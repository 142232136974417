import { Component } from 'react'

import * as firebase from "firebase/app"
import "firebase/firestore";

export default class Collection extends Component {

    state = {
        data: []
    }
    
    constructor(props) {
        super(props)
        this.add = this.add.bind(this)
        this.remove = this.remove.bind(this)
        this.modify = this.modify.bind(this)
        this.update = this.update.bind(this)
        this.startEditing = this.startEditing.bind(this)
        this.finishEditing = this.finishEditing.bind(this)
    }

    componentDidMount = async() => {
        try {
            let minPromiseTime = new Date();
            let hoursToExpireOs = 6
            minPromiseTime.setTime(minPromiseTime.getTime()-( (hoursToExpireOs * 60 * 60 * 1000)))
            let dataRef = firebase.firestore().collection(this.props.collection)
            if (this.props.orderBy && this.props.order) {
                dataRef.where("promiseTime", ">", minPromiseTime).orderBy(this.props.orderBy, this.props.order).onSnapshot(this.dataListener)
            } else {
                dataRef.where("promiseTime", "<", minPromiseTime).onSnapshot(this.dataListener)
            }
        } catch (error) {
            console.log('ERROR FETCHING DATA: ', error.message)
            return false
        }
    }

    dataListener = snapshot => {
        snapshot.docChanges().forEach(change => {
            let id = change.doc.id
            let parent = change.doc.ref.parent.id
            const data = Object.assign({}, change.doc.data(), {docId: id})
            switch (change.type) {
                case 'added':
                    this.add(id, data, parent)
                    break
                case 'modified':
                    this.modify(id, data, parent)
                    break
                case 'removed':
                    this.remove(id, parent)
                    break
                default: break;
            }
        })
    }

    get = (id) => {
        return this.state.data.find(data => data.id === id)
    }

    add(id, data) {
        //console.log("Trying to add")
        if(this.state.anyUnsaved) return
        let plateValue
        if(data.definedResource && data.definedResource.length > 0)
            plateValue = data.definedResource
        
        this.setState(
            Object.assign({}, this.state, {
                data: [
                    ...this.state.data,
                    Object.assign({}, data, plateValue ? {id: id, plate: plateValue} : {id: id})
                ]
            })
        )
    }

    remove(id) {
        let data = this.state.data.filter(object => {
            if ('id' in object) {
                if (typeof object.id === 'string' && typeof id === 'string')
                    return id.toLowerCase() !== object.id.toLowerCase()
            }
            return false
        })
        
        this.setState(
            Object.assign({}, this.state, {data: data})
        )
    }

    async modify(id, data, parent) {
        // console.trace()
        if(parent && this.state.anyUnsaved) return

        let updatedData = this.state.data.map(record => {
            if (record.id === id) {
                let plateValue = ""
                if(data.definedResource && data.definedResource.length > 0)
                    plateValue = data.definedResource
                else
                    plateValue = record.plate || ""

                let extras = parent ? {plate: plateValue} :  {plate: plateValue, _unsaved:false}
                let obj = Object.assign({}, record, data, extras)
                return obj
            } else {
                return record
            }
        })
        
        await this.setState({data: updatedData})
    }

    startEditing() {
        this.setState(Object.assign({}, this.state, {anyUnsaved: true}))
    }

    finishEditing() {
        this.setState(Object.assign({}, this.state, {anyUnsaved: false}))
    }

    async update(id, collection) {
        try {
            const { _unsaved, plate, docId, ...data } = this.state.data.find(data => data.id === id)
            let ref = firebase.firestore().collection(collection).doc(id)
            let uid = null
            await firebase.auth().onAuthStateChanged( user => user ? uid = user.uid : null)
            await ref.update(Object.assign({}, data, {lastUser: uid}))
        } catch (error) {
            throw new Error('ERROR. MESSAGE: ', error.message)
        }
    }

    reset = async(id) => {
        // console.log('id', id)
    }

    render() {
        return this.props.children({
            data: this.state.data,
            update: this.update,
            onChange: this.modify,
            reset: this.reset
        })
    }
}