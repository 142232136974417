import * as firebase from "firebase/app"
import "firebase/firestore";

const DEBUG = require("../../config/config.json").app.debug
const URL = require("../../config/config.json").server.url
const PROXY = require("../../config/config.json").server.proxy
const DAYS_OFFSET = 30

export class Session {

  constructor(username, password, customerId) {
    this._username = username;
    this._password = password;
    this._customerId = customerId
    this._sessionCreated = false
  }

  static get url () {
    return DEBUG ? PROXY + URL : URL
  }

  static set url(url) {
    this._url = url
  }

  static get username() {
    return this._username
  }

  static set username (username) {
    this._username = username
  }

  static get password() {
    return this._password
  }

  static set password (password) {
    this._password = password
  }

  static get sessionId () {
    return this._sessionId
  }

  static set sessionId (sessionId) {
    this._sessionId = sessionId
  }

  static get customerId () {
    return this._customerId
  }

  static set customerId (customerId) {
    this._customerId = customerId
  }

  static get sessionCreated () {
    return this._sessionCreated
  }

  static set sessionCreated(sessionCreated = false) {
    this._sessionCreated = Boolean(sessionCreated)
  }
  
  static get key () {
    return this._key
  }

  static set key (key) {
    this._key = key
  }

  static query(query) {
    console.log('query:', query)
    console.log(this.key)
  }

  static async createSession () {
    let path = `sessions/create?userName=${this.username}&password=${this.password}&asClientId=${this.customerId}`
    
    let url = this.url + path
    
    try {
      let response, success, key

      if (DEBUG) {
        response = await (await fetch(url)).json()
        success = response.success
        key = response.session.tokenKey
      } else {
        const myTest = firebase.functions().httpsCallable('myTest')
        let { data } = await myTest(url)
        success = data.success
        key = data.session.tokenKey
      }
      
      if (success) {
        this.key = key
        this.sessionCreated = true
        return true
      }

    } catch (error) {
      let css = ` background: #000;
                  color: #f00;
                  font-size: 16px;
                  font-weight: 900;
                  padding: 5px `
      console.log(`%cSESSION CREATION ERROR: ${error.message}`, css, );
    }
    return false
  }
}

export class Query {

  static get url () {
    return this._url
  }

  static set url (url) {
    this._url = url
  }

  /**
   * ***@******@******@******@******@******@******@***
   *                      @SELECT
   * ***@******@******@******@******@******@******@***
   */

  static select (select) {
    this.selectQuery = select
    return this
  }

  static get selectQuery () {
    return this._select
  }

  static set selectQuery (select) {
    this._select = select
  }

  /**
   * ***@******@******@******@******@******@******@***
   *                      @WHERE
   * ***@******@******@******@******@******@******@***
   */

  static where (...args) {

    if (args.length === 2) {
      let [ target, value ] = args
      this.whereQuery = {target: target, sign: '=', value: value}
    }
    
    if (args.length === 3) {
      let [ target, sign, value ] = args
      this.whereQuery = {target: target, sign: sign, value: value}
    }

    return this

  }

  static get whereQuery () {
    return this._where
  }

  static set whereQuery(where) {
    if (where) {
      if (!this._where) {
        this.whereQuery = false
        this.whereQuery = where
      } else {
        this._where.push(where)
      }
    } else {
      this._where = []
    }
  }

  /**
   * ***@******@******@******@******@******@******@***
   *                      @WHERE_IN
   * ***@******@******@******@******@******@******@***
   */

   static whereIn (target, array) {
    this.whereInQuery = {target, array}
    return this

  }

  static get whereInQuery () {
    return this._whereIn
  }

  static set whereInQuery(whereIn) {
    this._whereIn = whereIn
  }

  /**
   * ***@******@******@******@******@******@******@***
  *                      @RESOURCE
   * ***@******@******@******@******@******@******@***
   */

  static resource (resource) {
    if (!resource) return false
    this.url = `${Session.url}${resource}/`
    return this

  }

  /**
   * ***@******@******@******@******@******@******@***
  *                      @LIMIT
   * ***@******@******@******@******@******@******@***
   */

  static limit (limit) {
    this.limitQuery = limit
    return this
  }

  static get limitQuery () {
    return this._limit
  }

  static set limitQuery (limit) {

    let number = Number(limit)

    if (number && !isNaN(number)) {
      this._limit = limit
    } else {
      console.log('ERROR');
    }
    return this
  }

  /**
   * ***@******@******@******@******@******@******@***
  *                      @GROUP_BY
   * ***@******@******@******@******@******@******@***
   */

   static groupBy (groupBy) {
    this.groupByQuery = groupBy
    return this
  }

  static get groupByQuery () {
    return this._groupBy
  }

  static set groupByQuery (groupBy) {
    this._groupBy = groupBy
  }

  /**
   * ***@******@******@******@******@******@******@***
  *                      @ORDER_BY
   * ***@******@******@******@******@******@******@***
   */

   static orderBy (orderBy) {
    this.orderByQuery = orderBy
    return this
  }

  static get orderByQuery () {
    return this._orderBy
  }

  static set orderByQuery (orderBy) {
    this._orderBy = orderBy
  }

    /**
   * ***@******@******@******@******@******@******@***
  *                      @GET
   * ***@******@******@******@******@******@******@***
   */

  static async get () {

    const select = () => this.selectQuery ? `&select=${this.selectQuery}` : ``
    const whereIn = () => this.whereInQuery ? `&where=[${this.whereInQuery.target},in,${this.whereInQuery.array}]` : ``
    const limit = () => this.limitQuery ? `&limit=${this.limitQuery}` : ``
    const groupBy = () => this.groupByQuery ? `&groupBy=${this.groupByQuery}` : ``
    const orderBy = () => this.orderByQuery ? `&orderBy=${this.orderByQuery}` : ``

    // -----------------------------------------------------------------
    // SETTING MANUALLY A REPORT RANGE
    // THIS WILL CHANGE IN THE FUTURE
      const date = new Date()
      this.where('reportdate', '>' , timestampToDate(new Date(date.setDate(date.getDate() - DAYS_OFFSET))))
    // -----------------------------------------------------------------
    
    if (!Session.sessionCreated) await Session.createSession()
    
    const init = {
      method: 'GET',
      headers: {'Authorization': `StkAuth ${Session.key}`},
      cache: 'default'
    }

    const where = () => {
      if (!this.whereQuery || (this.whereQuery && this.whereQuery.length === 0)) return ''

      let where = 'where='

      if (this.whereQuery)
        this.whereQuery.forEach( ( { target, sign, value } ) => {
          where += sign === '=' ? `[${target},${value}]` : `[${target},${sign},${value}]`
        })

      return where
    }

    let url = `${this.url}?${select()}${where()}${whereIn()}${groupBy()}${orderBy()}${limit()}`

    // console.log('url', url.replace('http://127.0.0.1:8080/', ''))

    this.selectQuery = null
    this.whereQuery = null

    const doFetch = firebase.functions().httpsCallable('doFetch')

    // console.log('URL', url.replace('http://127.0.0.1:8080/', ''))
    // console.log('KEY', Session.key)

    if (DEBUG) {
      return  await (await fetch(url, init)).json()
    } else {
      let response = await doFetch({url, init})
      if ('data' in response) {
        return response.data
      }
    }
      
    return null
  }
}

const timestampToDate = (date: Date) => {
  let year = date.getFullYear().toString()
  let month = date.getMonth()+1
  let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${month}/${day}/${year}`
}
