import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions';

const DEBUG = require('./config.json').app.debug
const TEST_SERVER = require('./config.json').firebase.test_server

let testEnvs = [{
    apiKey: "AIzaSyCUKkw_mRFrseGTyxIGDEKpjjFEYMviMFc",
    authDomain: "sitrack-optimizer.firebaseapp.com",
    databaseURL: "https://sitrack-optimizer.firebaseio.com",
    projectId: "sitrack-optimizer",
    storageBucket: "sitrack-optimizer.appspot.com",
    messagingSenderId: "102016386814"
} , {
    apiKey: "AIzaSyC-aTCdAdr7NeteokRDzrZYS-GrdSVnNco",
    authDomain: "sitrack-optimizer.firebaseapp.com",
    databaseURL: "https://optimizertest2.firebaseio.com",
    projectId: "optimizertest2",
    storageBucket: "sitrack-optimizer.appspot.com",
    messagingSenderId: "345865934529"
}]

let productionEnv = 
{
    apiKey: "AIzaSyAGC-sdSEa-JuhLqe4vK6tWxfa_WA_kkQs",
    authDomain: "sitrack-dinamic-routing.firebaseapp.com",
    databaseURL: "https://sitrack-dinamic-routing.firebaseio.com",
    projectId: "sitrack-dinamic-routing",
    storageBucket: "sitrack-dinamic-routing.appspot.com",
    messagingSenderId: "1048340638303"
}

firebase.initializeApp(DEBUG ? testEnvs[TEST_SERVER] : productionEnv);
firebase.firestore().settings({});
firebase.functions().useFunctionsEmulator('http://localhost:5001');
// firebase.functions();

export default firebase;
