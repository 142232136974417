import React, { Component } from 'react'
import Table from '.'

import * as firebase from "firebase/app"
import "firebase/firestore";

const HOURS_OFF_SET = 36

export default class SolutionTable extends Component {

    state = {
        columns: [{
                name: 'plate',
                editable: true,
                editTarget: 'definedResource',
                listOptions: true
            },
            // {
            //     name: 'id'
            // },            
            {
                name: 'serviceOrder'
            },
            {
                name: 'funerary'
            },
            {
                name: 'name'
            },
            {   
                name: 'type'
            },
            {
                name: 'status',
                editable: true,
                listOptions: []
            },
            {
                name: 'religion'
            },
            // {
            //     name: 'definedResource',
            //     editable: true,
            //     listOptions: true
            // },
            {
                name: 'promiseTime',
                editable: true,
                date: true
            },
            {
                name: 'arrivalTime',
                date: true
            }
        ],
        dateFrom: new Date(new Date().setHours(new Date().getHours() - HOURS_OFF_SET)),
        dateTo: new Date(),
        status: [],
        type: [],
        filter: {}
    }

    componentDidMount = async() => {
        let status = await firebase.firestore().collection('status').get()
        let statusValues = []
        let type = await firebase.firestore().collection('type').get()
        let typeValues = []


        status.forEach(doc => {
            statusValues = [...statusValues, {label: doc.id, value: doc.id}]
        })
        
        type.forEach(doc => {
            typeValues = [...typeValues, {label: doc.id, value: doc.id}]
        })

        this.setState({status: statusValues, type: typeValues})
    }

    setFilter = (name, e) => {
        this.setState(state => ({
            filter: Object.assign({}, state.filter, {[name]: e})
        }))
    }

    clearFilter = () => {
        this.setState({filter: {}})
    }

    render() {
  
        return (
            <Table
                collection={'solution'}
                columns={this.state.columns}
                order={'desc'}
                from={this.state.dateFrom}
                to={this.state.dateTo}
                status={this.state.status}
                type={this.state.type}
                filter={this.state.filter}
                setFilter={this.setFilter}
                clearFilter={this.clearFilter}
            />
        )
    }

}
