import History from '../../components/history/history'

export const createAllocable = (serviceOrder, lab, deposit, uid, allocables, user) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        const createdAt = new Date();
        const timestamp = createdAt.getTime();

        let batch = firestore.batch();
        let newDoc;

        let bodyAllocable;
        let funeralAllocable;
        let burialAllocable;
        let funeralReturnAllocable;
        let generalAllocable;

        let history = {
            changes: { serviceOrder: serviceOrder.serviceOrder || 'SERVICE ORDER ID #NOTFOUND'},
            type: 'OS CREATION',
            serviceOrder: {
                id: serviceOrder.serviceOrder || 'SERVICE ORDER ID #NOTFOUND',
                name: serviceOrder.name || 'SERVICE ORDER NAME #NOTFOUND',
                createdAt:  firestore.Timestamp.fromDate(new Date()) || createdAt,
            },
            source: {
                type: 'web',
                resource: false
            },
            user: user
        }

        if (serviceOrder.bodyEnabled) {
            bodyAllocable = {
                contact: serviceOrder.bodyContact,
                createdAt,
                description: serviceOrder.bodyDescription,
                destinationAddress: serviceOrder.bodyAddress,
                destinationCoordinates: new firestore.GeoPoint(serviceOrder.bodyCoordinates.lat, serviceOrder.bodyCoordinates.lng),
                name: serviceOrder.name,
                optional: false,
                origin: 'web',
                promiseTime: serviceOrder.bodyPromiseTime._isAMomentObject ? serviceOrder.bodyPromiseTime.toDate() : serviceOrder.bodyPromiseTime,
                serviceOrder: serviceOrder.serviceOrder,
                status: serviceOrder.bodyStatus,
                type: 'body',
                history: {
                    [timestamp]: {
                        action: Object.keys(serviceOrder.currentAllocables).length > 0 ? 'updated' : 'created',
                        user: uid
                    }
                },
                estimatedDuration: 240,
                definedResource: serviceOrder.bodyDefinedManual ? serviceOrder.bodyDefinedResource : null,
                priority: parseInt(serviceOrder.priority)
            };

            history.changes.type = 'body'
            history.data = bodyAllocable
            History.save(history)
            History.addIndex('serviceOrders')

            batch = setHistory(serviceOrder, uid, allocables, bodyAllocable, firestore, batch);

            if ('body' in serviceOrder.currentAllocables) {
                delete bodyAllocable.createdAt;
                newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['body']);
                batch.update(newDoc, bodyAllocable);

            } else {
                newDoc = firestore.collection("allocables").doc();
                batch.set(newDoc, bodyAllocable);
            }
        } else if ('body' in serviceOrder.currentAllocables) {
            newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['body']);
            batch.delete(newDoc);
        }

        if (serviceOrder.funeralEnabled) {
            funeralAllocable = {
                contact: serviceOrder.funeralContact,
                createdAt,
                description: serviceOrder.funeralDescription,
                destinationAddress: serviceOrder.funeralAddress,
                destinationCoordinates: new firestore.GeoPoint(serviceOrder.funeralCoordinates.lat, serviceOrder.funeralCoordinates.lng),
                originAddress: lab.destinationAddress,
                originCoordinates: lab.destinationCoordinates,
                origin: 'web',
                name: serviceOrder.name,
                optional: false,
                promiseTime: serviceOrder.funeralPromiseTime._isAMomentObject ? serviceOrder.funeralPromiseTime.toDate() : serviceOrder.funeralPromiseTime,
                serviceOrder: serviceOrder.serviceOrder,
                status: serviceOrder.funeralStatus,
                type: 'funeral',
                history: {
                    [timestamp]: {
                        action: Object.keys(serviceOrder.currentAllocables).length > 0 ? 'updated' : 'created',
                        user: uid
                    }
                },
                estimatedDuration: 240,
                definedResource: serviceOrder.funeralDefinedManual ? serviceOrder.funeralDefinedResource : null,
                priority: parseInt(serviceOrder.priority)
            };

            history.changes.type = 'funeral'
            history.data = funeralAllocable
            History.save(history)
            History.addIndex('serviceOrders')

            batch = setHistory(serviceOrder, uid, allocables, funeralAllocable, firestore, batch);

            if ('funeral' in serviceOrder.currentAllocables) {
                delete funeralAllocable.createdAt;
                newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['funeral']);
                batch.update(newDoc, funeralAllocable);
            } else {
                newDoc = firestore.collection("allocables").doc();
                batch.set(newDoc, funeralAllocable);
            }
        } else if ('funeral' in serviceOrder.currentAllocables) {
            newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['funeral']);
            batch.delete(newDoc);
        }

        if (serviceOrder.funeralReturnEnabled) {
            funeralReturnAllocable = {
                name: serviceOrder.name,
                contact: serviceOrder.funeralReturnContact,
                createdAt,
                description: serviceOrder.funeralReturnDescription,
                destinationAddress: serviceOrder.funeralReturnAddress,
                destinationCoordinates: new firestore.GeoPoint(serviceOrder.funeralReturnCoordinates.lat, serviceOrder.funeralReturnCoordinates.lng + 0.00001),
                optional: false,
                origin: 'web',
                promiseTime: serviceOrder.funeralReturnPromiseTime._isAMomentObject ? serviceOrder.funeralReturnPromiseTime.toDate() : serviceOrder.funeralReturnPromiseTime,
                serviceOrder: serviceOrder.serviceOrder,
                status: serviceOrder.funeralReturnStatus,
                type: 'funeral_return',
                history: {
                    [timestamp]: {
                        action: Object.keys(serviceOrder.currentAllocables).length > 0 ? 'updated' : 'created',
                        user: uid
                    }
                },
                definedResource: serviceOrder.funeralReturnDefinedManual ? serviceOrder.funeralReturnDefinedResource : null,
                priority: parseInt(serviceOrder.priority)
            };

            history.changes.type = 'funeral_return'
            history.data = funeralReturnAllocable
            History.save(history)
            History.addIndex('serviceOrders')

            batch = setHistory(serviceOrder, uid, allocables, funeralReturnAllocable, firestore, batch);

            if ('funeral_return' in serviceOrder.currentAllocables) {
                delete funeralReturnAllocable.createdAt;
                newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['funeral_return']);
                batch.update(newDoc, funeralReturnAllocable);
            } else {
                newDoc = firestore.collection("allocables").doc();
                batch.set(newDoc, funeralReturnAllocable);
            }
        } else if ('funeral_return' in serviceOrder.currentAllocables) {
            newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['funeral_return']);
            batch.delete(newDoc);
        }

        if (serviceOrder.burialEnabled) {
            burialAllocable = {
                contact: serviceOrder.burialContact,
                createdAt,
                description: serviceOrder.burialDescription,
                destinationAddress: serviceOrder.burialDestinationAddress,
                destinationCoordinates: new firestore.GeoPoint(serviceOrder.burialDestinationCoordinates.lat, serviceOrder.burialDestinationCoordinates.lng),
                origin: 'web',
                originAddress: serviceOrder.burialOriginAddress,
                originCoordinates: new firestore.GeoPoint(serviceOrder.burialOriginCoordinates.lat, serviceOrder.burialOriginCoordinates.lng),
                name: serviceOrder.name,
                optional: false,
                promiseTime: serviceOrder.burialPromiseTime._isAMomentObject ? serviceOrder.burialPromiseTime.toDate() : serviceOrder.burialPromiseTime,
                serviceOrder: serviceOrder.serviceOrder,
                status: serviceOrder.burialStatus,
                type: 'burial',
                history: {
                    [timestamp]: {
                        action: Object.keys(serviceOrder.currentAllocables).length > 0 ? 'updated' : 'created',
                        user: uid
                    }
                },
                estimatedDuration: 180,
                definedResource: serviceOrder.burialDefinedManual ? serviceOrder.burialDefinedResource : null,
                priority: parseInt(serviceOrder.priority)
            };

            history.changes.type = 'burial'
            history.data = burialAllocable
            History.save(history)
            History.addIndex('serviceOrders')

            batch = setHistory(serviceOrder, uid, allocables, burialAllocable, firestore, batch);

            if ('burial' in serviceOrder.currentAllocables) {
                delete burialAllocable.createdAt;
                newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['burial']);
                batch.update(newDoc, burialAllocable);
            } else {
                newDoc = firestore.collection("allocables").doc();
                batch.set(newDoc, burialAllocable);
            }
        } else if ('burial' in serviceOrder.currentAllocables) {
            newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['burial']);
            batch.delete(newDoc);
        }

        if (serviceOrder.mealEnabled) {
            let mealAllocable = {
                contact: serviceOrder.mealContact,
                createdAt,
                description: serviceOrder.mealDescription,
                destinationAddress: serviceOrder.mealAddress,
                destinationCoordinates: new firestore.GeoPoint(serviceOrder.mealCoordinates.lat, serviceOrder.mealCoordinates.lng - 0.000005),
                name: serviceOrder.name,
                optional: false,
                origin: 'web',
                promiseTime: serviceOrder.mealPromiseTime._isAMomentObject ? serviceOrder.mealPromiseTime.toDate() : serviceOrder.mealPromiseTime,
                serviceOrder: serviceOrder.serviceOrder,
                status: serviceOrder.mealStatus,
                type: 'meal',
                history: {
                    [timestamp]: {
                        action: Object.keys(serviceOrder.currentAllocables).length > 0 ? 'updated' : 'created',
                        user: uid
                    }
                },
                estimatedDuration: 180,
                definedResource: serviceOrder.mealDefinedManual ? serviceOrder.mealDefinedResource : null,
                priority: parseInt(serviceOrder.priority)
            };

            history.changes.type = 'meal'
            history.data = mealAllocable
            History.save(history)
            History.addIndex('serviceOrders')

            batch = setHistory(serviceOrder, uid, allocables, mealAllocable, firestore, batch);

            if ('meal' in serviceOrder.currentAllocables) {
                delete mealAllocable.createdAt;
                newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['meal']);
                batch.update(newDoc, mealAllocable);
            } else {
                newDoc = firestore.collection("allocables").doc();
                batch.set(newDoc, mealAllocable);
            }
        } else if ('meal' in serviceOrder.currentAllocables) {
            newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['meal']);
            batch.delete(newDoc);
        }

        if (serviceOrder.mealReturnEnabled) {
            let mealReturnAllocable = {
                contact: serviceOrder.mealReturnContact,
                createdAt,
                description: serviceOrder.mealReturnDescription,
                destinationAddress: serviceOrder.mealReturnAddress,
                destinationCoordinates: new firestore.GeoPoint(serviceOrder.mealReturnCoordinates.lat, serviceOrder.mealReturnCoordinates.lng + -0.00001),
                name: serviceOrder.name,
                origin: 'web',
                optional: false,
                promiseTime: serviceOrder.mealReturnPromiseTime._isAMomentObject ? serviceOrder.mealReturnPromiseTime.toDate() : serviceOrder.mealReturnPromiseTime,
                serviceOrder: serviceOrder.serviceOrder,
                status: serviceOrder.mealReturnStatus,
                type: 'meal_return',
                history: {
                    [timestamp]: {
                        action: Object.keys(serviceOrder.currentAllocables).length > 0 ? 'updated' : 'created',
                        user: uid
                    }
                },
                definedResource: serviceOrder.mealReturnDefinedManual ? serviceOrder.mealReturnDefinedResource : null,
                priority: parseInt(serviceOrder.priority)
            };

            history.changes.type = 'meal_return'
            history.data = mealReturnAllocable
            History.save(history)
            History.addIndex('serviceOrders')

            batch = setHistory(serviceOrder, uid, allocables, mealReturnAllocable, firestore, batch);

            if ('meal_return' in serviceOrder.currentAllocables) {
                delete mealReturnAllocable.createdAt;
                newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['meal_return']);
                batch.update(newDoc, mealReturnAllocable);
            } else {
                newDoc = firestore.collection("allocables").doc();
                batch.set(newDoc, mealReturnAllocable);
            }
        } else if ('meal_return' in serviceOrder.currentAllocables) {
            newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['meal_return']);
            batch.delete(newDoc);
        }

        if (serviceOrder.generalEnabled) {
            generalAllocable = {
                contact: serviceOrder.generalContact,
                createdAt,
                description: serviceOrder.generalDescription,
                destinationAddress: serviceOrder.generalAddress,
                destinationCoordinates: new firestore.GeoPoint(serviceOrder.generalCoordinates.lat, serviceOrder.generalCoordinates.lng),
                name: serviceOrder.name,
                origin: 'web',
                optional: false,
                promiseTime: serviceOrder.generalPromiseTime._isAMomentObject ? serviceOrder.generalPromiseTime.toDate() : serviceOrder.generalPromiseTime,
                serviceOrder: serviceOrder.serviceOrder,
                status: serviceOrder.generalStatus,
                type: 'general',
                history: {
                    [timestamp]: {
                    action: Object.keys(serviceOrder.currentAllocables).length > 0 ? 'updated' : 'created',
                    user: uid
                    }
                },
                estimatedDuration: 180,
                definedResource: serviceOrder.generalDefinedManual ? serviceOrder.generalDefinedResource : null,
                priority: parseInt(serviceOrder.priority)
            };

            batch = setHistory(serviceOrder, uid, allocables, generalAllocable, firestore, batch);

            history.changes.type = 'general'
            history.data = generalAllocable
            History.save(history)
            History.addIndex('serviceOrders')

            if ('general' in serviceOrder.currentAllocables) {
                delete generalAllocable.createdAt;
                newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['general']);
                batch.update(newDoc, generalAllocable);
            } else {
                newDoc = firestore.collection("allocables").doc();
                batch.set(newDoc, generalAllocable);
            }
        } else if ('general' in serviceOrder.currentAllocables) {
            newDoc = firestore.collection("allocables").doc(serviceOrder.currentAllocables['general']);
            batch.delete(newDoc);
        }
        
        batch.commit()
            .then(() => {
                dispatch({type: 'SERVICE_ORDER_CREATED', serviceOrder});
            })
            .catch((err) => {
                alert(err);
                dispatch({type: 'SERVICE_ORDER_ERROR', err});
            });
    }
};

const setHistory = (serviceOrder, uid, allocables, currentAllocable, firestore, batch) => {
    let history;

    if (Object.keys(serviceOrder.currentAllocables).length > 0) {
        history = {
            timestamp: new Date(),
            action: 'OS edited',
            agent: 'interface',
            user: uid
        };

        let allocable = allocables.find((allocable) => {
            return allocable.id === serviceOrder.currentAllocables['body'];
        });

        for (let key in allocable) {
            let property = key[0].toUpperCase() + key.substring(1, key.length + 1);
            history['previous' + property] = allocable[key] !== undefined ? allocable[key] : null;
            history['current' + property] = currentAllocable[key] !== undefined ? currentAllocable[key] : null;
        }
    } else {
        history = {
            timestamp: new Date(),
            action: 'OS created',
            agent: 'interface',
            user: uid
        };
    }

    let newHistory = firestore.collection("history").doc();
    batch.set(newHistory, history);

    return batch;
};

export const SupervisorActions = (allocable, uid, user) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        switch (allocable.modalAction) {
            case 0 :
            case 1 :
                firestore.collection('allocables').doc(allocable.currentId).get().then(snapshot => {
                        let minutes = allocable.modalAction === 0 ? -30 : 30;
                        let newDuration = snapshot.data().estimatedDuration + minutes;
                        firestore.collection('allocables').doc(allocable.currentId).update({
                            estimatedDuration: newDuration
                        })
                            .then((snapshot) => {
                                let history = {
                                    timestamp: new Date(),
                                    allocable: allocable.currentId,
                                    action: 'OS edited',
                                    agent: 'interface',
                                    user: uid,
                                    previousEstimatedDuration: snapshot.data().estimatedDuration,
                                    currentEstimatedDuration: newDuration
                                };

                                let batch = firestore.batch();
                                let ref = firestore.collection('history').doc();
                                batch.set(ref, history);

                                if (allocable.type === 'funeral') {
                                    firestore.collection('allocables').where('serviceOrder', '==', allocable.serviceOrder).get()
                                        .then((snapshot) => {
                                            snapshot.forEach(doc => {
                                                let data = doc.data();
                                                let newReturnDuration = new Date(data.promiseTime.toDate().getTime() - 30 * 60000);
                                                // let ref = firestore.collection('allocables').doc(doc.id);
                                                if (data.type === 'funeral_return' || data.type === 'meal_return') {
                                                    let ref = firestore.collection('allocables').doc(doc.id);
                                                    batch.update(ref, {
                                                        promiseTime: newReturnDuration
                                                    });

                                                    let history = {
                                                        timestamp: new Date(),
                                                        allocable: allocable.currentId,
                                                        action: 'OS edited',
                                                        agent: 'interface',
                                                        user: uid,
                                                        previousPromiseTime: data.promiseTime,
                                                        currentPromiseTime: newReturnDuration
                                                    };

                                                    ref = firestore.collection('history').doc(); // CHANGED
                                                    batch.set(ref, history);
                                                }
                                            });
                                        })
                                        .catch((err) => {
                                            dispatch({type: 'SUPERVISOR_ERROR', err});
                                        });
                                    batch.commit()
                                        .then(() => {
                                            dispatch({type: 'SUPERVISOR_UPDATE'});
                                        })
                                        .catch(err => {
                                            dispatch({type: 'SUPERVISOR_ERROR', err});
                                        });
                                }
                                else {
                                    dispatch({type: 'SUPERVISOR_UPDATE', snapshot});
                                }
                            })
                            .catch((err) => {
                                dispatch({type: 'SUPERVISOR_ERROR', err});
                            })

                    })
                    .catch(err => {
                        dispatch({type: 'SUPERVISOR_ERROR', err});
                    });
                break;
            case 2 :
                firestore.collection('allocables').doc(allocable.currentId).get().then(snapshot => {
                    let status = 'finished';
                    let finishedTime = new Date();
                    let history = {
                        timestamp: new Date(),
                        allocable: allocable.currentId,
                        action: 'OS edited',
                        agent: 'interface',
                        user: uid,
                        previousStatus: snapshot.data().status,
                        currentStatus: status
                    };

                    let batch = firestore.batch();
                    let ref = firestore.collection('history').doc();
                    batch.set(ref, history);

                    let _history = {
                        user: user,
                        changes: {status: status},
                        previous: {status: snapshot.data().status},
                        serviceOrder: {
                            id: allocable.currentId || '',
                            createdAt: snapshot.data().createdAt || '',
                        },
                        optimizer: false,
                        type: 'UPDATE',
                        source: {
                            type: 'web',
                            resource: snapshot.data().definedResource ? snapshot.data().definedResource.toUpperCase() : false
                        }
                    }

                    History.save(_history)

                    ref = firestore.collection('allocables').doc(allocable.currentId);
                    batch.update(ref, {
                        status,
                        finishedTime
                    });

                    batch.commit()
                        .then((snapshot) => {
                            dispatch({type: 'SUPERVISOR_UPDATE', snapshot});
                        })
                        .catch((err) => {
                            dispatch({type: 'SUPERVISOR_ERROR', err});
                        });
                })
                    .catch(err => {
                        console.log('ESTE ERRO', err)
                        dispatch({type: 'SUPERVISOR_ERROR', err});
                    });
                break;
            case 3 :
                firestore.collection('resources').doc(allocable.currentId).get().then(snapshot => {
                    let history = {
                        timestamp: new Date(),
                        resource: allocable.currentId,
                        action: 'OS edited',
                        agent: 'interface',
                        user: uid,
                        previousCapacity: snapshot.data().capacity || 1,
                        previousCapacityReturn: snapshot.data().capacityReturn || 1,
                        previousloadQuantity: snapshot.data().loadQuantity || 0,
                        currentCapacity: allocable.capacity || 1,
                        currentCapacityReturn: allocable.capacityReturn || 1,
                        currentloadQuantity: allocable.loadQuantity || 1,
                        previousAndroidID: snapshot.data().androidID || '',
                        currentAndroidID: allocable.androidID || ''
                    };

                    let batch = firestore.batch();
                    let ref = firestore.collection('history').doc();
                    batch.set(ref, history);

                    ref = firestore.collection('resources').doc(allocable.currentId);
                    batch.update(ref, {
                        status: allocable.status,
                        capacity: allocable.capacity,
                        capacityReturn: allocable.capacityReturn,
                        loadQuantity: allocable.loadQuantity,
                        androidID: allocable.androidID
                    });

                    batch.commit()
                        .then(() => {
                            dispatch({type: 'SUPERVISOR_UPDATE'});
                            console.log('SUPERVISOR_UPDATE')
                        })
                        .catch(err => {
                            dispatch({type: 'SUPERVISOR_ERROR', err});
                            console.log('SUPERVISOR_ERROR1',err)
                        });
                })
                    .catch(err => {
                        dispatch({type: 'SUPERVISOR_ERROR', err});
                        console.log('SUPERVISOR_ERROR2',err)
                    });
                break;
            case 4 :
                let ids = String(allocable.ids).split('/')
                let cont = 0
                for(var j=0;j<ids.length;j++){
                    if(ids[j] !== ''){
                        cont++
                    }
                }
                for(var i=0;i<cont;i++){
                     query(ids[i], allocable, uid, user, dispatch, getState, {getFirebase, getFirestore})   

                
                }
            break;
            default: break
        }
    }
};

async function query(id,allocable, uid, user, dispatch, getState, {getFirebase, getFirestore}){
    const firestore = getFirestore();
    await firestore.collection('allocables').doc(id).get().then(snapshot => {
        let status = 'finished';
        let finishedTime = new Date();
        let history = {
            timestamp: new Date(),
            allocable: id,
            action: 'OS edited',
            agent: 'interface',
            user: uid,
            previousStatus: snapshot.data().status,
            currentStatus: status
        };

        let batch =  firestore.batch();
        let ref =  firestore.collection('history').doc();
        batch.set(ref, history);

        let _history = {
            user: user,
            changes: {status: status},
            previous: {status: snapshot.data().status},
            serviceOrder: {
                id: id || '',
                createdAt: snapshot.data().createdAt || '',
            },
            optimizer: false,
            type: 'UPDATE',
            source: {
                type: 'web',
                resource: snapshot.data().definedResource ? snapshot.data().definedResource.toUpperCase() : false
            }
        }

         History.save(_history)

        ref =  firestore.collection('allocables').doc(id);
         batch.update(ref, {
            status,
            finishedTime
        });

         batch.commit()
            .then((snapshot) => {
                dispatch({type: 'SUPERVISOR_UPDATE', snapshot});
            })
            .catch((err) => {
                dispatch({type: 'SUPERVISOR_ERROR', err});
            });
    })
    .catch(err => {
        console.log('ESTE ERRO', err)
        dispatch({type: 'SUPERVISOR_ERROR', err});
    });
}

export const getProgress = (data) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        const getProgress = firebase.functions().httpsCallable('getProgress');
        getProgress(data).then(function (result) {
            if (result.data.error === undefined) {
                dispatch({type: 'GET_PROGRESS_SUCCESS', result: result.data.progress, key: data.key});
            }

            else {
                dispatch({type: 'GET_PROGRESS_ERROR', err: result.data.error});
            }
        });
    }
};

export const getRoute = (data) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        const getProgress = firebase.functions().httpsCallable('getRoute');
        getProgress(data).then(function (result) {
            if (result.data.error === undefined) {
                dispatch({type: 'GET_ROUTE_SUCCESS', result: result.data[0]});
            }

            else {
                dispatch({type: 'GET_ROUTE_ERROR', err: result.data.error});
            }
        });
    }
};

export const deleteAllocables = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('allocables').get().then(documents => {
            let batch = firestore.batch();
            let docRef;

            let allocables = ['30470-13', '30480-13'];

            documents.forEach(doc => {
                if (doc.data().type !== 'laboratory' && doc.data().type !== 'deposit' &&
                    !allocables.includes(doc.data().serviceOrder)) {
                    docRef = firestore.collection('allocables').doc(doc.id);
                    batch.delete(docRef);
                }
            });

            batch.commit().then(() => {
                dispatch({type: 'DELETE_ALLOCABLES_SUCCESS', result: 'success'});
            })
                .catch(error => {
                    dispatch({type: 'DELETE_ALLOCABLES_ERROR', error});
                });
        })
            .catch(error => {
                dispatch({type: 'DELETE_ALLOCABLES_ERROR', error});
            });
    }
};

export const showAllocables = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('allocables').get().then(documents => {

            let allocables = {};
            documents.forEach(doc => {
                if (!(doc.data().name in allocables) &&
                    doc.data().type !== 'laboratory' && doc.data().type !== 'deposit' &&
                    doc.data().createdAt !== undefined
                // && doc.data().createdAt.toDate().getTime() > (new Date(2019, 3, 11, 18, 0, 0)).getTime()) {
                ) {
                    allocables[doc.data().name] = doc.data().serviceOrder;
                }
            });
        })
            .catch(error => {
                dispatch({type: 'DELETE_ALLOCABLES_ERROR', error});
            });
    }
};

export const ignoreAllocables = (allocables, justification, uid) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        let batch = firestore.batch();

        for (let key in allocables) {
            let newDoc = firestore.collection("allocables").doc(allocables[key]);
            batch.update(newDoc, {ignored: true});


            let history = {
                timestamp: new Date(),
                allocable: allocables[key],
                action: 'OS edited',
                agent: 'interface',
                user: uid,
                description: justification
            };
            newDoc = firestore.collection("history").doc();
            batch.set(newDoc, history);
        }

        batch.commit()
            .then(() => {
                dispatch({type: 'SERVICE_ORDER_IGNORED'});
            })
            .catch((err) => {
                dispatch({type: 'SERVICE_ORDER_IGNORED_ERROR', err});
            });
    }
};
