import React, { Component } from "react";
import FuneraryList from "./RegisterFunerary";
import { 
  Button,
  Glyphicon
} from 'react-bootstrap'
import Select from 'react-select'
import { text } from '../../config/translate'

import * as firebase from "firebase/app"
import "firebase/firestore";


export default class RegisterFunerary extends Component{
    
    render() {
    
        return (
          <div>
            <FuneraryList/>
          </div>
        );
      }
}