import React from 'react'
import { Col, Row, Grid, Table, Badge, Button, Glyphicon } from 'react-bootstrap'
import { FlexibleWidthXYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, LabelSeries, DiscreteColorLegend } from 'react-vis'
import Select from 'react-select'
import { tickFormatter } from '../../util'
import './index.css'
import Loading from '../loading'
import * as firebase from "firebase/app"
import "firebase/firestore";
import { text } from '../../config/translate'
import { useFirestore, useFirestoreGet } from '../../hooks/firebase'
import StateManager from 'react-select'
import {saveAs} from "file-saver";

const state = {
    mapa: [],
    chave: {},
    data: []
}

const GREEN = '#66bb6a'
const BLUE = '#42a5f5'


export default function DeathsChart ({}){
    //push paramenter to url and verify if exist    
    var arrayURL = String(window.location).split('/')

    let dateFilterInit
    let dataFilterEnd
    let yearInit
    var montInit
    let montEnd

    if(arrayURL[4] != null){
        //configure date in paramenter
        var dataURL = String(arrayURL[4]).split('$')

        dateFilterInit = new Date(dataURL[0])
        dateFilterInit.setDate(dateFilterInit.getDate() + 1)
        dateFilterInit.setHours(0)
        yearInit = dateFilterInit.getFullYear()
        montInit = Number(dateFilterInit.getMonth()+1)
    
        dataFilterEnd = new Date(dataURL[1])
        dataFilterEnd.setDate(dataFilterEnd.getDate() + 1) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        montEnd = dataFilterEnd.getMonth()+1
         
    } else {
        dateFilterInit = new Date()
        dateFilterInit.setDate(1)
        dateFilterInit.setHours(0)
        yearInit = dateFilterInit.getFullYear()
        montInit = Number(dateFilterInit.getMonth()+1)
    
        dataFilterEnd = new Date()
        dataFilterEnd.setDate(dataFilterEnd.getDate() + 1) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        montEnd = dataFilterEnd.getMonth()+1
    } 

    let content = <Loading/>

    //function to search data in firebase
    queryasync(montInit, yearInit, montEnd, dataFilterEnd.getFullYear(), dateFilterInit.getDate(), dataFilterEnd.getDate())
    
    async function queryasync(montInit, yearInit, montEnd, dataFilterEnd, dateInit, dateEnd){  
        await query(montInit, yearInit, montEnd, dataFilterEnd, dateInit, dateEnd)
    }

    async function download(){


        const timestampToDate = (date) => {
            console.log(date)
            let year = date.getUTCFullYear().toString()
            let month = date.getMonth()+1
            let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
            return `${day}/${month}/${year}`
          }
        


        var Excel = require('exceljs');
        // A new Excel Work Book
        var workbook = new Excel.Workbook();

        // Some information about the Excel Work Book.
        workbook.creator = 'Matheus Oliveira';
        workbook.lastModifiedBy = '';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();

        // Create a sheet
        var sheet = workbook.addWorksheet('Sheet1');
        // A table header
        sheet.columns = [
            { header: 'Nome', key: 'name' },
            { header: 'OS', key: 'os' },
            { header: 'Data Marcada', key: 'promiseTime' },
            { header: 'Data Criação', key: 'createdAt' },
            
        ]

        // Add rows in the above header
        Object.keys(state.data).forEach(function(item){
            sheet.addRow({name: state.data[item].name, os: state.data[item].os, promiseTime: state.data[item].promiseTime, createdAt: state.data[item].createdAt });
        })

        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileExtension = '.xlsx';
        
        const blob = new Blob([buffer], {type: fileType});
        
        if(arrayURL[4] != null){
            var dataURL = String(arrayURL[4]).split('$')
            var dataInit = String(dataURL[0]).split('-')
            var dataEnd = String(dataURL[1]).split('-')
            saveAs(blob, 'Number_Deaths_'+ dataInit[2] + "/" + (parseInt(dataInit[1])) + "/" + dataInit[0] + "-"+ dataEnd[2] + "/" + (parseInt(dataEnd[1])) + "/" + dataEnd[0] + fileExtension);
        } else {
            let date = new Date()
            saveAs(blob, 'Number_Deaths_mes_'+ (date.getMonth()+1) + fileExtension);
        }
    }
    
    let data = []   

    for (let chave in state.mapa) {
       // console.log(chave +" ==> "+state.mapa[chave]); // exemplo ilustrativo
     
        data.push({x: chave, y: state.mapa[chave], color: BLUE})
    }

    content = (
        <div>
            <FlexibleWidthXYPlot
                height={300 + 202}
                xType={'ordinal'}
                margin={{ bottom: 150 }}
            >
                <VerticalGridLines/>
                <HorizontalGridLines/>
                <XAxis
                    tickLabelAngle={-90}
                    style={{
                        text: {
                            stroke: 'none',
                            fill: '#000',
                            fontWeight: 600,
                            maxWidth: 2,
                        }
                    }}
                    tickFormat={tickFormatter}
                    position={'start'}
                    className={'XAxis'}
                />
                <YAxis/>
                    <VerticalBarSeries
                        data={data}
                        colorType={'literal'}
                    />
            </FlexibleWidthXYPlot>

                <Button style={{marginRight: 10}} onClick={download}>
                    Exportar Dados {" "}
					<Glyphicon glyph={'glyphicon glyphicon-download-alt'}/>
				</Button>
        </div>
    )

return (content)

}

async function query (monthInit, yearInit, monthEnd, yearEnd, dateInit, dateEnd) {
    let dateFilterEnd = new Date(yearEnd+"-"+monthEnd+"-"+(dateEnd))
    dateFilterEnd.setHours(23)
    dateFilterEnd.setMinutes(59)
    dateFilterEnd.setSeconds(59)

    let dateFilterInit = new Date(yearInit+"-"+monthInit+"-"+dateInit)
    dateFilterInit.setHours(0)
    dateFilterInit.setMinutes(0)
    dateFilterInit.setSeconds(0)

    //  console.log( "data init ==> ", dateFilterInit)
    //  console.log( "data end ==> ", dateFilterEnd)

    let data = []
    let os = []
    let dataExport = []
    let doc  = await firebase.firestore().collection('historico').where('type', '==', "OS CREATION")
                                                                 .where('data.promiseTime', '<=', dateFilterEnd)
                                                                 .where('data.promiseTime', '>=', dateFilterInit).get()
    .then( snapshot => {
        snapshot.forEach(doc => {       
            if(!os.includes(doc.data().data.serviceOrder)){  
                var date = new Date(doc.data().data.promiseTime.seconds * 1000)
                let day = date.getDate()                        
                os.push(doc.data().data.serviceOrder)
                data.push({death: 1, date: day+"/"+(date.getMonth()+1)})

                dataExport.push({
                    name: doc.data().data.name,
                    os: doc.data().data.serviceOrder,
                    promiseTime: new Date(doc.data().data.promiseTime.seconds * 1000).toUTCString(),
                    createdAt: new Date(doc.data().data.createdAt.seconds * 1000).toUTCString()
                })
            } 
            
        })
    })


    for (let i = 0; i < data.length; i++) {
        let chave = data[i].date;
        if (!state.mapa[chave]) {
            state.mapa[chave] = 1;
        } else {
            state.mapa[chave]++;
        }
    }

    state.data = dataExport
}
