export const text  = (string) => {
    return translate_portuguese(string ? string.toLowerCase() : '')
}

const translate_portuguese = string => {

    const PORTUGUESE = [
        {
            en: 'new',
            pt: 'novo'
        },
        {
            en: 'started',
            pt: 'iniciado'
        },
        {
            en: 'status',
            pt: 'Status'
        },
        {
            en: 'serviceorder',
            pt: 'O.S.'
        },
        {
            en: 'name',
            pt: 'Nome'
        },
        {
            en: 'type',
            pt: 'Tipo'
        },
        {
            en: 'religion',
            pt: 'Religião'
        },
        {
            en: 'plate',
            pt: 'Placa'
        },
        {
            en: 'definedresource',
            pt: 'Veículo Escolhido'
        },
        {
            en: 'promisetime',
            pt: 'Marcado'
        },
        {
            en: 'arrivaltime',
            pt: 'Estimativa'
        },
        {
            en: 'laboratory',
            pt: 'Laboratório'
        },
        {
            en: 'body',
            pt: 'Remoção'
        },
        {
            en: 'burial',
            pt: 'Cortejo'
        },
        {
            en: 'funeral',
            pt: 'Velório'
        },
        {
            en: 'catholic',
            pt: 'Católico'
        },
        {
            en: 'protestant',
            pt: 'Evangélico'
        },
        {
            en: 'solution',
            pt: 'Atividades'
        },
        {
            en: 'arrived_body',
            pt: 'Removido'
        },
        {
            en: 'arrived_funeral',
            pt: 'No Velório'
        },
        {
            en: 'at_lab',
            pt: 'No Laboratório'
        },
        {
            en: 'finished',
            pt: 'Finalizado'
        },
        {
            en: 'picked_up_body_funeral',
            pt: 'Em Cortejo'
        },
        {
            en: 'outsource',
            pt: 'Terceirizado'
        },
        {
            en: 'funeral_return',
            pt: 'Retorno de param.'
        },
        {
            en: 'general',
            pt: 'Genérica'
        },
        {
            en: 'select...',
            pt: 'Selecione...'
        },
        {
            en: 'type...',
            pt: 'Digite...'
        },
        {
            en: 'filter',
            pt: 'FILTRO'
        },
        {
            en: 'resource',
            pt: 'Veículo'
        },
        {
            en: 'user',
            pt: 'Usuário'
        },
        {
            en: 'createdat',
            pt: 'Data de Criação'
        },
        {
            en: 'service order',
            pt: 'Ordem de Serviço'
        },
        {
            en: 'definedresource',
            pt: 'Placa Escolhida'
        },
        {
            en: 'oldvalue',
            pt: 'Valor Anterior'
        },
        {
            en: 'newvalue',
            pt: 'Novo Valor'
        },
        {
            en: 'field',
            pt: 'Campo'
        },
        {
            en: 'value',
            pt: 'Valor'
        },
        {
            en: 'os creation',
            pt: 'CRIAÇÃO DE ORDEM DE SERVIÇO'
        },
        {
            en: 'description',
            pt: 'Descrição'
        },
        {
            en: 'destinationaddress',
            pt: 'Endereço de Destino'
        },
        {
            en: 'originaddress',
            pt: 'Endereço de Origem'
        },
        {
            en: 'username',
            pt: 'Responsável'
        },
        {
            en: 'undefined',
            pt: 'NÃO DEFINIDO(A)'
        },
        {
            en: 'created at',
            pt: 'Criado em'
        },
        {
            en: 'promise time',
            pt: 'Previsto para'
        },
        {
            en: 'last communication',
            pt: 'Última Comunicação'
        },
        {
            en: 'deposit',
            pt: 'Depósito'
        },
        {
            en: 'Date Create',
            pt: 'Data Início'
        },
        {
            en: 'Date End',
            pt: 'Data Final'
        },
        {
            en: 'funerary',
            pt: 'Funerária'
        },
        {
            en: 'so',
            pt: 'OS'
        },
        {
            en: 'definedresourceprevious',
            pt: 'Veículo Anterior'
        },
        {
            en: 'distance',
            pt: 'Distância'
        },
        {
            en: 'distanceprevius',
            pt: 'Distância anterior'
        },
        {
            en: 'distanceresult',
            pt: 'Distância atual'
        },
        {
            en: 'distancedifference',
            pt: 'Diferência das distâncias'
        },
        {
            en: 'dateupdate',
            pt: 'Data de Atualização'
        },
        {
            en: 'location',
            pt: 'Localização'
        },
        {
            en: 'map',
            pt: 'Mapa'
        }
    ]

    let term = PORTUGUESE.find(term => term.en === string)
    if (term)
        return term.pt
    return string
}
