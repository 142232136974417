import React from 'react';
import '../styles/index.css';

const Resources = props => {


    return (
        <div className="resources-wrapper">
            <div className="resources-container">
            Resources
            </div>
        </div>
    )
}

export default Resources;
