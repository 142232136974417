import React, { Component } from 'react'
import Table from '.'

const COLUMNS = [
    {
        name: 'id'
    },
    {
        name: 'vehicle',
        editable: false,
        listOptions: [
            {
                value: 'vehicle1',
                label: 'vehicle1'
            },
            {
                value: 'vehicle2',
                label: 'vehicle2'
            }
        ]
    },
    {
        name: 'name'
    },
    {
        name: 'type'
    },
    {
        name: 'promiseTime',
        editable: true,
        date: true
    },
    {
        name: 'arrivalTime',
        date: true
    },
    {
        name: 'serviceOrder'
    },
    {
        name: 'religion',
        editable: true
    },
    {
        name: 'status',
        editable: true,
        listOptions: [
            {
                value: 'new',
                label: 'new'
            },
            {
                value: 'old',
                label: 'old'
            }
        ]
    }
]

export default class AllocablesTable extends Component {
    render() {
        return <Table collection={'allocables'} columns={COLUMNS} orderBy={'promiseTime'} order={'desc'}/>
    }
}
