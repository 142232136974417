
export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        )
            .then(() => {
                dispatch({type: 'LOGIN_SUCCESS'})
            })
            .catch((err) => {
                dispatch({type: 'LOGIN_ERROR', err})
            })
    }
};

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signOut()
            .then(() => {
                dispatch({type: 'SIGNOUT_SUCCESS'});
            });
    }
};

export const createUser = (newUser) => {
    return (dispatch, getState, {getFirebase}) => {
        newUser.initials = newUser.firstName[0].toUpperCase() + newUser.lastName[0].toUpperCase();
        newUser.email =  newUser.firstName.toLowerCase() + '.' +  newUser.lastName.toLowerCase() + '@grupozelo.com';

        const firebase = getFirebase();

        const createUser = firebase.functions().httpsCallable('createUser');

        createUser({newUser}).then(function (result) {
            if (result.data.error == null || result.data.error === {}) {
                dispatch({type: 'CREATE_USER_SUCCESS', result: result.data.result});
            } else {
                dispatch({type: 'CREATE_USER_ERROR', err: result.data.error});
            }
        });
    }
};

