import { useReducer, useEffect, useState } from 'react'
import { dateSetHours } from '../util'
import { useFirestore } from './firebase'

/**
 *  ------------- CONSTANTS -------------
 */

const ALLOCABLE = 'allocables'

/**
 *  ------------- ACTIONS -------------
 */

const add = ( data ) => ({
    type: 'add',
    data
})

const remove = ( data) => ({
    type: 'remove',
    data
})

const modify = ( data ) => ({
    type: 'modify',
    data
})

/**
 *  ------------- SUPPORT FUNCTIONS -------------
 */

const addToState = ( state: Array = [], allocable: Object = {}) => ([ ...state, allocable ])

const removeFromState = ( state: Array = [], allocable: Object = {}) => state.filter( ({ id: allocableId }) => allocableId !== allocable.id)

const modifyInState = ( state: Array = [], allocableUpdated: Object = {}) => state.map( allocableInState => {
    if ('id' in allocableInState && 'id' in allocableUpdated) {
        return allocableInState.id === allocableUpdated.id ? allocableUpdated : allocableInState
    }
    return allocableInState
})

/**
 *  ------------- REDUCER -------------
 */

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return addToState(state, action.data)
        case 'modify':
            return modifyInState(state, action.data)
        case 'remove':
            return removeFromState(state, action.data)
        default:
            console.log('NO ACTION')
            return state
    }
}

/**
 *  ------------- MAIN FUNCTION -------------
 */

export function useAllocableListener () {

    const [ data, dispatch ] = useReducer(reducer, [])
    const [ isLoading, setIsLoading ] = useState(false)

    /**
     *  ------------- BOUND FUNCTIONS -------------
     */
    const addAllocable = (data) => dispatch(add(data))
    const modifyAllocable = (data) => dispatch(modify(data))
    const removeAllocable = (data) => dispatch(remove(data))

    /**
     *  ------------- LISTENER -------------
     */

    const onSnapshotListener = snapshot => {            

        const isAllocable = collection => collection && typeof collection === 'string' && collection.toLocaleLowerCase() === ALLOCABLE

        const doDispatch = (action, data) => {

            switch (action) {
                case 'added':
                    addAllocable(data)
                    break
                case 'modified':
                    modifyAllocable(data)
                    break
                case 'removed':
                    removeAllocable(data)
                    break
                default: break
            }
        }

        snapshot.docChanges().forEach( ( change ) => {
    
            let { type, createdAt, name, origin, serviceOrder, status, supervised, promiseTime } = change.doc.data()
            let id = change.doc.id
            let collection = change.doc.ref.parent.id
            let action = change.type

            if (isAllocable(collection))
                doDispatch(action, {
                    id,
                    type,
                    createdAt: createdAt && 'seconds' in createdAt ? new Date(createdAt.seconds * 1000) : null,
                    promiseTime: promiseTime && 'seconds' in promiseTime ? new Date(promiseTime.seconds * 1000) : null,
                    name,
                    origin,
                    serviceOrder,
                    status,
                    supervised
                })
        })

        setIsLoading(false)
    }

    useEffect( () => {
        setIsLoading(true)
        const unsubscribe = useFirestore().collection(ALLOCABLE).where('promiseTime', '>', dateSetHours(-6)).orderBy('promiseTime', 'desc').onSnapshot( onSnapshotListener )
        return () => {
            console.log('UNMOUNTED')
            unsubscribe()
        }
    }, [])

    return [ isLoading, data ]
}

