import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut, createUser } from '../../store/actions/authActions'
import {
	Alert,
	Button,
	Col,
	ControlLabel,
	FormControl,
	FormGroup,
	Grid,
	Panel,
	Row,
	SplitButton,
	MenuItem
} from 'react-bootstrap'

class CreateUser extends Component {
	state = {
		email: '',
		initials: '',
		firstName: '',
		lastName: '',
		disabled: false,
		type: 0,
		typeMapped: 'Motorista',
		registry: ''
	}

	handleChange = e => {
		if (typeof e === 'string') {
			this.setState({ type: parseInt(e) })

			switch (parseInt(e)) {
				case 0:
					this.setState({ typeMapped: 'Motorista' })
					break
				case 1:
					this.setState({ typeMapped: 'Operador' })
					break
				case 2:
					this.setState({ typeMapped: 'Fiscal de laboratório' })
					break
				case 3:
					this.setState({ typeMapped: 'Gerente de cozinha' })
					break
				case 4:
					this.setState({ typeMapped: 'Administrador' })
					break
				default:
					break
			}
		} else {
			this.setState({ [e.target.id]: e.target.value })
		}
	}

	handleSubmit = e => {
		e.preventDefault()

		this.setState({ disabled: true })
		this.props.createUser(this.state)
	}

	UNSAFE_UNSAFE_componentWillReceiveProps() {
		this.setState({ disabled: false })
	}

	render() {
		const { auth, authError, profile, signOut } = this.props

		if (!auth.uid || profile.type === 'driver') return <Redirect to={'/'} />
		else if (profile.type < 4) signOut()

		return (
			<Grid>
				<Row className="show-grid">
					<Col xs={12} md={12}>
						<Panel ref={this.panel}>
							<Panel.Heading>Novo Usuário</Panel.Heading>
							<Panel.Body>
								<form onSubmit={this.handleSubmit}>
									<SplitButton
										bsStyle={'primary'}
										title={this.state.typeMapped}
										key={'type'}
										id={'type'}
										onSelect={this.handleChange}
										disabled={this.state.disabled}
									>
										<MenuItem eventKey="0">
											Motorista
										</MenuItem>
										<MenuItem eventKey="1">
											Operador
										</MenuItem>
										<MenuItem eventKey="2">
											Fiscal de laboratório
										</MenuItem>
										<MenuItem eventKey="3">
											Gerente de cozinha
										</MenuItem>
										<MenuItem eventKey="4">
											Administrador
										</MenuItem>
									</SplitButton>
									<br />
									<br />

									<FormElement
										id={'firstName'}
										field={'Nome'}
										type={'text'}
										handleChange={this.handleChange}
										disabled={this.state.disabled}
									/>
									<FormElement
										id={'lastName'}
										field={'Sobrenome'}
										type={'text'}
										handleChange={this.handleChange}
										disabled={this.state.disabled}
									/>
									<FormElement
										id={'registry'}
										field={'Matrícula'}
										type={'text'}
										handleChange={this.handleChange}
										disabled={this.state.disabled}
									/>

									<FormGroup>
										<Button
											type="submit"
											disabled={this.state.disabled}
										>
											Criar Usuário
										</Button>
									</FormGroup>

									{authError &&
									'errorInfo' in authError &&
									'message' in authError['errorInfo'] ? (
										<Alert bsStyle="danger">
											{' '}
											<strong>
												{authError.errorInfo.message}
											</strong>
										</Alert>
									) : null}
								</form>
							</Panel.Body>
						</Panel>
					</Col>
				</Row>
			</Grid>
		)
	}
}

class FormElement extends Component {
	render() {
		return (
			<FormGroup>
				<ControlLabel>{this.props.field}</ControlLabel>
				<FormControl
					type={this.props.type}
					placeholder={this.field}
					id={this.props.id}
					onChange={this.props.handleChange}
					disabled={this.props.disabled}
				/>
			</FormGroup>
		)
	}
}

const mapStateToProps = state => {
	return {
		auth: state.firebase.auth,
		authError: state.auth.authError,
		profile: state.firebase.profile
	}
}

const mapDispatchToProps = dispatch => {
	return {
		createUser: newUser => dispatch(createUser(newUser)),
		signOut: () => dispatch(signOut())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)
