import React from 'react'
import { FlexibleWidthXYPlot, VerticalBarSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, DiscreteColorLegend} from 'react-vis'
import { Button, Glyphicon } from 'react-bootstrap'
import './index.css'
import { tickFormatter, dateToString } from '../../util'
import { useFirestore, useFirestoreGet } from '../../hooks/firebase'
import Loading from '../loading'
import * as firebase from "firebase/app"
import "firebase/firestore";
import {saveAs} from "file-saver";


const GREEN = '#66bb6a'
const BLUE = '#42a5f5'

const SHIFTS = [
    {
        starts: '07:00:00',
        ends: '19:00:00',
        label: 'dia',
        color: GREEN,
        title: 'Dia'
    },
    {
        starts: '19:00:00',
        ends: '07:00:00',
        label: 'noite',
        color: BLUE,
        title: 'Noite'
    }
]

const state = {
	dataEnd: '',
    dataInit: '',
    rank: []
}

function defineShift (date: Date = new Date(), shifts: Array = [{starts: '19:00:00', ends: '07:00:00', label: 'night'}]) {

    const returnSecondsFromDate = (_date: Date) => {
        const hours = _date.getHours()
        const minutes = _date.getMinutes()
        const seconds = _date.getSeconds()
        return seconds + minutes * 60 + hours * 60 * 60
    }

    const returnSecondsFromString = (_date: String = '00:00:00') => {
        const dateSplitted = _date.split(':')
        const hours = Number(dateSplitted[0])
        const minutes = Number(dateSplitted[1])
        const seconds = Number(dateSplitted[2])
        return hours * 60 * 60 + minutes * 60 + seconds
    }

    const pickShift = (_date: Date, shifts: Array) => {

        const dateInSeconds = returnSecondsFromDate(_date)

        const shift = shifts.map(turn => {
            let { starts, ends, label } = turn
            if (starts > ends) {
                if (dateInSeconds >= returnSecondsFromString(starts) || dateInSeconds <= returnSecondsFromString(ends))
                    return label
            } else {
                if (dateInSeconds <= returnSecondsFromString(ends) && dateInSeconds >= returnSecondsFromString(starts))
                    return label
            }
            return false
        })

        return shift

    }

    return pickShift(date, shifts).filter( shift => shift)[0]

}

const setHistoryData = (rank: Object = {}, historyData: Object = {}) => {
    // console.log("historyData")
    // console.log(historyData)
    
    let { timestamp: { seconds: timestamp }, source: { resource } } = historyData
    let _date = new Date(timestamp * 1000)
    
    let dateFilterInit = new Date(state.dataInit)
    let dataFilterEnd = new Date(state.dataEnd)
    
    dateFilterInit.setDate(dateFilterInit.getDate())
    dataFilterEnd.setDate(dataFilterEnd.getDate())
    
    dateFilterInit.setHours(0)
    dateFilterInit.setMinutes(0)
    dateFilterInit.setSeconds(0)
    
    dataFilterEnd.setHours(23)
    dataFilterEnd.setMinutes(59)
    dataFilterEnd.setSeconds(59)

    if(dateFilterInit<_date && dataFilterEnd >= _date){
        let date = dateToString(_date)
        let shift = defineShift(_date, SHIFTS)

        if (!rank[date])
            rank[date] = {}
        
        if (!rank[date][resource])
            rank[date][resource] = {}

        if (!rank[date]['export'])
            rank[date]['export'] = {}
        
        if (!rank[date]['export'][_date])
            rank[date]['export'][_date] = {}

        if (!rank['total'][resource]) {
            rank['total'][resource] = {}
            SHIFTS.forEach( ({ label: _shift }) => rank['total'][resource][_shift] = 0)
        }   

        
        rank['total'][resource][shift] = rank['total'][resource][shift] + 1
        rank[date][resource][shift] = rank[date][resource][shift] ? rank[date][resource][shift] + 1 : 1

        rank[date]['export'][_date] = resource
        // rank['data'] = rank['data'] ? [...rank['data'], data] : [{data}]
    }
    // console.log("rank")
    // console.log(rank)
    return rank
}

const setChartsFromHistoryData = (data: Array) => {
    const charts = {}   
    SHIFTS.forEach( ({ label }) => {
        charts[label] = []
        for (const resource in data) {
            if (data.hasOwnProperty(resource)) {
                const resourceData = data[resource];
                charts[label] = [ ...charts[label], { x: resource, y: resourceData[label ]}]
            }
        }
    })
    return charts
}

const getFilteredTags = (data, page, amount) => {
    let _data = data[0]
    return _data.slice((page-1)*amount, page*amount).map( ({ x }) => x)
}

export default function ResourcesChart (props, dataInit) {


    async function download(){
        var Excel = require('exceljs');
        // A new Excel Work Book
        var workbook = new Excel.Workbook();

        // Some information about the Excel Work Book.
        workbook.creator = 'Matheus Oliveira';
        workbook.lastModifiedBy = '';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();

        var sheet = workbook.addWorksheet("Overview");
        sheet.columns = [
            { header: 'Data/ Horário', key: 'date' },
            { header: 'Placa', key: 'plate' },
        ]
        Object.keys(state.rank).forEach(function(item){
            if(item !== "total"){
                Object.keys(state.rank[item].export).forEach(function(data){
                    sheet.addRow({date: data, plate: state.rank[item].export[data]});
                });
                
            }
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileExtension = '.xlsx';
        
        const blob = new Blob([buffer], {type: fileType});
        
        if(arrayURL[4] != null){
            var dataURL = String(arrayURL[4]).split('$')
            var dataInit = String(dataURL[0]).split('-')
            var dataEnd = String(dataURL[1]).split('-')
            saveAs(blob, 'Overview_of_Plate_'+ dataInit[2] + "/" + (parseInt(dataInit[1])) + "/" + dataInit[0] + "-"+ dataEnd[2] + "/" + (parseInt(dataEnd[1])) + "/" + dataEnd[0] + fileExtension);
        } else {
            let date = new Date()
            saveAs(blob, 'Overview_of_Plate_mes_'+ (date.getMonth()+1) + fileExtension);
        }


    }

    var arrayURL = String(window.location).split('/')

    if(arrayURL[4] != null){
        var arrayURL = String(window.location).split('/')

        var dataURL = String(arrayURL[4]).split('$')

        state.dataEnd = dataURL[1]
        state.dataInit = dataURL[0]   

        const resourceAssignmentRef = firebase.firestore().collection('historico').where('changes.definedResource', '>', '')    


        const [ isLoading, dataFetched ] = useFirestoreGet(resourceAssignmentRef)

        const amount = props.amount || 15

        const page = props.page || 1
        
        const sortData = ( a, b ) => {
            const { y: yA, x: xA } = a
            const { y: yB, x: xB } = b
            
            const findA = ({ x }) => x === xA
            const findB = ({ x }) => x === xB
        
            const valueA = yA + data[1].find(findA).y
            const valueB = yB + data[1].find(findB).y
            
            if (valueA > valueB) return -1
            if (valueA < valueB) return +1
            if (yA > yB) return -1
            if (yA < yB) return +1
            return 0
        
        }

        let data = []

        let content = <Loading/>

        if (!isLoading) {
            const { total} = dataFetched.reduce(setHistoryData, {total: []})
            const rank = dataFetched.reduce(setHistoryData, {total: []})
            state.rank = rank;

            let objectData = setChartsFromHistoryData(total)

            data = Object.keys(objectData).map(key => objectData[key])

            
            const filterData = d => getFilteredTags(data, page, amount).includes(d.x)
            
            data[0].sort(sortData)

            content = (
                <div>
                    <FlexibleWidthXYPlot
                        height={300 + 202}
                        xType={'ordinal'}
                        margin={{ bottom: 150 }}
                    >
                        <DiscreteColorLegend orientation={'horizontal'} height={100} items={SHIFTS} getTitle={d => d.label}/>
                        <VerticalGridLines/>
                        <HorizontalGridLines/>
                        <XAxis
                            tickLabelAngle={-90}
                            style={{
                                text: {
                                    stroke: 'none',
                                    fill: '#000',
                                    fontWeight: 600,
                                    maxWidth: 2,
                                }
                            }}
                            tickFormat={tickFormatter}
                            position={'start'}
                            className={'XAxis'}
                        />
                        <YAxis/>
                        <VerticalBarSeries
                            cluster={'1'}
                            data={data[0].filter(filterData)}
                            color={GREEN}
                        />
                        <VerticalBarSeries
                            cluster={'2'}
                            data={data[1].filter(filterData)}
                            color={BLUE}
                        />
                    </FlexibleWidthXYPlot>
                    <br/>
                    <br/>
                    <br/>
                    <Button style={{marginRight: 10}} onClick={download}>
                        Exportar Dados {" "}
                        <Glyphicon glyph={'glyphicon glyphicon-download-alt'}/>
				    </Button>

                </div>


            )
            
        }
        

        return content
    } else {
        let dateFilterInit = new Date()
        dateFilterInit.setDate(1)
        dateFilterInit.setHours(0)
        dateFilterInit.setMinutes(0)
        dateFilterInit.setSeconds(0)
        let yearInit = dateFilterInit.getFullYear()
        var montInit = Number(dateFilterInit.getMonth()+1)
    
        let dataFilterEnd = new Date()
        dataFilterEnd.setDate(dataFilterEnd.getDate()) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        let montEnd = dataFilterEnd.getMonth()+1
        
        state.dataEnd = dataFilterEnd
        state.dataInit = dateFilterInit  

        const resourceAssignmentRef = firebase.firestore().collection('historico').where('changes.definedResource', '>', '')    


        const [ isLoading, dataFetched ] = useFirestoreGet(resourceAssignmentRef)

        const amount = props.amount || 15

        const page = props.page || 1
        
        const sortData = ( a, b ) => {
            const { y: yA, x: xA } = a
            const { y: yB, x: xB } = b
            
            const findA = ({ x }) => x === xA
            const findB = ({ x }) => x === xB
        
            const valueA = yA + data[1].find(findA).y
            const valueB = yB + data[1].find(findB).y
            
            if (valueA > valueB) return -1
            if (valueA < valueB) return +1
            if (yA > yB) return -1
            if (yA < yB) return +1
            return 0
        
        }

        let data = []

        let content = <Loading/>

        if (!isLoading) {
            const { total} = dataFetched.reduce(setHistoryData, {total: []})
            const rank = dataFetched.reduce(setHistoryData, {total: []})
            state.rank = rank;

            let objectData = setChartsFromHistoryData(total)

            data = Object.keys(objectData).map(key => objectData[key])

            const filterData = d => getFilteredTags(data, page, amount).includes(d.x)
            
            data[0].sort(sortData)
            content = (
                <div>
                    <FlexibleWidthXYPlot
                        height={300 + 202}
                        xType={'ordinal'}
                        margin={{ bottom: 150 }}
                    >
                        <DiscreteColorLegend orientation={'horizontal'} height={100} items={SHIFTS} getTitle={d => d.label}/>
                        <VerticalGridLines/>
                        <HorizontalGridLines/>
                        <XAxis
                            tickLabelAngle={-90}
                            style={{
                                text: {
                                    stroke: 'none',
                                    fill: '#000',
                                    fontWeight: 600,
                                    maxWidth: 2,
                                }
                            }}
                            tickFormat={tickFormatter}
                            position={'start'}
                            className={'XAxis'}
                        />
                        <YAxis/>
                        <VerticalBarSeries
                            cluster={'1'}
                            data={data[0].filter(filterData)}
                            color={GREEN}
                        />
                        <VerticalBarSeries
                            cluster={'2'}
                            data={data[1].filter(filterData)}
                            color={BLUE}
                        />
                    </FlexibleWidthXYPlot>
                    <br/>
                    <br/>
                    <br/>
                    <Button style={{marginRight: 10}} onClick={download}>
                        Exportar Dados {" "}
                        <Glyphicon glyph={'glyphicon glyphicon-download-alt'}/>
				    </Button>
                </div>
            )
            
        }
        

        return content
    }
}


