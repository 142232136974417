import React from 'react'

import * as firebase from "firebase/app"
import "firebase/firestore";

import { Query, Session } from './core'
import History from '../history/history'
import Warehouse from '../warehouse/warehouse'

const PI = Math.PI
const GREEN = '#66bb6a'
const RED = '#ef5350'
const BLUE = '#42a5f5'
const DAYS_OFFSET = 30

export default class Dash extends Query {

  constructor (props) {
    super(props)
    Session.username = 'jefferson.machado@sillion.com.br'
    Session.password = 'jefferson.sillion'
    Session.customerId = '26292'
  }
  
  calcRad = (x, total) => (2*x/total)*PI

  dataFormat = (group, angle0, angle, radius0, radius, color) => {
    return ({
      title: group,
      angle0: angle0,
      angle: angle,
      radius0: radius0,
      radius: radius,
      color: color
    })
  }

  async ignition () {
    const ignition = await Query.resource('holder_memory').limit(1000).get()
    const holdersOn = ignition.filter(({ignition}) => ignition)
    const holdersOff = ignition.filter(({ignition}) => !ignition)
    return ({
      on: {
        count: holdersOn.length,
        holders: holdersOn
      },
      off: {
        count: holdersOff.length,
        holders: holdersOff
      },
    })
  }

  async lastReport () {
    const GROUP0 = 'inválido'
    const GROUP1 = 'até 12 horas'
    const GROUP2 = 'entre 12 e 24 horas'
    const GROUP3 = 'mais que 24 horas'

    let group0Count = 0
    let group1Count = 0
    let group2Count = 0
    let group3Count = 0
    let data = []

    
    const lastReport = lastReportDate => new Date(lastReportDate)
    const groupReportDate = (lastReport: Date) => {
      const isValid = date => lastReport instanceof Date && !isNaN(lastReport.getDate())
      if (!isValid(lastReport)) {
        group0Count++
        return GROUP0
      }
      const now = new Date()
      if (lastReport < now.setHours(now.getHours() - 12)) {
        group1Count++
        return GROUP1
      }
      if (lastReport > now.setHours(now.getHours() - 12)) {
        group2Count++
        return GROUP2
      }
      group3Count++
      return GROUP3
    }

    const holders = await Query.resource('holders_status').get()
    
    const holdersData = holders.map(({ holderid, lastReportDate }) => {
      let date = lastReport(lastReportDate)
      return ({
        holderid,
        lastReportDate: date,
        group: groupReportDate(date)
      })
    })

    const total = group0Count + group1Count + group2Count + group3Count

    const radGroup0 = this.calcRad(group0Count, total)
    const radGroup1 = this.calcRad(group1Count, total)
    const radGroup2 = this.calcRad(group2Count, total)
    const radGroup3 = this.calcRad(group3Count, total)
    
    if (radGroup0)
      data.push(
        {count: group0Count, ...this.dataFormat(`${GROUP0} (${group0Count})`, 0, radGroup0, 50, 100, RED)}
      )
    if (radGroup1)
      data.push(
        {count: group1Count, ...this.dataFormat(`${GROUP1} (${group1Count})`, radGroup0, radGroup0+radGroup1, 50, 100, BLUE)}
      )
    if (radGroup2)
      data.push(
        {count: group2Count, ...this.dataFormat(`${GROUP2} (${group2Count})`, radGroup0+radGroup1, radGroup0+radGroup1+radGroup2, 50, 100, GREEN)}
      )
    if (radGroup3)
      data.push(
        {count: group3Count, ...this.dataFormat(`${GROUP3} (${group3Count})`, radGroup1+radGroup2, radGroup0+radGroup1+radGroup2+radGroup3, 50, 100, 'grey')}
      )


    return ({
      holders: holdersData,
      data: data
    })

  }

  async suggestionsFromOptimizer () {

    let total = 0
    let totalConflictedData = 0
    let totalNonConflictedData = 0
    let data = []
    let date = new Date((new Date()).setDate((new Date()).getDate() - DAYS_OFFSET))

    const GROUP1 = 'Conflito'
    const GROUP2 = 'Aceito'

    const suggestionData = await History.where('changes.definedResource', '>', '').where('timestamp', '>', date).get()
      
    const conflictedData = suggestionData.reduce((accumulator, value) => {
      if (!('createdAtTimestamp' in value)) return accumulator      
      if (!value.optimizer) return accumulator

      total++

      const { definedResource, original } = value.optimizer

      if (String(definedResource).toUpperCase() === String(original).toUpperCase()) return accumulator
      return [...accumulator, value]

    }, [])

    totalConflictedData = conflictedData.length
    totalNonConflictedData = total - totalConflictedData

    const radConflicted = this.calcRad(totalConflictedData, total)
    const radNonConflicted = this.calcRad(totalNonConflictedData, total)

    if(radConflicted) data.push(this.dataFormat(`${GROUP1} (${totalConflictedData})`, 0, radConflicted, 50, 100, RED))
    if(radNonConflicted) data.push(this.dataFormat(`${GROUP2} (${totalNonConflictedData})`, radConflicted, radConflicted+radNonConflicted, 50, 100, BLUE))

    return data
  }

  async supervised () {

    let supervisedCount = 0, unsupervisedCount = 0, data = []
    const GROUP1 = 'Supervisionado', GROUP2 = 'Automático'
    const date = new Date((new Date()).setDate((new Date()).getDate() - DAYS_OFFSET))

    const countHistory = (accumulator, { source, serviceOrder }) => {
      if (!source || !serviceOrder) return accumulator
      const { id } = serviceOrder
      const { type } = source
      if (typeof type === 'string' && type.toUpperCase() !== 'WEB') return accumulator
      return ({
        ...accumulator,
        [id]: accumulator[id] ? accumulator[id]+1 : 1
      })
    }

    const historyFetched = await History.where('changes.status', '>', '').where('timestamp', '>', date).get(false, false)
    const historySupervised = historyFetched.reduce( countHistory , {} )
    const snap = await firebase.firestore().collection('allocables').where('createdAt', '>', date).get()
    const Data = snap.docs.map(doc => doc.data())
    const total = Data.length

    supervisedCount = Object.keys(historySupervised).length
    unsupervisedCount = total - supervisedCount

    const radSupervised = this.calcRad(supervisedCount, total)
    const radUnsupervised = this.calcRad(unsupervisedCount, total)

    if (radSupervised) data.push(this.dataFormat(`${GROUP1} (${supervisedCount})`, 0, radSupervised, 50, 100, RED))
    if (radUnsupervised) data.push(this.dataFormat(`${GROUP2} (${unsupervisedCount})`, radSupervised, radSupervised+radUnsupervised, 50, 100, BLUE))

    return data
  }

  async timeReport (fromDate: Date = new Date(new Date().setMonth((new Date()).getMonth() + 1 - 6)), toDate: Date = new Date()) {

    const sortDate = ({ x: a }, { x: b }) => {
      const dateA = new Date(a.split('/').reverse().join('/'))
      const dateB = new Date(b.split('/').reverse().join('/'))
      if (dateA > dateB) return 1
      if (dateA < dateB) return -1
      return 0
    }

    const from = {
      date: fromDate,
      month: fromDate.getMonth()+1,
      year: fromDate.getFullYear(),
    }
    const to = {
      date: toDate,
      month: toDate.getMonth()+1,
      year: toDate.getFullYear()
    }
    
    const { 
      monthly,
      daily,
      records
    } = await Warehouse.supervisor.getFromRange(from, to)
    return {
      monthly: monthly.sort(sortDate),
      daily: daily.sort(sortDate),
      records: records.reverse()
    }
  }

  async excessiveSpeed () {
    const speedOffense = await Query.resource('index').where('indexid', 'excesso_de_velocidade').get()

    return speedOffense
  }

  render() {
    return (
      <>
        DASH CLASS
      </>
    )
  }
}
