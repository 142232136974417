import React, { Component } from 'react'
import { Grid, Row, Col, Panel, Glyphicon } from 'react-bootstrap'

import Dash from './Dash'
import Ignition from './Dash_Ignition'
import LastReport from './Dash_LastReport'
import Suggestions from './Dash_Suggestions'
import Supervised from './Dash_Supervised'
import TimeChart from './Dash_TimeChart'

const SHIFTS = [
    {
        starts: '07:00:00',
        ends: '19:00:00',
        label: 'dia'
    },
    {
        starts: '19:00:00',
        ends: '07:00:00',
        label: 'noite'
    }
]

export default class Dashboard extends Component {
	state = {
		loading: true,
		ignition: {}
	}

	componentDidMount = async () => {
		const dash = new Dash()
		const ignition = await dash.ignition()
		const lastReport = await dash.lastReport()
		const suggestions = await dash.suggestionsFromOptimizer()
		const supervised = await dash.supervised()
		const { monthly, daily, records } = await dash.timeReport()
		
		this.setState(state => ({
			ignition: ignition,
			lastReport: lastReport,
			suggestions: suggestions,
			supervised: supervised,
			monthly: monthly,
			daily: daily,
			records: records,
			loading: false
		}))
	}

	

	render() {
		if (this.state.loading) return <Loading />
		
		const {
			ignition: { on, off },
			lastReport: { data: lastReportData },
			suggestions,
			supervised,
			monthly,
			daily,
			records
		} = this.state
		
		
		return (
			<Grid>
				<Row>
					<Col md={6}>
						<MyPanel title={'Veículos Ligados'}>
							<Ignition
								data={{ on: on.count, off: off.count }}
								title={'Ligado/Desligado'}
							/>
						</MyPanel>
					</Col>
					<Col md={6}></Col>
				</Row>
				<Row>
					<Col md={4}>
						<MyPanel title={'Último Reporte'}>
							<LastReport
								data={lastReportData}
								title={'Último Reporte'}
							/>
						</MyPanel>
					</Col>
					<Col md={4}>
						<MyPanel title={'Sugestões do Otimizador'}>
							<Suggestions
								data={suggestions}
								title={'Sugestões do Otimizador'}
							/>
						</MyPanel>
					</Col>
					<Col md={4}>
						<MyPanel title={'Tarefas Supervisionadas (Fiscal)'}>
							<Supervised data={supervised}/>
						</MyPanel>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<MyPanel title={'Assignação Manual (Por veículo)'}>
							<TimeChart data={records} title={'Assignação Manual (Por veículo)'}/>
						</MyPanel>
					</Col>
					<Col md={6}>
						<MyPanel title={'Assignação Manual (Mensal)'}>
							<TimeChart data={monthly} title={'Assignação Manual (Mensal)'}/>
						</MyPanel>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<MyPanel title={'Assignação Manual (Diária)'}>
							<TimeChart data={daily} title={'Assignação Manual (Diária)'}/>
						</MyPanel>
					</Col>
				</Row>
			</Grid>
		)
	}
}

class MyPanel extends Component {
	render() {
		return (
			<Panel style={{ height: '100%' }}>
				<Panel.Heading>
					<Panel.Title componentClass={'h5'}>
						{String(
							this.props.title || 'TITLE NOT DEFINED'
						).toUpperCase()}
					</Panel.Title>
				</Panel.Heading>
				<Panel.Body style={{ ...this.props.style, height: '85%' }}>
					{this.props.children}
				</Panel.Body>
			</Panel>
		)
	}
}

class Loading extends Component {
	render() {
		return (
			<div
				style={{
					textAlign: 'center',
					fontSize: 20
				}}
			>
				<Glyphicon glyph={'glyphicon glyphicon-refresh'}>
					LOADING{' '}
				</Glyphicon>{' '}
			</div>
		)
	}
}

const defineShift = (date: Date = new Date(), shifts: Array = [{starts: '19:00:00', ends: '07:00:00', label: 'night'}]) => {

    const returnSecondsFromDate = (_date: Date) => {
        const hours = _date.getHours()
        const minutes = _date.getMinutes()
        const seconds = _date.getSeconds()
        return seconds + minutes * 60 + hours * 60 * 60
    }

    const returnSecondsFromString = (_date: String = '00:00:00') => {
        const dateSplitted = _date.split(':')
        const hours = Number(dateSplitted[0])
        const minutes = Number(dateSplitted[1])
        const seconds = Number(dateSplitted[2])
        return hours * 60 * 60 + minutes * 60 + seconds
    }

    const pickShift = (_date: Date, shifts: Array) => {

        const dateInSeconds = returnSecondsFromDate(_date)

        const shift = shifts.map(turn => {
            let { starts, ends, label } = turn
            if (starts > ends) {
                if (dateInSeconds >= returnSecondsFromString(starts) || dateInSeconds <= returnSecondsFromString(ends))
                    return label
            } else {
                if (dateInSeconds <= returnSecondsFromString(ends) && dateInSeconds >= returnSecondsFromString(starts))
                    return label
            }
            return false
        })

        return shift

    }

    return pickShift(date, shifts).filter( shift => shift)[0]

}
