import React, { Component,  } from "react";
import { Col, Row, Grid, Table, Badge, Button, Glyphicon} from 'react-bootstrap'
import Select from 'react-select'
import './index.css'
import { text } from '../../config/translate'
import SuggestionsChart from './SuggestionsChart'
import ResourcesChart from './ResourcesChart'
import OperationTypesChart from './OperationTypesChart'
import StatusCard from './StatusCard'
import DeathsChart from './DeathsChart'
import { useAllocableListener } from '../../hooks/listeners'
import { dateSetHours, statusColor } from '../../util'
import * as firebase from "firebase/app"
import "firebase/firestore";
import {saveAs} from "file-saver";

const BODY_HOURS_DELAY = 2
const ALLOCABLES_DELAY = 24
const ALLOCABLE_TYPES = [
	{
		name: 'body',
		status: [
			'new', 'started', 'arrived_body', 'at_lab', 'finished'
		],
		img: require('../../resources/icons/car.svg')
	},{
		name: 'funeral',
		status: [
			'new', 'started', 'arrived_funeral', 'finished'
		],
		img: require('../../resources/icons/church.svg')
	},{
		name: 'burial',
		status: [
			'new', 'started', 'picked_up_body_funeral', 'finished'
		],
		img: require('../../resources/icons/grave.svg')
	},{
		name: 'funeral_return',
		status: [
			'new', 'started', 'finished'
		],
		img: require('../../resources/icons/wreath.svg')
	}
]
const state = {
	data: [],
	isLoading: true,
	resource: false,
	os: '',
	dataEnd:'',
	dataInit: '',
	timestamp: '',
	createdAt: 'null',
	ref: ''
}

const OperationStatus = props => {
	//push paramenter to url and verify if exist  
	const [ isLoading, dataFetched ] = useAllocableListener()
	var arrayURL = String(window.location).split('/')
	var dates =  String(arrayURL[4]).split('$')
	var data = new Date()
	let month = data.getMonth()+1
	let monthName = data.getMonth()+1
	let year = data.getFullYear()
	
	if(Number(monthName)<9){
		monthName = "0" + monthName
	}

	var d = String(dates[0]).split('-')
	var date = new Date(d[0], d[1]-1, d[2])
	d = String(dates[1]).split('-')
	var date1 = new Date(d[0], d[1]-1, d[2]) 

	if(arrayURL[4] != null){
		if (!isLoading && dataFetched){
			return (
				<Grid className={'main-container'}>
					<Row>
						<Col md={12}>
							<div className={'communication-main-container'}>
								{lastCommunication(isLoading, dataFetched)}
							</div> 
						</Col>
					</Row>
					<Row className={'main-container-cards'}>
						<Col>
							{StatusCardFactory(dataFetched)}
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<div className={'solutions-main-container'}>
								{Solutions(isLoading, dataFetched)}
							</div>
						</Col>
					</Row>
					<Row style={{marginTop: '1em'}}>
						<Col md={12}>
							<div className="filter-main-container">
								{Filter()}
							</div>
						</Col>
					</Row> 
					{/* <Row style={{marginTop: '1em'}}>
						<Col md={12}>
							<Button style={{marginRight: 10}} onClick={download}>
								Exportar Dados {" "}
								<Glyphicon glyph={'glyphicon glyphicon-download-alt'}/>
                    		</Button>
						</Col>
					</Row> */}
					<Row style={{marginTop: '1em'}}>
						<Col md={6}>	
							<div className="solutions-main-container">
								<Suggestions title={'Visão geral das atividades de - ' +  date.getDate()+ '/'+ (date.getMonth()+1) + '/' + date.getFullYear() +' até '+  date1.getDate()+ '/'+ (date1.getMonth()+1) + '/' + date1.getFullYear()}/>
							</div>
						</Col>
						<Col md={6}>
							<div className="solutions-main-container">
								<OperationTypes title={'Visão geral dos tipos de ativdade de -' + date.getDate()+ '/'+ (date.getMonth()+1) + '/' + date.getFullYear() +' até '+  date1.getDate()+ '/'+ (date1.getMonth()+1) + '/' + date1.getFullYear()}/>
							</div>
						</Col>
						<Col md={6}>
							<div className="solutions-main-container">
								<Resources title={'Atividades total por Veículo de - '+   date.getDate()+ '/'+ (date.getMonth()+1) + '/' + date.getFullYear() +' até '+  date1.getDate()+ '/'+ (date1.getMonth()+1) + '/' + date1.getFullYear()}/>
							</div>
						</Col> 
						<Col md={6}>
							<div className="solutions-main-container">
								<Deaths title={'Quantidades de óbitos de - ' +   date.getDate()+ '/'+ (date.getMonth()+1) + '/' + date.getFullYear() +' até '+  date1.getDate()+ '/'+ (date1.getMonth()+1) + '/' + date1.getFullYear()}/>
							</div>
						</Col>
					</Row> 
				
				</Grid>
			)
		}
	}else{
		if (!isLoading && dataFetched) {
			return (
				<Grid className={'main-container'}>
					<Row>
						<Col md={12}>
							<div className={'communication-main-container'}>
								{lastCommunication(isLoading, dataFetched)}
							</div> 
						</Col>
					</Row>
					<Row className={'main-container-cards'}>
						<Col>
							{StatusCardFactory(dataFetched)}
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<div className={'solutions-main-container'}>
								{Solutions(isLoading, dataFetched)}
							</div>
						</Col>
					</Row>
					<Row style={{marginTop: '1em'}}>
						<Col md={12}>
							<div className="filter-main-container">
								{Filter()}
							</div>
						</Col>
					</Row> 


					<Row style={{marginTop: '1em'}}>
						<Col md={6}>	
							<div className="solutions-main-container">
								<Suggestions title={'Visão geral das atividades do mês atual - ' + monthName +'/'+ year}/>
							</div>
						</Col>
						<Col md={6}>
							<div className="solutions-main-container">
								<OperationTypes title={'Visão geral dos tipos de ativdade do mês atual - ' + monthName +'/'+ year}/>
							</div>
						</Col>
						<Col md={6}>
							<div className="solutions-main-container">
								<Resources title={'Atividades total por Veículo do mês atual - '+  monthName +'/'+ year}/>
							</div>
						</Col>
						<Col md={6}>
							<div className="solutions-main-container">
								<Deaths title={'Quantidade de óbitos do mês atual - '+  monthName +'/'+ year}/>
							</div>
						</Col>
					</Row>  
					
				
				</Grid>
			)
		}
	}
	return <Loading/>
	
}

export default OperationStatus

async function downloadHistory() {
	
	const timestampToDate = (date) => {
		let year = date.getUTCFullYear().toString()
		let month = date.getMonth()+1
		let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
		let hour = date.getHours()
		let minutes = date.getMinutes()
		let seconds = date.getSeconds()
		return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`
	  }

	var arrayURL = String(window.location).split('/')

	let dateFilterInit
    let dataFilterEnd
    let yearInit
    var montInit
    let montEnd

	if(arrayURL[4] != null){

        var dataURL = String(arrayURL[4]).split('$')

        dateFilterInit = new Date(dataURL[0])
        dateFilterInit.setDate(dateFilterInit.getDate() + 1)
        dateFilterInit.setHours(0)
        yearInit = dateFilterInit.getFullYear()
        montInit = Number(dateFilterInit.getMonth()+1)
    
        dataFilterEnd = new Date(dataURL[1])
        dataFilterEnd.setDate(dataFilterEnd.getDate() + 1) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        montEnd = dataFilterEnd.getMonth()+1

	} else{
		dateFilterInit = new Date()
        dateFilterInit.setDate(1)
        dateFilterInit.setHours(0)
        yearInit = dateFilterInit.getFullYear()
        montInit = Number(dateFilterInit.getMonth()+1)
    
        dataFilterEnd = new Date()
        dataFilterEnd.setDate(dataFilterEnd.getDate() + 1) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        montEnd = dataFilterEnd.getMonth()+1
	}

	let dataTotal = []
	let doc  = await firebase.firestore().collection('historico').where('timestamp', '<=', dataFilterEnd)
																.where('timestamp', '>=', dateFilterInit).get()
	.then( snapshot => {
        snapshot.forEach(doc => {
			if(doc.data().chance !== undefined && doc.data().chance.status !== undefined){
				if(doc.data().user.firstName !== undefined ){
					dataTotal.push({
						serviceOrder: doc.data().data.serviceOrder,
						createdAt: timestampToDate(new Date(doc.data().data.createdAt.seconds * 1000)),
						name: doc.data().data.name,
						plate: doc.data().data.plate,
						funerary: doc.data().source.resource,
						type: text(doc.data().type),	
						typeOS: text(doc.data().data.type),
						status:	text(doc.data().data.status),
						//promiseTime: doc.data().data.promiseTime,
						promiseTime: timestampToDate(new Date(doc.data().data.promiseTime.seconds * 1000)),
						timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000)),
						user: doc.data().user.firstName + " " + doc.data().user.lastName
					})
				}else{
					dataTotal.push({
						serviceOrder: doc.data().data.serviceOrder,
						createdAt: timestampToDate(new Date(doc.data().data.createdAt.seconds * 1000)),
						name: doc.data().data.name,
						plate: doc.data().data.plate,
						funerary: doc.data().source.resource,
						type: text(doc.data().type),	
						typeOS: text(doc.data().data.type),
						status:	text(doc.data().data.status),
						//promiseTime: doc.data().data.promiseTime,
						promiseTime: timestampToDate(new Date(doc.data().data.promiseTime.seconds * 1000)),
						timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000)),
						user: doc.data().user
					})
				}
			}
			else{
				if(doc.data().data !== undefined){
					if(doc.data().data.promiseTime !== undefined){
						if(doc.data().data.createdAt !== undefined){
							if(doc.data().user.firstName !== undefined ){
								dataTotal.push({
									serviceOrder: doc.data().data.serviceOrder,
									createdAt: timestampToDate(new Date(doc.data().data.createdAt.seconds * 1000)),
									name: doc.data().data.name,
									plate: doc.data().data.plate,
									funerary: doc.data().data.funerary,
									type: text(doc.data().type),	
									typeOS: text(doc.data().data.type),
									status:	text(doc.data().data.status),
									//promiseTime: doc.data().data.promiseTime,
									promiseTime: timestampToDate(new Date(doc.data().data.promiseTime.seconds * 1000)),
									timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000)),
									user: doc.data().user.firstName + " " + doc.data().user.lastName
								})
							}else{
								dataTotal.push({
									serviceOrder: doc.data().data.serviceOrder,
									createdAt: timestampToDate(new Date(doc.data().data.createdAt.seconds * 1000)),
									name: doc.data().data.name,
									plate: doc.data().data.plate,
									funerary: doc.data().data.funerary,
									type: text(doc.data().type),	
									typeOS: text(doc.data().data.type),
									status:	text(doc.data().data.status),
									//promiseTime: doc.data().data.promiseTime,
									promiseTime: timestampToDate(new Date(doc.data().data.promiseTime.seconds * 1000)),
									timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000)),
									user: doc.data().user.email
								})
							}
						} else {
							if(doc.data().user.firstName !== undefined ){
								dataTotal.push({
									serviceOrder: doc.data().data.serviceOrder,
									createdAt: doc.data().data.createdAt,
									name: doc.data().data.name,
									plate: doc.data().data.plate,
									funerary: doc.data().data.funerary,
									type: text(doc.data().type),	
									typeOS: text(doc.data().data.type),
									status:	text(doc.data().data.status),
									//promiseTime: doc.data().data.promiseTime,
									promiseTime: timestampToDate(new Date(doc.data().data.promiseTime.seconds * 1000)),
									timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000)),
									user: doc.data().user.firstName + " " + doc.data().user.lastName
								})
							}else{
								dataTotal.push({
									serviceOrder: doc.data().data.serviceOrder,
									createdAt: doc.data().data.createdAt,
									name: doc.data().data.name,
									plate: doc.data().data.plate,
									funerary: doc.data().data.funerary,
									type: text(doc.data().type),	
									typeOS: text(doc.data().data.type),
									status:	text(doc.data().data.status),
									//promiseTime: doc.data().data.promiseTime,
									promiseTime: timestampToDate(new Date(doc.data().data.promiseTime.seconds * 1000)),
									timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000)),
									user: doc.data().user.email
								})
							}

						}
					}else{
						if(doc.data().data.createdAt !== undefined){
							if(doc.data().user.firstName !== undefined ){
								dataTotal.push({
									serviceOrder: doc.data().data.serviceOrder,
									createdAt: timestampToDate(new Date(doc.data().data.createdAt.seconds * 1000)),
									name: doc.data().data.name,
									plate: doc.data().data.plate,
									funerary: doc.data().data.funerary,
									type: text(doc.data().type),	
									typeOS: text(doc.data().data.type),
									status:	text(doc.data().data.status),
									promiseTime: doc.data().data.promiseTime,
									//promiseTime: new Date(doc.data().data.promiseTime.seconds * 1000).toUTCString(),
									timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000)),
									user: doc.data().user.firstName + " " + doc.data().user.lastName
								})
							}else{
								dataTotal.push({
									serviceOrder: doc.data().data.serviceOrder,
									createdAt: timestampToDate(new Date(doc.data().data.createdAt.seconds * 1000)),
									name: doc.data().data.name,
									plate: doc.data().data.plate,
									funerary: doc.data().data.funerary,
									type: text(doc.data().type),	
									typeOS: text(doc.data().data.type),
									status:	text(doc.data().data.status),
									promiseTime: doc.data().data.promiseTime,
									//promiseTime: new Date(doc.data().data.promiseTime.seconds * 1000).toUTCString(),
									timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000)),
									user: doc.data().user.email
								})
							}

						} else {
							if(doc.data().user.firstName !== undefined ){
								dataTotal.push({
									serviceOrder: doc.data().data.serviceOrder,
									createdAt: doc.data().data.createdAt,
									name: doc.data().data.name,
									plate: doc.data().data.plate,
									funerary: doc.data().data.funerary,
									type: text(doc.data().type),	
									typeOS: text(doc.data().data.type),
									status:	text(doc.data().data.status),
									promiseTime: doc.data().data.promiseTime,
									//promiseTime: new Date(doc.data().data.promiseTime.seconds * 1000).toUTCString(),
									timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000)),
									user: doc.data().user.firstName + " " + doc.data().user.lastName
								})
							}else{
								dataTotal.push({
									serviceOrder: doc.data().data.serviceOrder,
									createdAt: doc.data().data.createdAt,
									name: doc.data().data.name,
									plate: doc.data().data.plate,
									funerary: doc.data().data.funerary,
									type: text(doc.data().type),	
									typeOS: text(doc.data().data.type),
									status:	text(doc.data().data.status),
									promiseTime: doc.data().data.promiseTime,
									//promiseTime: new Date(doc.data().data.promiseTime.seconds * 1000).toUTCString(),
									timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000)),
									user: doc.data().user.email
								})
							}
						}
					}
				}
			}
        })
        
	})		
	

	var Excel = require('exceljs');
	// A new Excel Work Book
	var workbook = new Excel.Workbook();

	// Some information about the Excel Work Book.
	workbook.creator = 'Matheus Oliveira';
	workbook.lastModifiedBy = '';
	workbook.created = new Date();
	workbook.modified = new Date();
	workbook.lastPrinted = new Date();


	// Create a sheet
	var sheet = workbook.addWorksheet('Base de Dados');
	// A table header
	sheet.columns = [
		{ header: 'Tipo', key: 'type' },
		{ header: 'OS', key: 'serviceOrder' },
		{ header: 'Tipo da OS', key: 'typeOS' },
		{ header: 'Status', key: 'status' },
		{ header: 'Nome', key: 'name' },
		{ header: 'Funerária', key: 'funerary' },
		{ header: 'Placa', key: 'plate' },
		{ header: 'Data Criação', key: 'createdAt' },
		{ header: 'Data Marcada', key: 'promiseTime' },
		{ header: 'Data da Atualização', key: 'timestamp' },
		{ header: 'Usuário', key: 'user' },
		
	]
	// Add rows in the above header
	Object.keys(dataTotal).forEach(function(item){
		//sheet.addRow({name: state.dataTotal[item].name, os: state.dataTotal[item].os, promiseTime: state.dataTotal[item].promiseTime, createdAt: state.dataTotal[item].createdAt });
		sheet.addRow({
			type: dataTotal[item].type,
			serviceOrder: dataTotal[item].serviceOrder, 
			typeOS: dataTotal[item].typeOS, 
			status: dataTotal[item].status,
			name: dataTotal[item].name, 
			funerary: dataTotal[item].funerary, 
			plate: dataTotal[item].plate, 
			createdAt: dataTotal[item].createdAt,
			promiseTime: dataTotal[item].promiseTime,
			timestamp: dataTotal[item].timestamp,
			user: dataTotal[item].user
		});
	})


	const buffer = await workbook.xlsx.writeBuffer();
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const fileExtension = '.xlsx';
	
	const blob = new Blob([buffer], {type: fileType});
	
	if(arrayURL[4] != null){
		var dataURL = String(arrayURL[4]).split('$')
		var dataInit = String(dataURL[0]).split('-')
		var dataEnd = String(dataURL[1]).split('-')
		saveAs(blob, 'Dados_do_Historico_'+ dataInit[2] + "/" + (parseInt(dataInit[1])) + "/" + dataInit[0] + "-"+ dataEnd[2] + "/" + (parseInt(dataEnd[1])) + "/" + dataEnd[0] + fileExtension);
	} else {
		let date = new Date()
		saveAs(blob, 'Dados_do_Historico_mes_'+ (date.getMonth()+1) + fileExtension);
	}
}

async function download() {
	
	let users = []

	let user = await firebase.firestore().collection('users').get()
		.then(function(querySnapshot){
			querySnapshot.forEach(function(doc) {
				
				users.push({
					id: doc.id,
					user: doc.data().firstName + " " + doc.data().lastName
				})	
					
				});
	});
	


	const timestampToDate = (date) => {
		let year = date.getUTCFullYear().toString()
		let month = date.getMonth()+1
		let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
		let hour = date.getHours()
		let minutes = date.getMinutes()
		let seconds = date.getSeconds()
		return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`
	  }


	var arrayURL = String(window.location).split('/')

	let dateFilterInit
    let dataFilterEnd
    let yearInit
    var montInit
    let montEnd

	if(arrayURL[4] != null){

        var dataURL = String(arrayURL[4]).split('$')

        dateFilterInit = new Date(dataURL[0])
        dateFilterInit.setDate(dateFilterInit.getDate() + 1)
		dateFilterInit.setHours(0)
		dateFilterInit.setMinutes(0)
		dateFilterInit.setSeconds(0)
        yearInit = dateFilterInit.getFullYear()
        montInit = Number(dateFilterInit.getMonth()+1)
    
        dataFilterEnd = new Date(dataURL[1])
        dataFilterEnd.setDate(dataFilterEnd.getDate() + 1) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        montEnd = dataFilterEnd.getMonth()+1

	} else{
		dateFilterInit = new Date()
        dateFilterInit.setDate(1)
        dateFilterInit.setHours(0)
        yearInit = dateFilterInit.getFullYear()
        montInit = Number(dateFilterInit.getMonth()+1)
    
        dataFilterEnd = new Date()
        dataFilterEnd.setDate(dataFilterEnd.getDate() + 1) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        montEnd = dataFilterEnd.getMonth()+1
	}

	let history = []
	let his = await firebase.firestore().collection('history').where('timestamp', '<=', dataFilterEnd)
																.where('timestamp', '>=', dateFilterInit).get()
	.then(function(querySnapshot){
		querySnapshot.forEach(function(doc) {
			
				history.push({
					allocable: doc.data().allocable,
					timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000))
				});	
				
			});
	});

	let historico = []
	let hist = await firebase.firestore().collection('historico').where('changes.status', '==', "finished")
																	.where('timestamp', '<=', dataFilterEnd)
																	.where('timestamp', '>=', dateFilterInit).get()
	.then(function(querySnapshot){
		querySnapshot.forEach(function(doc) {
				if(doc.data().data !== undefined){
					history.push({
						allocable: doc.data().data.id,
						timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000))
					});	
				} else{
					history.push({
						allocable: doc.data().serviceOrder.id,
						timestamp: timestampToDate(new Date(doc.data().timestamp.seconds * 1000))
					});	
				}
				
			});
	});

	let dataTotal = []
	let doc  = await firebase.firestore().collection('allocables').where('promiseTime', '<=', dataFilterEnd)
																.where('promiseTime', '>=', dateFilterInit).get()
	.then( function(querySnapshot){
        querySnapshot.forEach(function(doc) {
				//console.log(doc.data());
				if(doc.data().lastUser !== undefined){
					
					dataTotal.push({
						serviceOrder: doc.data().serviceOrder,
						createdAt: timestampToDate(new Date(doc.data().createdAt.seconds * 1000)),
						name: doc.data().name,
						plate: doc.data().definedResource,
						funerary: doc.data().funerary,	
						typeOS: text(doc.data().type),
						status:	text(doc.data().status),
						//promiseTime: doc.data().data.promiseTime,
						promiseTime: timestampToDate(new Date(doc.data().promiseTime.seconds * 1000)),
						createdBy: doc.data().createdBy,
						lastUser: doc.data().lastUser,
						id: doc.data().id
					})
				} else{
					dataTotal.push({
						serviceOrder: doc.data().serviceOrder,
						createdAt: timestampToDate(new Date(doc.data().createdAt.seconds * 1000)),
						name: doc.data().name,
						plate: doc.data().definedResource,
						funerary: doc.data().funerary,	
						typeOS: text(doc.data().type),
						status:	text(doc.data().status),
						//promiseTime: doc.data().data.promiseTime,
						promiseTime: timestampToDate(new Date(doc.data().promiseTime.seconds * 1000)),
						createdBy: doc.data().createdBy,
						lastUser: "",
						id: doc.data().id
					})
				}

        })
        
	})	
	
	//console.log(dataTotal)

	let data = []
	Object.keys(dataTotal).forEach(async function(item){

		if(dataTotal[item].status === "Finalizado"){
			let finished = history.find(element => element.allocable === dataTotal[item].id)

			if(finished !== undefined){
				data.push({
					serviceOrder: dataTotal[item].serviceOrder,
					createdAt: dataTotal[item].createdAt,
					name: dataTotal[item].name,
					plate: dataTotal[item].plate,
					funerary: dataTotal[item].funerary,	
					typeOS: dataTotal[item].typeOS,
					status:	dataTotal[item].status,
					//promiseTime: doc.data().data.promiseTime,
					promiseTime: dataTotal[item].promiseTime,
					createdBy: dataTotal[item].createdBy,
					lastUser: dataTotal[item].lastUser,
					id: dataTotal[item].id,		
					dateFinished: finished.timestamp
				});	
			}else{
				data.push({
					serviceOrder: dataTotal[item].serviceOrder,
					createdAt: dataTotal[item].createdAt,
					name: dataTotal[item].name,
					plate: dataTotal[item].plate,
					funerary: dataTotal[item].funerary,	
					typeOS: dataTotal[item].typeOS,
					status:	dataTotal[item].status,
					//promiseTime: doc.data().data.promiseTime,
					promiseTime: dataTotal[item].promiseTime,
					createdBy: dataTotal[item].createdBy,
					lastUser: dataTotal[item].lastUser,
					id: dataTotal[item].id,		
					dateFinished: ""
				});	
			}
		}else{
			data.push({
				serviceOrder: dataTotal[item].serviceOrder,
				createdAt: dataTotal[item].createdAt,
				name: dataTotal[item].name,
				plate: dataTotal[item].plate,
				funerary: dataTotal[item].funerary,	
				typeOS: dataTotal[item].typeOS,
				status:	dataTotal[item].status,
				//promiseTime: doc.data().data.promiseTime,
				promiseTime: dataTotal[item].promiseTime,
				createdBy: dataTotal[item].createdBy,
				lastUser: dataTotal[item].lastUser,
				id: dataTotal[item].id,		
				dateFinished:""
			})
		}
	});


	//console.log(data)
		var Excel = require('exceljs');
		// A new Excel Work Book
		var workbook = new Excel.Workbook();

		// Some information about the Excel Work Book.
		workbook.creator = 'Matheus Oliveira';
		workbook.lastModifiedBy = '';
		workbook.created = new Date();
		workbook.modified = new Date();
		workbook.lastPrinted = new Date();



		// Create a sheet
		var sheet = workbook.addWorksheet('Base de Dados');
		// A table header
		sheet.columns = [
			{ header: 'OS', key: 'serviceOrder' },
			{ header: 'Tipo da OS', key: 'typeOS' },
			{ header: 'Status', key: 'status' },
			{ header: 'Nome', key: 'name' },
			{ header: 'Funerária', key: 'funerary' },
			{ header: 'Placa', key: 'plate' },
			{ header: 'Data Criação', key: 'createdAt' },
			{ header: 'Data Marcada', key: 'promiseTime' },
			{ header: 'Usuário', key: 'createdBy' },
			{ header: 'Último Usuário', key: 'lastUser' },
			{ header: 'Data Finalização', key: 'dateFinished' },
		]


		
		// Add rows in the above header
		Object.keys(data).forEach(async function(item){
			//sheet.addRow({name: state.dataTotal[item].name, os: state.dataTotal[item].os, promiseTime: state.dataTotal[item].promiseTime, createdAt: state.dataTotal[item].createdAt });
			//let user = await firebase.firestore().collection('users').doc(dataTotal[item].lastUser).get()
				//console.log(users[dataTotal[item].lastUser])
				let lastUser = users.find( element => element.id === data[item].lastUser)
				if(lastUser !== undefined){
					sheet.addRow({
							serviceOrder: data[item].serviceOrder, 
							typeOS: data[item].typeOS, 
							status: data[item].status,
							name: data[item].name, 
							funerary: data[item].funerary, 
							plate: data[item].plate, 
							createdAt: data[item].createdAt,
							promiseTime: data[item].promiseTime,
							createdBy: data[item].createdBy,
							lastUser: lastUser.user,
							dateFinished:data[item].dateFinished
					});
				} else {
					sheet.addRow({
						serviceOrder: data[item].serviceOrder, 
						typeOS: data[item].typeOS, 
						status: data[item].status,
						name: data[item].name, 
						funerary: data[item].funerary, 
						plate: data[item].plate, 
						createdAt: data[item].createdAt,
						promiseTime: data[item].promiseTime,
						createdBy: data[item].createdBy,
						lastUser: "",
						dateFinished:data[item].dateFinished
					});
				}
			
			

			
		});

		
		const buffer = await workbook.xlsx.writeBuffer();
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const fileExtension = '.xlsx';
		
		const blob = new Blob([buffer], {type: fileType});
		
		if(arrayURL[4] != null){
			var dataURL = String(arrayURL[4]).split('$')
			var dataInit = String(dataURL[0]).split('-')
			var dataEnd = String(dataURL[1]).split('-')
			saveAs(blob, 'Base_de_dados_'+ dataInit[2] + "/" + (parseInt(dataInit[1])) + "/" + dataInit[0] + "-"+ dataEnd[2] + "/" + (parseInt(dataEnd[1])) + "/" + dataEnd[0] + fileExtension);
		} else {
			let date = new Date()
			saveAs(blob, 'Base_de_dados_mes_'+ (date.getMonth()+1) + fileExtension);
		}

}

const Solutions = ( isLoading, allocablesData ) => {
	const data = allocablesData ? allocablesData.filter( ({ promiseTime, type, status }) => {

		/**
		 * BODY = NEW | STARTED
		 * FUNERAL = NEW | STARTED
		 * BURIAL = NEW | STARTED | PICKED_UP_BODY_FUNERAL
		 */

		switch (String(type).toLowerCase()) {
			case 'body':
				return ['new', 'started'].includes(String(status).toLowerCase()) && promiseTime < dateSetHours(-BODY_HOURS_DELAY)
			case 'funeral':
				return ['new', 'started'].includes(String(status).toLowerCase()) && promiseTime < dateSetHours(-ALLOCABLES_DELAY)
			case 'burial':
				return ['new', 'started', 'picked_up_body_funeral'].includes(String(status).toLowerCase()) && promiseTime < dateSetHours(-ALLOCABLES_DELAY)
			default:
				return false
		}

	}) : []

	let content =  (
		<Table className={'container-solutions'}>
			<thead className={'hidden-xs hidden-sm row-header'}>
				<tr>
					<th md={1}>
						{text('STATUS')}
					</th>
					<th md={1}>
						{text('TYPE')}
					</th>
					<th md={2}>
						{text('NAME')}
					</th>
					<th md={3}>
						{text('SERVICE ORDER')}
					</th>
					<th md={3}>
						{text('CREATED AT')}
					</th>
					<th md={3}>
						{text('PROMISE TIME')}
					</th>
				</tr>
			</thead>
			<tbody className={'container-allocables'}>
				{
					(!isLoading && data) ?
						data.map( ({ name, createdAt, serviceOrder, promiseTime, status, type }, key) => {
						return (
							<Allocable
								serviceOrder={serviceOrder}
								createdAt={formatDate(createdAt)}
								promiseTime={formatDate(promiseTime)}
								name={name}
								status={status}
								type={type}
								key={key}
							/>
						)
					}) : <tr><td><Loading/></td></tr>
				}
			</tbody>
		</Table>
	)
	return content
}

const Allocable = props => {
	const { serviceOrder, createdAt, promiseTime, name, status, type } = props

	const badgeStyle = {
		padding: 10, fontWeight: 600, fontSize: 14, boxShadow: ' 2px 2px rgba(0, 0, 0, 0.1)'
	}

	let content = (
		<tr className={'row-allocables'}>
			<td className={'column-allocables'} style={{textAlign: 'center'}} md={1}>
				<Badge style={{...statusColor(status), ...badgeStyle}}>{String(text(status)).toUpperCase()}</Badge>
			</td>
			<td className={'column-allocables'} md={2}>
				{text(type)}
			</td>
			<td className={'column-allocables'} md={2}>
				{name}
			</td>
			<td className={'column-allocables'} md={3}>
				{serviceOrder}
			</td>
			<td className={'column-allocables'} md={3}>
				{createdAt}
			</td>
			<td className={'column-allocables'} md={3}>
				{promiseTime}
			</td>
		</tr>
	)
	return content
}

const StatusCardFactory = (allocables: Array = []) => {

	const rankByStatusAndType = ( accumulator, allocable ) => {
		let { status, type } = allocable
		
		if (!accumulator[type]) accumulator[type] = {}
		if (!accumulator[type][status]) accumulator[type][status] = 0

		++accumulator[type][status]
		return accumulator
	}

	let report = allocables.reduce(rankByStatusAndType, makeNewReport())

	return Object.keys(report).map( (type, key) => {
		
		let allocable = ALLOCABLE_TYPES.find( ({ name }) => String(name).toLowerCase() === String(type).toLowerCase())

		return allocable ? <StatusCard key={key} name={allocable.name} statuses={report[type]} img={allocable.img}/> : null

	})
}

const makeNewReport = () => {
	return ALLOCABLE_TYPES.reduce( (accumulator, { name, status }) => {

		accumulator[name] = {}
		
		if (Array.isArray(status)) {
			status.forEach( statusName => {
				accumulator[name][statusName] = 0
			})
		}

		return accumulator
	}, {})
}

const formatDate = (date: Date = new Date()) => {

	const zeroFix = (number) => number < 10 ? `0${number}` : number

	const day = date.getDate()
	const month = zeroFix(date.getMonth() + 1)
	const year = date.getFullYear()
	const hours = zeroFix(date.getHours())
	const minutes = zeroFix(date.getMinutes())
	const seconds = zeroFix(date.getSeconds())

	return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}

const Loading = (props) => {
	
	const loadingGif = require('../../resources/img/loading-spin.svg')
	return (
		<div className={'loading-container'}>
			<img className={'loading-gif'} src={loadingGif} alt={'loading...'}/>
		</div>
	)
}

const Filter = () => {
	
	return (
		<Grid>
			<Row style={{ width: '100%', height: '100%'}}>
			<Col   md={12}  >
			<div className="panel panel-default" style={{margin: 15}}>
				<div className="panel-heading">{text('FILTER')}</div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}} className="panel-body">	
					<div style={{width: '15%'}} className={'css-2b097c-container'}>
						<label style={{marginLeft: 30}}>{'A partir'}</label>
						<input
							className={'css-yk16xz-control'}
							style={{marginLeft: 20, marginRight: 10, padding: 10, height: 38, width: '100%'}}
							type="Date"
							onChange={onInputChangeDateInit}
							placeholder={text('Date Create...')}
						/>
					</div>
					<div style={{width: '15%'}} className={'css-2b097c-container'}>
						<label style={{marginLeft: 40}}>{'Até'}</label>
						<input
							className={'css-yk16xz-control'}
							style={{marginLeft: 30, marginRight: 10, padding: 10, height: 38, width: '100%'}}
							type="Date"
							onChange={onInputChangeDateEnd}
							placeholder={text('Date End...')}
						/>
					</div>     
					<div style={{marginLeft: 'auto'}}>
							<Button style={{marginRight: 10}} onClick={download}>
								Exportar Base de Dados {" "}
								<Glyphicon glyph={'glyphicon glyphicon-download-alt'}/>
                    		</Button>
					</div>  
					<div style={{marginLeft: 'auto'}}>
							<Button style={{marginRight: 10}} onClick={downloadHistory}>
								Exportar Histórico {" "}
								<Glyphicon glyph={'glyphicon glyphicon-download-alt'}/>
                    		</Button>
					</div>  

					<div style={{marginLeft: 'auto'}}>
							<Button style={{marginRight: 10}} onClick={searchResource}>
								<Glyphicon glyph={'glyphicon glyphicon-search'}/>
							</Button>
							<Button onClick={clearSearch}>
								<Glyphicon glyph={'glyphicon glyphicon-erase'}/>
							</Button>
					</div>
	
				</div>
			</div>
			</Col>
			</Row>
		</Grid>
	)
}

const onInputChangeDateInit = ({target: {value}}) => {  
	state.dataInit = value
	return state.dataInit
}

const onInputChangeDateEnd = ({target: {value}}) => {
	state.dataEnd = value
	return state.dataEnd
}

const clearSearch= async () => {
	var arrayOfStrings = String(window.location).split('/')
	window.location.replace(arrayOfStrings[0]+"/"+arrayOfStrings[1]+"/"+arrayOfStrings[2]+"/"+arrayOfStrings[3])
}

async function query () {
	let value
	await firebase.firestore().collection('allocables').where('origin', '==', 'API').orderBy('createdAt', 'desc').limit(1).get()
	.then( snapshot => {
		snapshot.forEach(doc => {
				value = doc.data().createdAt.seconds
		});
	});
	state.ref = value
	return await value
}

const lastCommunication = (isLoading, dataFetched: Array) => {
	if (!isLoading || state.ref == '') {
		
		query()
		let createdAt = new Date(state.ref*1000)
		let status = 'GREEN'	
		if (createdAt < new Date((new Date()).setHours((new Date()).getHours() + (-6)))) {
			status = 'RED'
		} else if (createdAt < new Date((new Date()).setHours((new Date()).getHours() + (-2)))) {
			status = 'YELLOW'
		}

		let date = formatDate(createdAt).split(' ')

		return (
			<Grid className={'last-communication-container'}>
				<Row className={'last-communication-row'}>
					<Col className={'last-communication-header'} md={5} sm={5} xs={5}>
						{text('LAST COMMUNICATION')}
					</Col>
					{
						isLoading ? <Col md={12}><Loading/></Col> :	
						<>
							<Col className={'last-communication-text'} md={5} sm={5} xs={5}>
								<div>
									<p>{date[0]} {date[1]}</p>
								</div>
							</Col>
							<Col className={'last-communication-status'} md={2} sm={2} xs={2}>
								<div className={'status-ball'} style={{backgroundColor: status}}></div>
							</Col>
						</>
					}
				</Row>				
			</Grid>
		)
	}

	return <Loading/>
}

const searchResource = async () => {
	if(state.dataInit != '' && state.dataEnd != ''){
		var arrayOfStrings = String(window.location).split('/')
		window.location.replace(arrayOfStrings[0]+"/"+arrayOfStrings[1]+"/"+arrayOfStrings[2]+"/"+arrayOfStrings[3]+"/"+state.dataInit+"$"+state.dataEnd)
	} else window.location.replace(window.location)
}			

const Suggestions = ({ title, dataInit }) => {

	let content = <div>SUGGESTIONS</div>
	 content = ( 
				<Table className={'status-table'}>
					<thead>
						<tr>
							<th>
								{title}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<SuggestionsChart />
							</td>
						</tr>
					</tbody>
				</Table>
	)

	return content
}

const Resources = ({ title }) => {

	let content = <div>RESOURCES</div>

	content = (
		<Table className={'status-table'}>
			<thead>
				<tr>
					<th>
						{title}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<ResourcesChart />
					</td>
				</tr>
			</tbody>
		</Table>
	)

	return content
}

const OperationTypes = ({title}) => {
	let content = <div>OperationTypes</div>

	content = (
		<Table className={'status-table'}>
			<thead>
				<tr>
					<th>
						{title}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<OperationTypesChart />
					</td>
				</tr>
			</tbody>
		</Table>
	)

	return content
}

const Deaths = ({title}) => {
	let content = <div>Deaths</div>

	content = (
		<Table className={'status-table'}>
			<thead>
				<tr>
					<th>
						{title}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<DeathsChart />
					</td>
				</tr>
			</tbody>
		</Table>
	)

	return content
}