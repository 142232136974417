import React, {Component} from 'react';
import {
    Button,
    ControlLabel,
    FormControl,
    FormGroup,
    Panel,
    Well,
    InputGroup,
    Glyphicon,
    Popover,
    OverlayTrigger,
    Form,
} from "react-bootstrap";
import DateTime from "react-datetime";
import MaskedFormControl from 'react-bootstrap-maskedinput'

const TITLE = 'Tarefa Genérica'

const DESCRIPTION = 'Descrição'
const DESCRIPTION_ID = 'generalDescription'
const DESCRIPTION_LABEL = 'Descrição'

const CONTACTS = 'Telefones'
const CONTACTS_ID = 'bodyContact'
const CONTACTS_LABEL = 'Telefones para contato'

const PROMISETIME = 'Data e Hora de Chegada'
const PROMISETIME_ID = 'generalPromiseTime'
const PROMISETIME_LABEL = 'Hora de Chegada'

const GENERALADDRESS = 'Endereço'
const GENERALADDRESS_ID = 'generalAddress'
const GENERALADDRESS_LABEL = 'Endereço'

const GENERALSUGGESTIONS_ID = 'generalSuggestions'

class GeneralForm extends Component {
    state = {
        phone: ''
    };

    handleClickAdd = () => {
        if (!this.props.generalContact.includes(this.state.phone))
            this.props.generalContact.push(this.state.phone);

        this.setState({phone: ''});
    };

    handleClickRemove = () => {
        this.props.generalContact.splice(this.props.generalContact.indexOf(this.state.phone), 1);
        this.setState({phone: ''});
    };

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value})

    };

    render() {
        const popoverPlus = (
            <Popover id="popover-trigger-click" title="Adicionar telefone">
                <FormGroup>
                    <InputGroup>
                        <MaskedFormControl className="text" placeholder="Telefone"
                                           id="phone" onChange={this.handleChange}
                                           value={this.state.phone}
                                           mask='(11) 1 1111-1111'/>
                        <InputGroup.Button>
                            <Button id={'add'} bsStyle={'primary'} onClick={this.handleClickAdd}>
                                <Glyphicon glyph="ok"/>
                            </Button>
                        </InputGroup.Button>
                    </InputGroup>
                </FormGroup>
            </Popover>
        );

        const popoverMinus = (
            <Popover id="popover-trigger-click" title="Remover telefone">
                <FormGroup>
                    <InputGroup>
                        <MaskedFormControl className="text" placeholder="Telefone"
                                           id="phone" onChange={this.handleChange}
                                           value={this.state.phone}
                                           mask='(11) 1 1111-1111'/>
                        <InputGroup.Button>
                            <Button id={'remove'} bsStyle={'primary'} onClick={this.handleClickRemove}>
                                <Glyphicon glyph="remove"/>
                            </Button>
                        </InputGroup.Button>
                    </InputGroup>
                </FormGroup>
            </Popover>
        );

        let {
            generalDescription: description,
            generalContact: contact,
            generalPromiseTime: promiseTime,
            handleDateChangeGeneral: handleDateChange,
            generalAddress,
            generalSuggestions,
            generalCoordinates,
            getValidationStateCoordinates,
            suggestionClick,
            handleChangeAddress,
            getValidationState,
            getValidationDate
        } = this.props
        

        return (
            <Panel defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>{TITLE}</Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <Form>
                            <FormGroup>
                                <ControlLabel>{DESCRIPTION_LABEL}</ControlLabel>
                                <FormControl componentClass="textarea" placeholder={DESCRIPTION}
                                             id={DESCRIPTION_ID} onChange={this.props.handleChange}
                                             value={description}/>
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>{CONTACTS_LABEL}</ControlLabel>
                                <InputGroup>
                                    <InputGroup.Button>
                                        <OverlayTrigger trigger="click" placement="right" overlay={popoverPlus}
                                                        rootClose>
                                            <Button>
                                                <Glyphicon glyph="plus"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Button>
                                    <FormControl className="text" placeholder={CONTACTS}
                                                 id={CONTACTS_ID}
                                                 disabled={true}
                                                 value={contact}/>
                                    <InputGroup.Button>
                                        <OverlayTrigger trigger="click" placement="left" overlay={popoverMinus}
                                                        rootClose>
                                            <Button>
                                                <Glyphicon glyph="minus"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Button>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup validationState={getValidationDate(promiseTime)}>
                                <ControlLabel>{PROMISETIME_LABEL}</ControlLabel>
                                <DateTime inputProps={{placeholder: PROMISETIME}} locale={'pt-br'}
                                          id={PROMISETIME_ID}
                                          onChange={handleDateChange}
                                          value={promiseTime}
                                          disabled={true}/>
                            </FormGroup>


                            {/* ----------------- GENERAL ADDRESS ----------------- */}
                            <FormGroup validationState={getValidationState(generalAddress)}>
                                <ControlLabel>{GENERALADDRESS_LABEL}</ControlLabel>
                                <FormControl type="text" placeholder={GENERALADDRESS}
                                             id={GENERALADDRESS_ID}
                                             onChange={handleChangeAddress}
                                             value={generalAddress}
                                />
                            </FormGroup>

                            {generalSuggestions && generalSuggestions.map(suggestion => {
                                return (
                                    <Well key={GENERALSUGGESTIONS_ID + ',' + generalSuggestions.indexOf(suggestion)}
                                          id={GENERALSUGGESTIONS_ID + ',' + generalSuggestions.indexOf(suggestion)}
                                          onClick={suggestionClick}>{suggestion.label}</Well>
                                )
                            })}

                            <FormGroup
                                validationState={getValidationStateCoordinates(generalCoordinates.lat)}>
                                <ControlLabel>Coordenadas</ControlLabel>
                                <FormControl type="text" placeholder="Lat"
                                             id="lat" value={generalCoordinates.lat}
                                             disabled={true}/>

                            </FormGroup>
                            <FormGroup
                                validationState={getValidationStateCoordinates(generalCoordinates.lng)}>
                                <FormControl type="text" placeholder="Lon"
                                             id="lon" value={generalCoordinates.lng}
                                             disabled={true}/>
                            </FormGroup>
                            
                        </Form>
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }
}

export default GeneralForm;

