import React, { Component } from 'react'

import '../../../node_modules/react-vis/dist/style.css'
import {
	FlexibleWidthXYPlot,
	VerticalGridLines,
	HorizontalGridLines,
	XAxis,
	YAxis,
	VerticalBarSeries,
	// Crosshair,
	LabelSeries
} from 'react-vis'

const ORANGE = '#8d6e63'

export default class Chart extends Component {
	state = {
		crosshairValues: []
	}

	componentDidMount = async () => {}

	onMouseLeave = () => {
		this.setState({ crosshairValues: [] })
	}

	onNearestX = value => {
		this.setState({
			crosshairValues: [value]
		})
	}

	render() {
		// const { crosshairValues } = this.state
		const { data } = this.props
		if (data) {
			return (
				<>
					<FlexibleWidthXYPlot
						height={300 + 52}
						xType={'ordinal'}
						onMouseLeave={this.onMouseLeave}
						margin={{ bottom: 70 }}
					>
						<VerticalGridLines />
						<HorizontalGridLines />
						<XAxis
							tickLabelAngle={-30}
							style={{
								text: {
									stroke: 'none',
									fill: '#000',
									fontWeight: 600,
									maxWidth: 2
								}
							}}
							tickFormat={tickFormater}
						/>
						<YAxis tickTotal={10} />
						<VerticalBarSeries
							data={data}
							onNearestX={this.onNearestX}
							color={ORANGE}
							barWidth={0.8}
						/>
						<LabelSeries
							data={data.map(obj => ({
								...obj,
								label: `${obj.y}`
							}))}
							labelAnchorX={'start'}
							// labelAnchorY={'text-after-edge'}
						/>
						{/* <Crosshair values={this.state.crosshairValues}>
              <div style={{backgroundColor: 'black', padding: 10}}>
                <span>{`${crosshairValues[0] ? crosshairValues[0].y : ''}`}</span>
              </div>
            </Crosshair> */}
					</FlexibleWidthXYPlot>
				</>
			)
		} else {
			return null
		}
	}
}

const tickFormater = (t) => {
	const sizeOfLabel = t.length
	if (sizeOfLabel > 10) {
		let text1 = t.substr(0, 9)
		let text2 = t.substr(9, sizeOfLabel-1)
		return (
			<tspan>
				<tspan x="0" dy="1em">{text1}</tspan>
				<tspan x="0" dy="1em">{text2}</tspan>
			</tspan>
		)

	} else {
		return (
			<tspan x="0" dy="1em">
				{t}
			</tspan>
		)
	}
}
