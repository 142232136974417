import React from 'react'

export const dateToString = (date: Date = new Date()) => {
    let year = date.getFullYear().toString()
    let month = date.getMonth()+1
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    return `${day}/${month}/${year}`
}

export const dateToFullString = (date: Date = new Date()) => {
    let year = date.getFullYear().toString();
    let month = date.getMonth()+1;
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    let seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export const firestoreTimestampToDate = (timestamp: Object = {}) => {
    return  isFirestoreTimestamp(timestamp) ? new Date(timestamp.seconds * 1000) : false;
}

export const isFirestoreTimestamp = ( param ) => typeof param === 'object' && 'seconds' in param;

export const tickFormatter = (t) => {
    const sizeOfLabel = t.length
    if (sizeOfLabel > 15) {
        let text1 = t.substr(0, 15)
        let text2 = t.substr(15, sizeOfLabel - 1)
        return (
            <tspan>
                <tspan x="0" dy="1em">{text1}</tspan>
                <tspan x="0" dy="1em">{text2}</tspan>
            </tspan>
        )

    } else {
        return (
            <tspan x="0" dy="1em">
                {t}
            </tspan>
        )
    }
}

export const dateSetHours = (hours: Number = 0) => new Date((new Date()).setHours( (new Date()).getHours() + hours ));

export const dateSetDays = (days: Number = 0) => new Date((new Date()).setDate( (new Date()).getDate() + days ));

export const statusColor = (status: String = '') => {
    if (status in statusColors)
        return statusColors[status]
    return {backgroundColor: 'white', color: 'black'}
}

const statusColors = {
    new: { backgroundColor: '#ffdabb', color: 'black' },
    started: { backgroundColor: '#f3fab1', color: 'black' },
    arrived_body: { backgroundColor: '#b1fab3', color: 'black' },
    arrived_funeral: { backgroundColor: '#b1fab3', color: 'black' },
    at_lab: { backgroundColor: '#bbcaff', color: 'black' },
    finished: { backgroundColor: '#777', color: 'white',},
    picked_up_body_funeral: { backgroundColor: '#fffac7', color: 'black' }
}


/**
 * TABLE FIELDS:
 *  - type
 *  - source
 *  - previousData
 *  - newData
 *  - user
 *  - optimizer
 * 
 * When optimizer is TRUE, previousData will receive optimizer->original
 * and newData will be filled with optimizer->definedResource
 * 
 */

export const historyFlattener = ( history: Object ) => {

    // This is what's going to be returned
    // Basically, the object will produce
    // a flat array of each event that
    // can be found within each
    // history record
    let recordsData;


    // LET used based on possible future reassignments
    // and for destructuring historyRecords so the
    // readability won't be compromised
    let {
        previous: previousData,
        changes: newData,
        optimizer,
        source,
        timestamp,
        type
    } = history;

    const dataTemplate = ( () => {

        let origin, originResource, date, operation;

        if (source) {
            origin = source['type'];
            originResource = source['resource'];
        }

        operation = type;

        date = isFirestoreTimestamp(timestamp) ? dateToFullString(firestoreTimestampToDate(timestamp)) : null;

        return ({
            origin,
            originResource,
            operation,
            date,
            timestamp: isFirestoreTimestamp(timestamp) ? timestamp.seconds : null
        })
    })();


    // Using this trick to prevent any error from
    // possible future database changes
    if (!newData) newData = {};
    if (!previousData) previousData = {};

    // Since optimizer can assume "false" as a value
    // we need to check for some specific cases
    // #note that may look the same, but
    // it is important for possible
    // future code maintenance
    if (optimizer === undefined || optimizer === null) optimizer = false;

    const keys = new Set([...Object.keys(newData), ...Object.keys(previousData)]);

    recordsData = [...keys].map(key => ({
        ...dataTemplate,
        attribute: key,
        previous: isFirestoreTimestamp(previousData[key]) ? timestamp.seconds : previousData[key],
        new: isFirestoreTimestamp(newData[key]) ? timestamp.seconds : newData[key],
    }));

    if (optimizer) {
        recordsData.push({
            ...dataTemplate,
            attribute: 'optimizer',
            previous: optimizer['original'],
            new: optimizer['definedResource']
        })
    }
    
    return recordsData;

}
    