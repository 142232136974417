import React, { Component } from 'react'
import {
  Modal,
  Jumbotron
} from 'react-bootstrap'
import { text } from '../../config/translate'

export default class Timeline extends Component {

  render() {

    let { show, onHide, data, serviceOrder } = this.props

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <h3>{text('SERVICE ORDER').toUpperCase()}:  {(serviceOrder && typeof serviceOrder === 'string') ? serviceOrder.toUpperCase() : text('UNDEFINED').toUpperCase()}</h3>
        </Modal.Header>
        <Modal.Body>
          {
            data().map(({createdAt, serviceOrder, serviceOrderType, user, changes, type, data}, key) => {
              if (type === 'OS CREATION') {
                return (
                  <Jumbotron key={key} style={{padding: 15}}>
                    <h4>{text(type)}</h4>
                    <h5>{text(createdAt)}</h5>
                    <p style={{fontSize: 14, fontWeight: 500}}>
                      {text('type')}: {text(serviceOrderType).toUpperCase()}
                    </p>
                    <p>
                      {text('USERNAME')}: {text(user).toUpperCase()}
                    </p>
                    <hr/>
                    <React.Fragment key={key}>
                      <table className={'table table-stripped'} key={key}>
                        <thead>
                          <tr>
                            <th>{text('FIELD')}</th>
                            <th>{text('VALUE')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          Object.keys(data).map( (key, index) => {
                            return (
                              <tr key={key}>
                                <td style={{width: '30%'}}>{text(key).toUpperCase()}</td>
                                <td style={{width: '70%'}}>{text(data[key]).toUpperCase()}</td>
                              </tr>
                            )
                          })
                        }
                        </tbody>
                      </table>
                    </React.Fragment>
                    
                  </Jumbotron>
                )
              } else {
                return (
                  <Jumbotron key={key} style={{padding: 15}}>
                    <h5>{createdAt}</h5>
                    <p style={{fontSize: 14, fontWeight: 500}}>
                      {text('type')}: {text(serviceOrderType).toUpperCase()}
                    </p>
                    <p>
                      {text('USERNAME')}: {text(user).toUpperCase()}
                    </p>
                    <hr/>
                    {
                      changes.map(({property, newValue, oldValue}, key) => {
                        return (
                          <React.Fragment key={key}>
                            <p>{text(property)}</p>
                            <table className={'table table-stripped'} key={key}>
                              <thead>
                                <tr>
                                  <th>{text('OLDVALUE')}</th>
                                  <th>{text('NEWVALUE')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{width: '30%'}}>{text(oldValue).toUpperCase()}</td>
                                  <td style={{width: '70%'}}>{text(newValue).toUpperCase()}</td>
                                </tr>
                              </tbody>
                            </table>
                          </React.Fragment>
                        )
                      })
                    }
                  </Jumbotron>
                )
              }
            })
          }
        </Modal.Body>
        {/* <Modal.Footer style={{'display': 'flex', 'justifyContent': 'space-around'}}>
          <h5>FOOTER</h5>
        </Modal.Footer> */}
      </Modal>
    )
  }
}
