import React, {Component} from 'react';
import {connect} from 'react-redux';
import {signIn} from '../../store/actions/authActions';
import {FormGroup, ControlLabel, FormControl, Button, Row, Col, Grid, Panel, Alert} from 'react-bootstrap';

class SignIn extends Component {
    state = {
        email: '',
        password: ''
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state);
    };

    render() {
        const {authError, auth} = this.props;

        if (auth.uid) return null

        return (
            <Grid>
                <Row className="show-grid">
                    <Col xs={12} md={12}>
                        <Panel>
                            <Panel.Heading>Login</Panel.Heading>
                            <Panel.Body>
                                <form onSubmit={this.handleSubmit}>

                                    <FormGroup>
                                        <ControlLabel>Email</ControlLabel>
                                        <FormControl type="email" placeholder="Email"
                                                     id="email" onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Password</ControlLabel>
                                        <FormControl type="password" placeholder="Password"
                                                     id="password" onChange={this.handleChange}/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Button type="submit">Login</Button>
                                    </FormGroup>

                                    {authError ?  <Alert bsStyle="danger"> <strong>{authError}</strong> </Alert> : null}
                                </form>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
