import * as firebase from "firebase/app"
import "firebase/firestore";


const COLLECTION = 'historico'
const DATA_FIELDS = [
    'name', 'type', 'description', 'destinationAddress', 'originAddress', 'promiseTime', 'createdAt'
]

const timestampToDate = timestamp => {
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    let date = new Date (timestamp*1000)
    let year = date.getFullYear().toString().substr(2)
    let month = months[date.getMonth()]
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    let hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    let seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}

class History {

    constructor({changes, previous, timestamp, optimizer, user, type, data, source, id, serviceOrder}) {
        this.user = user
        this.changes = changes
        this.previous = previous
        this.timestamp = timestamp
        this.optimizer = optimizer
        this.type = type
        this.data = data
        this.source = source
        this.id = id
        this.serviceOrder = serviceOrder
    }

    format = () => {

        const isTimestamp = value => value && typeof value === 'object' && 'seconds' in value ? timestampToDate(value.seconds) : value

        const filterData = data => {
            let obj = {}
            DATA_FIELDS.forEach(field => {
                if (data[field])
                    obj[field] = isTimestamp(data[field])
            })
            return obj
        }

        let { firstName, lastName, email} = this.user || {}
        const user = firstName && lastName ? `${firstName} ${lastName}` : typeof this.user === 'string' ? this.user : typeof email === 'string' ? email : ''
        let { serviceOrder, type: serviceOrderType  } = this.data || {}
        let timestamp = this.timestamp
        let changes = Object.keys(this.changes).map(column => {
            let newValue = this.changes ? isTimestamp(this.changes[column]) : ''
            let oldValue = this.previous ? isTimestamp(this.previous[column]) : ''
            return (
                {
                    property: column,
                    newValue: newValue,
                    oldValue: oldValue
                }
            )
        })
        let type = this.type || ''
        let data = this.data ? filterData(this.data) : false

        let thisObj = {
            user: user,
            serviceOrder: serviceOrder ? serviceOrder : '',
            serviceOrderType: serviceOrderType ? serviceOrderType :  '',
            createdAt: timestamp ? timestampToDate(timestamp.seconds) : null,
            createdAtTimestamp: this.timestamp || 0,
            changes: changes,
            type: type,
            data: data,
            optimizer: this.optimizer
        }
        // console.log('thisObj', thisObj)
        return thisObj
    }

    static get ref() {
        return firebase.firestore().collection(COLLECTION)
    }

    static set ref(ref) {
        this._ref = ref
    }

    static reset() {
        this._ref = firebase.firestore().collection(COLLECTION)
    }

    static query = async(resource, dateEndValue, dateInitValue , os) => {

        let snapshot = Object.create(null);
        let result 

        if(resource && dateInitValue && dateEndValue && os){
            let data = new Date(dateInitValue);
            let dataEnd = new Date(dateEndValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('source.resource', '==', resource)
                                                                        .where('data.serviceOrder', '==', os)
                                                                        .where('data.createdAt', '<=', dataEnd)
                                                                        .where('data.createdAt', '>', data).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })  
        } 
        else if(resource && dateInitValue && dateEndValue){
            let data = new Date(dateInitValue);
            let dataEnd = new Date(dateEndValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('source.resource', '==', resource)
                                                                        .where('data.createdAt', '<=', dataEnd)
                                                                        .where('data.createdAt', '>=', data).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })  
        }
        else if(resource && dateInitValue && os){
            let data = new Date(dateInitValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('source.resource', '==', resource)
                                                                        .where('data.serviceOrder', '==', os)
                                                                        .where('data.createdAt', '>=', data).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })             
        }
        else if(dateInitValue && dateEndValue && os){
            let data = new Date(dateInitValue);
            let dataEnd = new Date(dateEndValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('data.serviceOrder', '==', os)
                                                                        .where('data.createdAt', '<=', dataEnd)
                                                                        .where('data.createdAt', '>=', data).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })             
        }
        else if(resource && dateEndValue && os){
            let dataEnd = new Date(dateEndValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('source.resource', '==', resource)
                                                                        .where('data.serviceOrder', '==', os)
                                                                        .where('data.createdAt', '<=', dataEnd).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })             
        }
        else if(resource && dateEndValue){
            let dataEnd = new Date(dateEndValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('source.resource', '==', resource)
                                                                        .where('data.createdAt', '<=', dataEnd).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })            
        }
        else if(resource && dateInitValue){
            let data = new Date(dateInitValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('source.resource', '==', resource)
                                                                        .where('data.createdAt', '>=', data).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })            
        }
        else if(resource && os){
            snapshot = await firebase.firestore().collection(COLLECTION).where('source.resource', '==', resource)
                                                                        .where('data.serviceOrder', '==', os).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })            
        }
        else if(dateInitValue && dateEndValue){
            let data = new Date(dateInitValue);
            let dataEnd = new Date(dateEndValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('data.createdAt', '<=', dataEnd)
                                                                        .where('data.createdAt', '>=', data).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })            
        }
        else if(dateEndValue && os){
            let dataEnd = new Date(dateEndValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('data.serviceOrder', '==', os)
                                                                        .where('data.createdAt', '<=', dataEnd).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })            
        }
        else if(dateInitValue && os){
            let data = new Date(dateInitValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('data.serviceOrder', '==', os)
                                                                        .where('data.createdAt', '>=', data).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })            
        }
        else if(resource){
            snapshot = await firebase.firestore().collection(COLLECTION).where('source.resource', '==', resource).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })            
        }
        else if(dateEndValue){
            let dataEnd = new Date(dateEndValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('data.createdAt', '<=', dataEnd).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })            
        }
        else if(dateInitValue){
            let data = new Date(dateInitValue);
            snapshot = await firebase.firestore().collection(COLLECTION).where('data.createdAt', '>=', data).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })            
        }
        else if(os){
            snapshot = await firebase.firestore().collection(COLLECTION).where('data.serviceOrder', '==', os).get();
            result = snapshot.docs.map(doc => {
                let history = new History(doc.data())
                return history.format() 
            })            
        }

        return await result;
    }

    static get = async(ref, format = true) => {
        let snapshot = Object.create(null)
        if (ref) {
            snapshot = await ref.get()
        } else if (!this._ref) {
            snapshot = await this.ref.get()
        } else {
            snapshot = await this._ref.get()
        }
        return snapshot.docs.map(doc => {
            let history = new History(doc.data())
            return format ? history.format() : history
        })
    }

    static where = (a, s, b) => {
        this.ref = this.ref.where(a,s, b)
        return this
    }

    static orderBy = (by, order) => {
        if (order)
            this.ref = this.ref.orderBy(by, order)
        else
            this.ref = this.ref.orderBy(by)
        return this
    }

    static addIndex = async(index: string) => {
        const ref = firebase.firestore().collection('warehouse').doc('index')
        let increment = firebase.firestore.FieldValue.increment(1)
        await ref.update({[index]: increment})
    }

    static save = async(history) => {
        
        const timestamp = firebase.firestore.Timestamp.fromDate(new Date())
        const data = Object.assign({}, history, {timestamp: timestamp})
        await this.ref.add(data)

    }
}

export default History