import React, { Component } from "react";
import { text } from '../../config/translate';
import {Button, Col, Grid, Panel, Row,Glyphicon} from "react-bootstrap";
import * as firebase from "firebase/app"
import "firebase/firestore";
import {getFirebase} from 'react-redux-firebase';
const COLUMNS = ["plate", "location"];

export default class VehiclePosition extends Component{
    state ={
        data:[]
    }

    update = async(e) =>{
        await firebase.firestore().collection("position").doc('new').update({
          update: "true"
        });

    }

    search = async(e) => {
        let data = []
          await firebase.firestore().collection("position").doc('new').get()
            .then( snapshot => {
                  Object.keys(snapshot.data().position).forEach(function(item) {
                  data.push({
                   plate: snapshot.data().position[item].plate,
                   location: snapshot.data().position[item].location,
                   map: 'https://www.google.com/maps/search/?api=1&query=' + snapshot.data().position[item].latitude + ',' + snapshot.data().position[item].longitude,
                  }) 
                })
                  })
        this.setState(state => ({
                data: data
        }))
    }

    render(){

        this.search()
        const {data} = this.state;
        return (
            <Grid>
                <Row className="show-grid">
                    <Col xs={12} md={12}>
                        <Panel ref={this.panel}>
                            <Panel.Body>
                                <ListTable data={data}/>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: 'red',
                                    }}
                                >          
                                    <Button 
                                        onClick={this.update}
                                    > 
                                    <Glyphicon glyph={'glyphicon glyphicon-refresh'}/>
                                    {" "} Atualizar
                                    </Button>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
}


const ListTable = ({data}) => {

	return (
		
	  <div className="panel panel-default" style={{ margin: 15 }}>
		<div className="panel-heading">Posição das Placas Habilitadas</div>
		<div className="panel-body">
		  <div>
			<table className={"table table-striped"}>
			  <thead>
				<tr>
				  {COLUMNS.map((column, key) => {
					return (
					  <th key={key}>
						<span className={'lead'}>{text(column.toUpperCase())}</span>
					  </th>
					);
				  })}
				</tr>
			  </thead>
			  <tbody>
				{data.map((data, key) => {
				  return (
            
					<tr
					  key={key}
					>
					  {COLUMNS.map((column, key) => {
              if(column === "location"){
                return (
                  <td key={key}>
                    <a href={data['map'] }>{data[column]}</a>
                  </td>  
                )
              }
              else{
                return (<td key={key}>{data[column]}</td>)
              }
						
					  })}
					</tr>
				  );
				})}
			  </tbody>
			</table>
		  </div>
		</div>
    </div>
	);
};

