const initState = {
    resources: [
    ]
};

const resourceReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_RESOURCE':
            return state;
        case 'CREATE_RESOURCE_ERROR':
            return state;
        default:
            return state;
    }
};

export default resourceReducer;

