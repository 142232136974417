
import React, { Component } from "react";
import { 
  Button,
  Glyphicon
} from 'react-bootstrap';
import Select from 'react-select';
import { text } from '../../config/translate';

import * as firebase from "firebase/app"
import "firebase/firestore";

//const COLUMNS = ["coordinatesPrevius", "coordinatesResource", "destinationCoordinates", "definedResource", "definedResourcePrevious", "so", "distance"];
const COLUMNS = ["so", "createdAt", "definedResourcePrevious", "distancePrevius", "dateUpdate", "definedResource", "distanceResult", "distanceDifference"];
const originalState  = {
	loading: true,
	data: [],
	dateInitValue: '',
	dateEndValue: ''
}

export default class InterventionHistory extends Component{

	state = originalState;

    onClick = async () => {
		let data = await query(this.state.dateInitValue, this.state.dateEndValue);
		console.log("state", "==>", this.state)
		//this.setState(state =>({data}))
		let resultPrevius = await this.distanceRequestPrevius(data)
		let result = await this.distanceRequest(data)
		
		// http://router.project-osrm.org/route/v1/driving/-43.9538717,-19.928675;-43.9583041,-19.9628328

		let newData = []
		for(var index = 0; index<data.length; index++){
			//let [stringPrevious, stringResource, stringDestination] = convertGeoPoint(data[index].coordinatesPrevius, data[index].coordinatesResource, data[index].destinationCoordinates)
			if(resultPrevius[index].distancePrevius.distance >= result[index].distance.distance){
				newData.push({
					// coordinatesPrevius: stringPrevious,
					// coordinatesResource: stringResource,
					// destinationCoordinates: stringDestination,
					definedResource: data[index].definedResource,
					definedResourcePrevious: data[index].definedResourcePrevious,
					so: data[index].so,
					createdAt: data[index].date,
					dateUpdate: data[index].dateUpdate,
					// resultPrevius: resultPrevius[index],
					distancePrevius: resultPrevius[index].distancePrevius.distance.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
					// result: result[index],
					distanceResult: result[index].distance.distance.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
					distanceDifference: (resultPrevius[index].distancePrevius.distance - result[index].distance.distance).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
				})
			} else if(resultPrevius[index].distancePrevius.distance < result[index].distance.distance){
				newData.push({
					// coordinatesPrevius: stringPrevious,
					// coordinatesResource: stringResource,
					// destinationCoordinates: stringDestination,
					definedResource: data[index].definedResource,
					definedResourcePrevious: data[index].definedResourcePrevious,
					so: data[index].so,
					createdAt: data[index].date,
					dateUpdate: data[index].dateUpdate,
					// resultPrevius: resultPrevius[index],
					distancePrevius: resultPrevius[index].distancePrevius.distance.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
					// result: result[index],
					distanceResult: result[index].distance.distance.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
					distanceDifference: (result[index].distance.distance - resultPrevius[index].distancePrevius.distance).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
				})
			}
		}

		this.setState(({loading: false, data: newData}))
		//console.log("STATE ", this.state)	
	}

	clearFilter = () => {
		this.setState(originalState)
	}
	
	distanceRequestPrevius = async(state) =>{
		let result = []
		for(var index = 0; index<state.length; index++){


			let url = 'https://router.project-osrm.org/route/v1/driving/'+ state[index].coordinatesPrevius.longitude+','
					+state[index].coordinatesPrevius.latitude+';'+state[index].destinationCoordinates.longitude
					+','+state[index].destinationCoordinates.latitude
			
		
			const fetch = require('node-fetch');
			await fetch(url)
				.then(response => response.json())
				.then(data => {
					Object.keys(data.routes).forEach(function(item){
						result.push({			
							distancePrevius: data.routes[item]
						})
					})
				})
				.catch(err => {
					console.log({type: 'ERROR', err});
				});
		}

		return result
	}

	distanceRequest = async(state) =>{
		let result = []
		for(var index = 0; index<state.length; index++){

			let url = 'https://router.project-osrm.org/route/v1/driving/'+state[index].coordinatesResource.longitude+','
					+state[index].coordinatesResource.latitude+';'+state[index].destinationCoordinates.longitude
					+','+state[index].destinationCoordinates.latitude

			const fetch = require('node-fetch');
			await fetch(url)
				.then(response => response.json())
				.then(data => {
					Object.keys(data.routes).forEach(function(item){
						result.push({			
							distance: data.routes[item]
						})
					})
				})
				.catch(err => {
					console.log({type: 'ERROR', err});
				});
		}

		return result
	}
	
	onInputChangeDateInit= ({target: {value}}) => {
		this.setState({dateInitValue: value})
	}
	
	onInputChangeDateEnd = ({target: {value}}) => {	
		this.setState({dateEndValue: value})
	}
	

    render() {
		
		const {data, dateInitValue, dateEndValue} = this.state;
		
			return (
				<div>
					<Filter
						onClick={this.onClick}
						onClear={this.clearFilter}
						dateInit={dateInitValue}
						dateEnd={dateEndValue} 
						onInputChangeDateEnd={this.onInputChangeDateEnd} 
						onInputChangeDateInit={this.onInputChangeDateInit}
					/>
					<ListTable
						data={data}/>
				</div>
			)

	}
	
}

const Filter = ({onClick, onClear, dateInit, dateEnd, onInputChangeDateEnd, onInputChangeDateInit}) => {
    return (
      <div className="panel panel-default" style={{margin: 15}}>
        <div className="panel-heading">{text('FILTER')}</div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}} className="panel-body">
          {/* <div style={{width: '15%'}}>
            <label style={{marginLeft: 20}}>{text('Resource')}</label>
            <Select
				placeholder={text('Select...')}
             />
          </div>
          <div style={{width: '15%'}} className={'css-2b097c-container'}>
            <label style={{marginLeft: 30}}>{text('ServiceOrder')}</label>
            <input
				style={{marginLeft: 10, marginRight: 10, padding: 10, height: 38, width: '100%'}}
				type="text"
				placeholder={text('Type...')}
            />
          </div> */}
          <div style={{width: '12%'}} className={'css-2b097c-container'}>
            <label style={{marginLeft: 30}}>{'A partir'}</label>
            <input
				style={{marginLeft: 20, marginRight: 10, padding: 10, height: 38, width: '100%'}}
				type="Date"
				onChange={onInputChangeDateInit}
				value={dateInit}
            />
          </div>
          <div style={{width: '12%'}} className={'css-2b097c-container'}>
            <label style={{marginLeft: 40}}>{'Até'}</label>
            <input
				style={{marginLeft: 30, marginRight: 10, padding: 10, height: 38, width: '100%'}}
				type="Date"
				onChange={onInputChangeDateEnd}
				value={dateEnd}
            />
          </div>        
          <div style={{marginLeft: 'auto'}}>
            <Button style={{marginRight: 10}} onClick={onClick}>
              <Glyphicon glyph={'glyphicon glyphicon-search'}/>
            </Button>
            <Button  onClick={onClear}> 
                <Glyphicon glyph={'glyphicon glyphicon-erase'}/>
            </Button>
          </div>
        </div>
        
      </div>
    )
 
}
  
const ListTable = ({data}) => {
	// console.log('DATA RETURN ', data)

	// data.map((data, key) => {
	// {COLUMNS.map((column, key) => {
	// 	console.log(data[column])
	//   })}
	// }) 
	return (
		
	  <div className="panel panel-default" style={{ margin: 15 }}>
		<div className="panel-heading">HISTÓRICO DE INTERVENÇÂO</div>
		<div className="panel-body">
		  <div>
			<table className={"table table-striped"}>
			  <thead>
				<tr>
				  {COLUMNS.map((column, key) => {
					return (
					  <th key={key}>
						<span className={'lead'}>{text(column.toUpperCase())}</span>
					  </th>
					);
				  })}
				</tr>
			  </thead>
			  <tbody>
				{data.map((data, key) => {
				  return (
					<tr
					  key={key}
					>
					  {COLUMNS.map((column, key) => {
						return <td key={key}>{data[column]}</td>;
					  })}
					</tr>
				  );
				})}
			  </tbody>
			</table>
		  </div>
		</div>
		<div className="panel-footer"></div>
	  </div>
	);
};
  
const Loading = (props) => {
	
	const loadingGif = require('../../resources/img/loading-spin.svg')
	return (
		<div className={'loading-container'}>
			<img className={'loading-gif'} src={loadingGif} alt={'loading...'}/>
		</div>
	)
}

const styles = {
	content: {
		// display: 'flex',
		alignContent: 'center',
		// justifyContent: 'center',
		backgroundColor: 'rgba(1,1,1,0.1)',
		width: '100%'
	}
}

const convertGeoPoint = (coordinatesPrevius, coordinatesResource, destinationCoordinates) =>{

	let latitude = coordinatesPrevius.latitude
	let longitude = coordinatesPrevius.longitude
	let stringPrevious = "["+latitude+"°, "+longitude+"°]"

	latitude = coordinatesResource.latitude
	longitude = coordinatesResource.longitude
	let stringResource = "["+latitude+"°, "+longitude+"°]"
	
	latitude = destinationCoordinates.latitude
	longitude = destinationCoordinates.longitude
	let stringDestination = "["+latitude+"°, "+longitude+"°]"
	
	return[stringPrevious, stringResource, stringDestination]
}

const timestampToDate = timestamp => {
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    let date = new Date (timestamp*1000)
    let year = date.getFullYear().toString().substr(2)
    let month = months[date.getMonth()]
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    let hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    let seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}

// async function query (dateInit, dateEnd) {
// 	let data = []



// 	var dateInitValue = new Date(dateInit)
// 	dateInitValue.setDate(dateInitValue.getDate() + 1)
// 	dateInitValue.setHours(0)


// 	var dateEndValue = new Date(dateEnd)
// 	dateEndValue.setDate(dateEndValue.getDate() + 1) 
// 	dateEndValue.setHours(23)
// 	dateEndValue.setMinutes(59)
// 	dateEndValue.setSeconds(59)

// 	await firebase.firestore().collection("allocables").where('definedResourcePrevious', '>', '')
// 														.get()
// 	.then( snapshot => {
// 		snapshot.forEach(doc => {   
// 				if(doc.data().coordinatesPrevius && doc.data().coordinatesResource){
// 				//	let [stringPrevious, stringResource, stringDestination] = convertGeoPoint(doc.data().coordinatesPrevius, doc.data().coordinatesResource, doc.data().destinationCoordinates)


// 					var date = timestampToDate(doc.data().createdAt.seconds)
// 					var dateFilter = new Date(date)
// 					if(dateFilter >= dateInitValue && dateFilter <= dateEndValue){
// 						if(doc.data().dateUpdate){

// 							var dateUpdate = timestampToDate(doc.data().dateUpdate.seconds)
// 							data.push({			
// 								coordinatesPrevius: doc.data().coordinatesPrevius,
// 								coordinatesResource:  doc.data().coordinatesResource,
// 								destinationCoordinates: doc.data().destinationCoordinates,
// 								definedResource: doc.data().definedResource,
// 								definedResourcePrevious: doc.data().definedResourcePrevious,
// 								so: doc.data().serviceOrder,
// 								date: date,
// 								dateUpdate: dateUpdate
// 							})
// 						} else {
// 							data.push({			
// 								coordinatesPrevius: doc.data().coordinatesPrevius,
// 								coordinatesResource:  doc.data().coordinatesResource,
// 								destinationCoordinates: doc.data().destinationCoordinates,
// 								definedResource: doc.data().definedResource,
// 								definedResourcePrevious: doc.data().definedResourcePrevious,
// 								so: doc.data().serviceOrder,
// 								date: date
// 							})
// 						}
// 					}
// 				}
// 		});
// 	});

// 	return data
	
// }

async function query (dateInit, dateEnd) {
	let data = []

	var dateInitValue = new Date(dateInit)
	dateInitValue.setDate(dateInitValue.getDate() + 1)
	dateInitValue.setHours(0)


	var dateEndValue = new Date(dateEnd)
	dateEndValue.setDate(dateEndValue.getDate() + 1) 
	dateEndValue.setHours(23)
	dateEndValue.setMinutes(59)
	dateEndValue.setSeconds(59)

	await firebase.firestore().collection("historico").where('optimizer.ignored', '==', true)
														.get()
	.then( snapshot => {
		snapshot.forEach(doc => {   
			//console.log(doc.data().coordinatesOriginal, doc.data().coordinatesDefinedResource)
				if(doc.data().coordinatesOriginal && doc.data().coordinatesDefinedResource){

					var date = timestampToDate(doc.data().data.createdAt.seconds)
					var dateFilter = new Date(doc.data().data.createdAt.seconds * 1000)

					if(dateFilter >= dateInitValue && dateFilter <= dateEndValue){			
						var dateUpdate = timestampToDate(doc.data().timestamp.seconds)

						var definedResource = doc.data().optimizer.definedResource
						var definedResourcePrevious = doc.data().optimizer.original

						
						data.push({			
							coordinatesPrevius: doc.data().coordinatesOriginal,
							coordinatesResource: doc.data().coordinatesDefinedResource,
							destinationCoordinates: doc.data().data.destinationCoordinates,
							definedResource: definedResource,
							definedResourcePrevious: definedResourcePrevious,
							so: doc.data().data.serviceOrder,
							date: date,
							dateUpdate: dateUpdate
						})
					}
				}
		});
	});
	console.log(data)
	return data
	
}

async function coordinatesPlate(plate){
	var coordinates
    await firebase.firestore().collection("resources").doc(String(plate).toUpperCase()).get()
            .then(doc => {
                if (!doc.exists) {
                console.log('No such document!');
                } else {
					console.log(doc.data().coordinates)
                	coordinates = doc.data().coordinates
                }
            })
            .catch(err => {
                console.log('Error getting document', err);
			});
			
	return await coordinates
}