import authReducer from './authReducer';
import allocableReducer from './allocableReducer';
import resourceReducer from './resourceReducer'
import {combineReducers} from 'redux';
import {firestoreReducer} from 'redux-firestore';
import {firebaseReducer} from 'react-redux-firebase';


const rootReducer = combineReducers({
    auth: authReducer,
    allocable: allocableReducer,
    resource: resourceReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
});

export default rootReducer;

