import React, {Component} from 'react';
import {Modal, Button, Glyphicon} from 'react-bootstrap';

class VehicleModal extends Component {

    render() {
        let message;
        switch (this.props.action) {
            case 3:
                message = 'Tem certeza que deseja salvar o valor de capacidade, capacidade de retorno de paramentos e carga atual para o veículo com placa: ' +
                this.props.plate + '?';
                break;
            default: break;
        }
        return (
            <Modal
                {...this.props}
                bsSize="small"
                aria-labelledby="contained-modal-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-lg">ATENÇÃO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <strong>{message}</strong>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle={'danger'} onClick={() => this.props.onHide(false)}><Glyphicon glyph={'remove'}></Glyphicon></Button>
                    <Button bsStyle={'success'} onClick={() => this.props.onHide(true)}><Glyphicon glyph={'ok'}></Glyphicon></Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default VehicleModal;
