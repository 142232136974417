import React from 'react'
import { Glyphicon } from 'react-bootstrap'

export default function Loading () {
	
    return (
        <div
            style={{
                textAlign: 'center',
                fontSize: 20
            }}
        >
            <Glyphicon glyph={'glyphicon glyphicon-refresh'}>
                LOADING{' '}
            </Glyphicon>{' '}
        </div>
    )
}
