import { useState, useEffect, useRef } from 'react'

import * as firebase from "firebase/app"
import "firebase/firestore";

export const useFirestore = () => {
    return firebase.firestore()
}

export const useFirebase = (ref: Object, documentId: String = '', dependencies: Array = [] ) => {

    const [ data, setData ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(true)

    useEffect( () => {
        let mounted = true
        if (documentId !== '' && documentId) {
            ref.doc(documentId).get()
                .then(doc => {
                    return doc.data()
                })
                .then( data => {
                    if (mounted) {
                        setData(data)
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    console.log('ERROR', error)
                    setIsLoading(false)
                    throw new Error(error.message)
                })
        } else {
            ref.get()
                .then(snapshot => {
                    return snapshot.docs.map(doc => doc.data())
                })
                .then( data => {
                    if (mounted) {
                        setData(data)
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    console.log('ERROR', error)
                    setIsLoading(false)
                    throw new Error(error.message)
                })
        }
        return () => mounted = false
    }, dependencies)

    return [isLoading, data]
}

export const useDataListener = (ref: Object, dependencies: Array = []) => {
    // console.log('USE DATA LISTENER')
    const [ data, setData ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
    
    const objectToArray = (object: Object) => {
        return Object.keys(object).map(key => {
            return {id: key, ...object[key]}
        })
    }

    const dataListener = snapshot => {
        snapshot.docChanges().forEach( change => {
            const { allocables } = change.doc.data()
            // if (Object.keys(allocables) < 1) return
            setData(objectToArray(allocables))
            setIsLoading(false)
        })
    }

    useEffect( () => {
        setIsLoading(true)
        const unsubscribe = ref.onSnapshot(dataListener)
        
        return () => {
            console.log('UNSUBSCRIBED FROM DATA_LISTENER')
            unsubscribe()
        }
    }, dependencies)
    // console.log('IS LOADING........:', isLoading)
    return [ isLoading, data ]
}

export const useAllocablesListener = (ref: Object, dependencies: Array = []) => {
    
    const [ data, setData ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)

    const dataListener = snapshot => {
        let fetchedData = snapshot.docChanges().map( ({ doc }) => ({id: doc.id, ...doc.data()}))
        setData(fetchedData)
        setIsLoading(false)
    }

    useEffect( () => {
        setIsLoading(true)
        const unsubscribe = ref.onSnapshot(dataListener)

        return () => {
            unsubscribe()
        }
    }, dependencies)

    return [isLoading, data]
}


export const useFirestoreGet = (ref: Object, dependencies: Array = []) => {
   
    const [state, setState] = useState(null)
    const [isLoading, setIsLoading] = useState(true)    

    const useAllocablesDidRun = useRef(false)

    useEffect(() => {
        let mounted = true
        
        if (!useAllocablesDidRun.current) {
            ref.get()
            .then( ( { docs } ) => docs.map( doc => doc.data()))
            .then( data => {
                if (mounted) {
                    setState(data)
                    setIsLoading(false)
                }
            })
            .then( () => {
                mounted = false
                useAllocablesDidRun.current = true
            })
            .catch( err => {
                console.log('ERROR', err)
                setIsLoading(false)
                throw new Error(err.message)
            })
        }
        return () => {
            mounted = false
        }
    }, dependencies)
    
    return [ isLoading, state ]
}
