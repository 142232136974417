import React from 'react';
import '../styles/index.css';

const Api = props => {


    return (
        <div className="card-wrapper">
            <div className="card-status-badge">
                <p>1</p>
            </div>
            <div className="card-container">
                <div className="card-title">
                    <div className="card-title-icon">
                        <img src="/img/006-api.svg" alt="API-ICON"/>
                    </div>
                    <p>API</p>
                </div>
                <div className="card-main-content">
                    <p>
                        5
                        <span>
                            /10
                        </span>
                    </p>
                    <p>
                        VALUE MEANING
                    </p>
                </div>
                <div className="container-divisor"></div>
                <div className="card-sub-content">
                    <p>
                        TEXT TO SOME LINK
                    </p>
                    <div className="link"></div>
                </div>
            </div>
        </div>
    )
}

export default Api;
