import {Component} from 'react';
import {connect} from 'react-redux';
import {signOut} from '../../store/actions/authActions';

class SignOut extends Component {
    render(){
        this.props.signOut();
        return null
    }
};

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
