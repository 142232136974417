import React, {Component} from 'react';
import 'react-table/react-table.css'
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {firestoreConnect} from "react-redux-firebase";
import {Redirect} from "react-router-dom";
import {signOut} from "../../store/actions/authActions";
import {
    Panel,
    Grid,
    Row,
    Col,
    ListGroup,
    ButtonGroup,
    Button,
    Glyphicon
} from 'react-bootstrap';
import LabModal from './LabModal';
import {SupervisorActions} from "../../store/actions/allocableActions";

var numberCheckBox = 0;
var numberSet = 1
class LabAllocables extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            modalAction: 0,
            currentId: '',
            flag: false,
            ids: ''
        }
    }

        
    onCheck = () =>{
        var label = document.getElementsByName("checkboxLab");
        if(label.checked !== true){
            label.checked=true
        } else {
            label.checked=false
        }
    
    }

    onClick = () =>{ 
        for(i=0;i<numberCheckBox;i++){
            var label = document.getElementsByName("checkboxLab");

            label.value = '';
                    
            for(var i=0;i<label.length;i++){       
                if (label[i].checked){
                    label.value += label[i].value+'/';
                }
            }
            
            this.setState({                                                           
                showModal: true,
                modalAction: 4,
                flag: true,
                ids: label.value
                
            })

            
        }
    }  

    componentDidMount() {
        this.interval = setInterval(() => this.forceUpdate(), 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        numberCheckBox = 0
        const {auth, profile, signOut, allocables} = this.props;

        if (!auth.uid) return <Redirect to={'/signin'}/>;
        else if (profile.type !== undefined && profile.type !== 2 && profile.type !== 4) signOut();

        if (allocables === undefined || allocables.length === 2) return Loading();

        let closeModal = (success) => {           
            if (success) {
                this.props.SupervisorActions(this.state, auth.uid, this.props.user);
            }

            this.setState({showModal: false});
        };

        let solutionLabs = [];

        for (let index in allocables) {
            if (allocables[index].type === 'body' && allocables[index].status === 'at_lab') {
                let allocable = allocables[index];

                solutionLabs.push({
                    serviceOrder: allocable.serviceOrder,
                    name: allocable.name,
                    estimatedDuration: allocable.estimatedDuration,
                    arrivalTime: allocable.at_labTime,
                    id: allocable.id,
                    place: allocable.place
                });
            }
        }

        let CustomListGroupItem = ({children})=> {
            return (
                <div class="card" >
                <ul class="list-group list-group-flush" >
                  <li class="list-group-item" >{children}</li>
                </ul>
              </div>
              
            )
        }

        solutionLabs.sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
        })

        return (
            <Grid>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Corpos no Laboratório</Panel.Title>
                            </Panel.Heading>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Button  bsStyle={'success'}  onClick={this.onClick} >
                                        Liberar corpos selecionados para o velório {" "}
                                    <Glyphicon glyph="check"/>
                                </Button>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: 'red'
                                    }}
                                >
                                <h4>ATENÇÃO: Para liberar vários corpos simultaneamente, os selecione primeiro.</h4>
                                </div>
                            <Panel.Body>
                                <ListGroup>
                                    {
                                        (!solutionLabs || !solutionLabs.length) && (
                                            <div>Não há nenhum corpo no laboratório.</div>
                                        )
                                    }
                                    {solutionLabs && solutionLabs.map((solutionLab, key) => {

                                        let perc = 0

                                        if(solutionLab.arrivalTime && solutionLab.estimatedDuration){
                                            
                                            perc = (((new Date()).getTime() - solutionLab.arrivalTime.toDate().getTime())
                                                / (new Date(solutionLab.arrivalTime.toDate().getTime() + solutionLab.estimatedDuration * 60000)
                                                    - solutionLab.arrivalTime.toDate().getTime())) * 100;

                                            perc = Number((perc).toFixed(0));

                                            if (perc < 0) perc = 0;
                                            else if (perc > 100) perc = 100;


                                        }
                                        numberCheckBox++
                                        return (
                                            <CustomListGroupItem key={key}>
                                                
                                                   
                                                    <div className={'monitorHeader block'}>
                                                        <div className={'monitorHeader osInfo'}>
                                                            <h4 className={'monitorHeader mHlabel'}>Nome: </h4>
                                                            <h4 className={'monitorHeader value'}>{solutionLab.name}</h4>
                                                        </div>
                                                        <div className={'monitorHeader osInfo'}>
                                                            <h4 className={'monitorHeader mHlabel'}>OS: </h4>
                                                            <h4 className={'monitorHeader value'}>{solutionLab.serviceOrder}</h4>
                                                        </div>
                                                        <div className="monitorHeader osInfo">
                                                            <h4 className="monitorHeader mhLabel">LOCAL: {solutionLab.place}</h4>
                                                        </div>

                                                        {       
                                                        
                                                            (solutionLab.arrivalTime && solutionLab.estimatedDuration) &&
                                                            
                                                            <div className={'monitorHeader block left'} >
                                                                <div className={'monitorHeader osInfo'}>
                                                                    <h5 className={'monitorHeader mHlabel'}>Início: </h5>
                                                                    <h5 className={'monitorHeader value'}>{solutionLab.arrivalTime.toDate().toLocaleString('pt-Br')}</h5>
                                                                </div>
                                                                <div className={'monitorHeader osInfo'}>
                                                                    <h5 className={'monitorHeader mHlabel'}>Fim: </h5>
                                                                    <h5 className={'monitorHeader value'}>{(new Date(solutionLab.arrivalTime.toDate().getTime() + solutionLab.estimatedDuration * 60000)).toLocaleString('pt-Br')}</h5>
                                                                </div>
                                                            </div>
                                                            
                                                        }
                                                    </div>
                                                        

                                                
                                                
                                                    <br/>
                                                
                                                    <div name="checkbox1" xs={12} md={12} sm={12} >
                                                            <h4>
                                                                <input 
                                                                        name="checkboxLab"
                                                                        type="checkbox" 
                                                                        value={solutionLab.id}
                                                                        onClick={this.onCheck}
                                                                        style={{width: '20px',
                                                                        height: '20px' }}

                                                                /> Selecionar corpo</h4>
                                                            
                                                    </div>
                                                       

                                                

                                            </CustomListGroupItem>
                                        );
                                    })}
                                </ListGroup>
                            </Panel.Body>
                        </Panel>              
                <LabModal show={this.state.showModal} onHide={closeModal} action={this.state.modalAction}/>
           
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    const { uid, email } = state.firebase.auth
    const { isEmpty, isLoaded, ...profile } = Object.assign({}, state.firebase.profile, {email: email, uid: uid})
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        allocables: state.firestore.ordered['allocables'],
        solution: state.firestore.ordered['solution'],
        user: profile
    }
};

const Loading = (props) => {
	
	const loadingGif = require('../../resources/img/loading-spin.svg')
	return (
		<div className={'loading-container'}>
			<img className={'loading-gif'} src={loadingGif} alt={'loading...'}/>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        SupervisorActions: (allocable, uid, user) => dispatch(SupervisorActions(allocable, uid, user))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        {'collection': 'allocables'},
        {'collection': 'solution'}
    ])
)(LabAllocables);
