import React from 'react'
import { Grid, Row, Col, Image } from 'react-bootstrap'

import './index.css'
import Loading from '../loading'
import { text } from '../../config/translate'

const Badge = ( { statusName = 'STATUS', amount = 0}) => {

    let content = (
        <div className={'badge-container'}>
            <div>
                <div>{text(statusName)}</div>
                <p>{amount}</p>
            </div>
        </div>
    )


    return content
}

export default function StatusCard ({ ...props }) {
    
    let content = <Loading/>

    const { statuses, img, name } = props

    content = (
        <Grid className={'cards-main-container'}>
            <Row>
                <Col className={'cards-title'} md={4} sm={4} xm={4}>
                    <Image className={'status-icon'} src={img} alt="icon"/>
                    <p>{text(name)}</p>
                </Col>
                <Col className={'cards-badges'} md={8} sm={8} xm={8}>
                    {
                        Object.keys(statuses).map( (status, key) => <Badge key={key} statusName={status} amount={statuses[status]}/>)
                    }
                </Col>
            </Row>
        </Grid>
    )

    return (
        <>
            {content}
        </>
    )
        
}
