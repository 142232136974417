import React from 'react'
import {connect} from 'react-redux';
import {signOut} from '../../store/actions/authActions';
import {Nav, NavItem, Badge} from 'react-bootstrap';
import { bootstrapUtils } from 'react-bootstrap/lib/utils';

bootstrapUtils.addStyle(Badge, 'custom');

const AdministratorLinks = (props) => {
    return(
        <Nav pullRight>
            <NavItem eventKey={1} href="/createservice">
                Criar Ordem de Serviço
            </NavItem>
            <NavItem eventKey={1} href="#" onClick={props.signOut}>
               Sair
            </NavItem>
        </Nav>
    )
};

const mapDispatchToProps = (dispatch) => {
    return{
        signOut: () => dispatch(signOut())
    };
};

export default connect(null, mapDispatchToProps)(AdministratorLinks);
