import * as firebase from "firebase/app"
import "firebase/firestore";

import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {firestoreConnect} from "react-redux-firebase";

import Collection from './collection'
import History from '../../history/history';
import Warehouse from "../../warehouse/warehouse";

const ALLOCABLES = 'allocables'
const SOLUTION = 'solution'
const RESOURCES = 'resources'
const STATUS = 'status'
const LABORATORY = 'laboratory'
const DEPOSIT = 'deposit'
const FINISHED = 'finished'

const statusValues = ['new','started','arrived_body','picked_up_body_funeral','at_lab','arrived_funeral','finished']

const originalState = {
    anyUnsaved: false,
    data: [],
    [RESOURCES]: [{
        value: '',
        label: ''
    }],
    [STATUS]: [],
    modifiedData: Object.create(null)
}

class Solution extends Collection {

    state = originalState

    constructor(props) {
        super(props)
        this.unsubscribe = []
    }

    componentDidMount = async() => {
        try {
            let allocablesRef = firebase.firestore().collection(ALLOCABLES)

            allocablesRef = allocablesRef.where('promiseTime', '>=', this.props.from)
            if (this.props.orderBy && this.props.order) allocablesRef = allocablesRef.orderBy(this.props.orderBy, this.props.order)
    
            let solutionRef = firebase.firestore().collection(SOLUTION)
            if (this.props.orderBy && this.props.order) solutionRef = solutionRef.orderBy(this.props.orderBy, this.props.order)
            
            
            this.unsubscribe[0] = allocablesRef.onSnapshot(this.dataListener)
            this.unsubscribe[1] = solutionRef.onSnapshot(this.dataListener)
            this.unsubscribe[2] = firebase.firestore().collection(RESOURCES).onSnapshot(snapshot => this.dataListener(snapshot, 'plate'))
            this.unsubscribe[3] = firebase.firestore().collection(STATUS).onSnapshot(snapshot => this.dataListener(snapshot, 'status'))
            
        } catch (error) {
            console.log('ERROR FETCHING DATA: ', error.message)
            return false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !(nextState.anyUnsaved)
    }

    componentWillUnmount = () => {
        if (Array.isArray(this.unsubscribe)) {
            this.unsubscribe.forEach(unsubscribe => unsubscribe())
        }
    }

    get solutions () {
        return this.state.data
    }

    add = (id, data, parent) => {
        if(this.state.anyUnsaved) return
        
        const add = Collection.prototype.add.bind(this)

        if (parent === SOLUTION) {
            let { allocables, docId } = data
            // console.log('allocables', id,  allocables)
            Object.keys(allocables).forEach(allc => {
                return Object.keys(allocables[allc]).forEach((key) => {
                    return (allocables[allc][key] === null || allocables[allc][key] === "") 
                    && delete allocables[allc][key]
                })
            })
            
            Object.keys(allocables).forEach(key => {
                let plateValue = ""
                let stateAllocable = this.state.data.find(alc => alc.id === key)
                let mydefinedResource = stateAllocable ? stateAllocable.definedResource : false
                if (mydefinedResource && mydefinedResource.length > 0) {
                    plateValue = mydefinedResource 
                } else {
                    plateValue = id
                }
                this.remove(key)
                //console.log('docId', docId)
                let extra = docId ? {plate: plateValue.toLowerCase(), docId: docId.toLowerCase()} : {plate: plateValue.toLowerCase()}
                // console.log('stateAllocable', stateAllocable)
                add(key, Object.assign({}, stateAllocable, allocables[key], extra, {_unsaved:false}))
            })

        } else if (parent === RESOURCES || parent === STATUS) {
            this.setState(
                Object.assign({}, this.state, {
                    [parent]: [
                        ...this.state[parent],
                        {label: id.toUpperCase(), value: id.toLowerCase(), extra: data}]}
                )
            )

        } else {
            if (!(this.isDeposit(data) || this.isLaboratory(data))) {
                //console.log('data', data)
                add(id, data)
            }
        }
    }

    startEditing = () => {
        const startEditing = Collection.prototype.startEditing.bind(this)
        this.setState(Object.assign({}, this.state, {anyUnsaved: true}))
        startEditing()
    }

    finishEditing = () => {
        const finishEditing = Collection.prototype.finishEditing.bind(this)
        this.setState(Object.assign({}, this.state, {anyUnsaved: false}))
        finishEditing()
    }

    modify(id, data, parent) {
        const modify = Collection.prototype.modify.bind(this)
        if (parent === SOLUTION) {
            if(this.state.anyUnsaved) return
            this.add(id, data, parent)
        } else if (parent !== RESOURCES && parent !== STATUS) {
            modify(id, data,parent)
        }
    }

    onChange = (id, data, parent) => {
        if (data.definedResource) data.definedResource = data.definedResource.toUpperCase()
        this.setState((state) => {
            let { modifiedData } = state
            let { _unsaved, ..._data } = data
            modifiedData[id] = Object.assign({}, modifiedData[id], _data)
            return ({modifiedData: modifiedData})
        }, () => this.modify(id, data, parent))
    }

    remove (id, parent) {
        const remove = Collection.prototype.remove.bind(this)
        if (parent !== RESOURCES && parent !== STATUS) {
            remove(id)
        }
    }

    async update(id, collection, originalData) {
        const allocable = this.state.data.find(data => data.id === id)
        const modifiedData = this.state.modifiedData[id]

        const manual = ({ status: _status }, { status: _statusOrigin, type: _type }) => {

            const status = String(_status).toUpperCase()
            const statusOrigin = String(_statusOrigin).toUpperCase()
            const type = String(_type).toUpperCase()
            
            switch (status) {
                case statusOrigin: return false
                case 'FINISHED':
                    if (type === 'BODY') return statusOrigin === 'AT_LAB' ? false : true
                    if (type === 'FUNERAL') return statusOrigin === 'ARRIVED_FUNERAL' ? false : true
                    return true
                default: return true
            }
        }

        const compare = a => b => {
            if (typeof a === 'string' && typeof b === 'string') {
                return a.toUpperCase() === b.toUpperCase()
            } else if ((a && 'seconds' in a) && (b && 'seconds' in b)) {
                return a.seconds === b.seconds && a.nanoseconds === b.nanoseconds
            } else {
                return a === b
            }
        }

        let hasChanged = false
        for (let key in modifiedData) {
            if (!compare(originalData[key])(allocable[key])) {
                hasChanged = true
            }
        }

        if (hasChanged) {

            let coordinatesPlate = async(plate) =>{
                var coordinates
                await firebase.firestore().collection("resources").doc(String(plate).toUpperCase()).get()
                        .then(doc => {
                            if (!doc.exists) {
                            console.log('No such document!');
                            } else {
                                coordinates = doc.data().coordinates
                            }
                        })
                        .catch(err => {
                            console.log('Error getting document', err);
                        });
                        
                return coordinates
            }

            let optimizer = () => {

                /** NOTE FROM LATER CHECKUP
                 * 
                 * this might have been set this way to check if the allocable
                 * is coming from ALLOCABLES collection or SOLUTION collection
                 * in which, for this last case, can't set anything for
                 * optimizer since it haven't been allocated by the
                 * service yet and can't be take in count at this point
                 * 
                 */
                if (String(allocable.id).toUpperCase() === String(allocable.docId).toUpperCase()) {
                    return false
                } else if (allocable.definedResource) {

                    return ({
                        original: allocable.docId,
                        definedResource: allocable.definedResource,
                        ignored: String(allocable.docId).toUpperCase() !== String(allocable.definedResource).toUpperCase()
                    })
                } else {
                    return false
                }
            }

            let coordinatesOriginal = ""
            let coordinatesDefinedResource = ""
            if(optimizer() !== false && optimizer().ignored === true){
                coordinatesOriginal = await coordinatesPlate(allocable.docId)
                coordinatesDefinedResource = await coordinatesPlate(allocable.definedResource)
            }
            
            let history = {
                user: this.props.user,
                changes: modifiedData,
                previous: originalData,
                data: allocable,
                optimizer: optimizer(),
                type: 'UPDATE',
                source: {
                    type: 'web',
                    resource: allocable.definedResource ? allocable.definedResource.toUpperCase() : false
                },
                coordinatesOriginal: coordinatesOriginal,
                coordinatesDefinedResource: coordinatesDefinedResource,
            }

            if (manual(modifiedData, originalData)) {
                // let { serviceOrder } = allocable
                // await Warehouse.supervised.add(serviceOrder)
                await this.modify(id, {supervised: true})
            }
            
            History.save(history)
        }

        this.setState({modifiedData: Object.create(null)})
        
        if(allocable && !this.isDeposit(allocable) && !this.isLaboratory(allocable)){
            const update = Collection.prototype.update.bind(this)
            update(id, ALLOCABLES)
        }
    }

    getAllocables = (solutionId) => {
        return this.state.data.filter(({plate}) => plate === solutionId)
    }    

    get allAllocables() {
        let allocables = []
        if (this.state.data) {
            this.state.data.forEach(solution => {
                if ('plate' in solution) {
                    allocables.push(solution)
                }
            })
        }
        return allocables
    }

    addAllocable = (solutionId, allocable) => {
        let solution = this.get(solutionId)
        if (solution) {
            let updatedData = this.state.data.map(solution => {
                if (solution.id === solutionId) {
                    if (solution.allocables) {
                        const modifiedAllocables = Object.assign({}, solution.allocables, allocable)
                        return Object.assign({}, solution, {allocables: modifiedAllocables})
                    }
                }
                return solution
            })
             this.setState(
                Object.assign({}, this.state, {data: updatedData})
            , () => this.update(solutionId))
            
        }
    }

    isInSolution = (id) => {
        return this.allAllocables.find(allocable => allocable.id === id)
    }

    isInAllocables = id => {
        return this.state.data.find(data => {
            return data.id === id
        })
    }

    isLaboratory = ({type}) => type === LABORATORY
    isDeposit = ({type}) => type === DEPOSIT

    isFinished = ({status}) => status === FINISHED

    getList = (list) => {
        switch (list) {
            case 'definedResource':
                return this.state[RESOURCES]
            case 'plate':
                return this.state[RESOURCES]
            case 'status':
                return this.state[STATUS]
            default:
                return undefined
        }
    }

    reset = async () => {
        this.setState(originalState, () => this.componentDidMount())
    }

    changedColumns = (id) => {
        const { modifiedData } = this.state
        if (modifiedData) {
            let columns = modifiedData[id]
            if (columns) {
                return Object.keys(columns)
            }
        }
        return []
    }
    
    render() {
        let data

        if (Object.keys(this.props.filter).length > 0) {
            data = this.state.data.filter(data => {
                let composedValidation = true
                for(let filter in this.props.filter) {
                    let valid = false
                    if (this.props.filter[filter] === null) {
                        valid = true
                        continue
                    }
                    if (Array.isArray(this.props.filter[filter])) {
                        for (let each in this.props.filter[filter]) {
                            let { value } = this.props.filter[filter][each]
                            valid = valid || JSON.stringify(data[filter]).toUpperCase() === JSON.stringify(value).toUpperCase()
                        }
                    } else {
                        valid = JSON.stringify(data[filter]).toUpperCase() === JSON.stringify(this.props.filter[filter].value).toUpperCase()
                    }
                    composedValidation = composedValidation && valid
                }
                return composedValidation
            })
        } else {
            data = this.state.data
        }

        const sortDataByName = (a, b) => {
            
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
        }
        
        return this.props.children({
            data: data && !(data.find(d=>d._unsaved)) ? data.sort(sortDataByName) : data,
            get: this.get,
            reset: this.reset,
            solutions: this.solutions,
            allocables: this.getAllocables,
            addAllocable: this.addAllocable,
            allAllocables: this.allAllocables,
            isInSolution: this.isInSolution,
            onChange: this.onChange,
            update: this.update,
            getList: this.getList,
            changedColumns: this.changedColumns,
            finishEditing: this.finishEditing,
            startEditing: this.startEditing
        })
    }
}

const mapStateToProps = (state) => {
    const { uid, email } = state.firebase.auth
    const { isEmpty, isLoaded, ...profile } = Object.assign({}, state.firebase.profile, {email: email, uid: uid})
    return ({
        user: profile
    })
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        {'collection': 'resources'},
        {'collection': 'users'}
    ])
)(Solution);
