import React, {Component} from 'react';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import {createAllocable, ignoreAllocables} from '../../store/actions/allocableActions';
import {
    Button,
    Row,
    Col,
    Grid,
    Glyphicon,
    Checkbox,
    Alert,
    Panel
} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {signOut} from "../../store/actions/authActions";
import {EsriProvider} from 'leaflet-geosearch';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/pt-br';
import BodyForm from './BodyForm';
import OSFrom from './OSForm';
import FuneralForm from './FuneralForm';
import BurialForm from './BurialForm';
import MealForm from './MealForm';
import FuneralReturnForm from './FuneralReturnForm';
import MealReturnForm from './MealReturnForm';
import ServiceCreationModal from "./ServiceCreationModal";
import GeneralForm from './GeneralForm';

class CreateService extends Component {

    constructor() {
        super();

        this.state = {
            name: '',
            serviceOrder: '',

            /**
             * @DESCRIPTIONS
             */
            bodyDescription: '',
            funeralDescription: '',
            burialDescription: '',
            mealDescription: '',
            funeralReturnDescription: '',
            mealReturnDescription: '',
            generalDescription: '',

            /**
             * @COORDINATES
             */
            bodyCoordinates: {lat: '', lng: ''},
            funeralCoordinates: {lat: '', lng: ''},
            burialOriginCoordinates: {lat: '', lng: ''},
            burialDestinationCoordinates: {lat: '', lng: ''},
            mealCoordinates: {lat: '', lng: ''},
            funeralReturnCoordinates: {lat: '', lng: ''},
            mealReturnCoordinates: {lat: '', lng: ''},
            generalCoordinates: {lat: '', lng: ''},

            /**
             * @ADDRESSES
             */
            bodyAddress: '',
            funeralAddress: '',
            burialOriginAddress: '',
            burialDestinationAddress: '',
            mealAddress: '',
            funeralReturnAddress: '',
            mealReturnAddress: '',
            generalAddress: '',

            /**
             * @CONTACTS
             */
            bodyContact: [],
            funeralContact: [],
            burialContact: [],
            mealContact: [],
            funeralReturnContact: [],
            mealReturnContact: [],
            generalContact: [],

            provider: new EsriProvider(),

            /**
             * @SUGESTIONS
             */
            bodySuggestions: [],
            funeralSuggestions: [],
            burialOriginSuggestions: [],
            burialDestinationSuggestions: [],
            mealSuggestions: [],
            funeralReturnSuggestions: [],
            mealReturnSuggestions: [],
            generalSuggestions: [],

            /**
             * @PROMISE_TIMES
             */
            bodyPromiseTime: new Date(new Date().getTime() + (1 * 60 * 60 * 1000)),
            funeralPromiseTime: null,
            burialPromiseTime: null,
            mealPromiseTime: null,
            funeralReturnPromiseTime: null,
            mealReturnPromiseTime: null,
            generalPromiseTime: null,

            /**
             * @ENABLERS
             */
            bodyEnabled: false,
            funeralEnabled: false,
            burialEnabled: false,
            mealEnabled: false,
            funeralReturnEnabled: false,
            mealReturnEnabled: false,
            generalEnabled: false,

            /**
             * @CONTROLLERS
             */
            disabled: false,
            valid: false,
            lab: null,
            deposit: null,
            currentAllocables: {},
            init: true,
            showModal: false,
            modalAction: 0,

            /**
             * @DEFINED_MANUALS_RESOURCES
             */
            bodyDefinedManual: false,
            bodyDefinedResource: '',
            funeralDefinedManual: false,
            funeralDefinedResource: '',
            burialDefinedManual: false,
            burialDefinedResource: '',
            mealDefinedManual: false,
            mealDefinedResource: '',
            funeralReturnDefinedManual: false,
            funeralReturnDefinedResource: '',
            mealReturnDefinedManual: false,
            mealReturnDefinedResource: '',
            generalDefinedManual: false,
            generalDefinedResource: '',

            /**
             * @STATUSES
             */
            bodyStatus: 'new',
            funeralStatus: 'new',
            funeralReturnStatus: 'new',
            burialStatus: 'new',
            mealStatus: 'new',
            mealReturnStatus: 'new',
            generalStatus: 'new',

            /**
             * @PRIORITY
             */
            priority: 2
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {os, allocables, resources} = props;

        if (resources !== undefined && allocables !== undefined && state.init) {
            state.init = false;

            if (os !== undefined) {
                state = {
                    ...state,
                    serviceOrder: os
                };

                for (let key in allocables) {
                    if (allocables[key].serviceOrder === os) {
                        let currentAllocables = state.currentAllocables;
                        currentAllocables[allocables[key].type] = allocables[key].id;
                        state = {
                            ...state,
                            currentAllocables
                        };
                        let addressType = allocables[key].type === 'burial' ? 'Destination' : '';

                        state = {
                            ...state,
                            name: allocables[key].name,
                            priority: allocables[key].priority !== undefined ? allocables[key].priority : 2,
                            [allocables[key].type.replace('_r', 'R') + 'Description']: allocables[key].description,
                            [allocables[key].type.replace('_r', 'R') + addressType + 'Coordinates']: {
                                lat: allocables[key].destinationCoordinates['_lat'],
                                lng: allocables[key].destinationCoordinates['_long']
                            },
                            [allocables[key].type.replace('_r', 'R') + 'OriginCoordinates']: allocables[key].originCoordinates ? {
                                lat: allocables[key].originCoordinates['_lat'],
                                lng: allocables[key].originCoordinates['_long']
                            } : null,
                            [allocables[key].type.replace('_r', 'R') + addressType + 'Address']: allocables[key].destinationAddress,
                            [allocables[key].type.replace('_r', 'R') + 'OriginAddress']: allocables[key].originAddress,
                            [allocables[key].type.replace('_r', 'R') + 'Contact']: allocables[key].contact,
                            [allocables[key].type.replace('_r', 'R') + 'PromiseTime']: allocables[key].promiseTime.toDate(),
                            [allocables[key].type.replace('_r', 'R') + 'Enabled']: true,
                            [allocables[key].type.replace('_r', 'R') + 'DefinedManual']: allocables[key].definedResource !== null && allocables[key].definedResource !== undefined && allocables[key].definedResource !== '',
                            [allocables[key].type.replace('_r', 'R') + 'DefinedResource']: allocables[key].definedResource ? allocables[key].definedResource : resources[0].id,
                            [allocables[key].type.replace('_r', 'R') + 'Status']: allocables[key].status,

                        };

                        // if (allocables[key].type === 'funeral') {
                        //     state = {
                        //         ...state,
                        //         funeralReturnPromiseTime: new Date(allocables[key].promiseTime.toDate().getTime() + (allocables[key].estimatedDuration * 60 * 1000)),
                        //         mealReturnPromiseTime: new Date(allocables[key].promiseTime.toDate().getTime() + (allocables[key].estimatedDuration * 60 * 1000))
                        //     }
                        // }

                    } else if (allocables[key].type === 'laboratory') {
                        state = {
                            ...state,
                            lab: allocables[key]
                        }
                    } else if (allocables[key].type === 'deposit') {
                        state = {
                            ...state,
                            deposit: allocables[key]
                        }
                    }
                }
            } else {
                for (let key in allocables) {
                    if (allocables[key].type === 'laboratory') {
                        state = {
                            ...state,
                            lab: allocables[key]
                        }
                    } else if (allocables[key].type === 'deposit') {
                        state = {
                            ...state,
                            deposit: allocables[key]
                        }
                    }
                }
            }
        }
        return state;
    }

    /**
     * *****************************************************************
     * @HANDLERS
     * *****************************************************************
     */

    handleChange = (e) => {
        if (e.target !== undefined &&
            ((e.target.id.includes('Defined') && !e.target.id.includes('Resource')) ||
                e.target.id.includes('Enabled') || e.target.id === 'funeralReturn' ||
                e.target.id === 'mealReturn')) {

            if (e.target.id.includes('Auto')) e.target.checked = !e.target.checked;
            this.setState({
                [e.target.id.replace('Auto', 'Manual')]: e.target.checked
            });
        }
        else {
            this.setState({
                [e.target.id]: e.target.value
            });
        }
    };

    handleChangeAddress = (e) => {
        this.setState({[e.target.id]: e.target.value});
        let key = e.target.id.replace('Address', 'Suggestions');
        this.state.provider
            .search({query: e.target.value})
            .then((result) => {
                this.setState({
                    [key]: result
                })
            });
    };

    suggestionClick = (e) => {
        const fields = e.target.id.split(',');
        const suggestion = this.state[fields[0]][fields[1]];
        this.setState({
            ...this.state,
            [fields[0]]: [],
            [fields[0].replace('Suggestions', 'Coordinates')]: {
                lat: suggestion.y,
                lng: suggestion.x
            },
            [fields[0].replace('Suggestions', 'Address')]: suggestion.label
        });
    };

    handleSubmit = () => {
        if (!this.isValid()) {
            alert('Alguns campos estão inválidos. Favor revisar os campos em vermelho e confirmar que você selecionou pelo menos uma etapa.');
            return;
        } else {
            let modalAction = 0;
            if (this.props.os !== undefined)
                modalAction = 1;
            else
                modalAction = 0;
            this.setState({
                showModal: true,
                modalAction
            })
        }
    };

    handleIgnoreSubmit = () => {
        let modalAction = 2;
        this.setState({
            showModal: true,
            modalAction
        })
    };

    handleSelect = (type) => {
        this.setState({type: type});
    };

    /**
     * *****************************************************************
     * @HANDLE_DATE_CHANGES
     * *****************************************************************
     */

    handleDateChangeBody = (e) => {
        this.setState({bodyPromiseTime: e});
    };
    
    handleDateChangeFuneral = (e) => {
        this.setState({
            funeralPromiseTime: e,
            //funeralReturnPromiseTime: new Date(e + (6 * 60 * 60 * 1000)),
            //mealReturnPromiseTime: new Date(e + (6 * 60 * 60 * 1000))
        });
    };

    handleDateChangeBurial = (e) => {
        this.setState({burialPromiseTime: e});
    };

    handleDateChangeMeal = (e) => {
        this.setState({mealPromiseTime: e});
    };

    handleDateChangeFuneralReturn = (e) => {
        this.setState({funeralReturnPromiseTime: e});
    };

    handleDateChangeMealReturn = (e) => {
        this.setState({mealReturnPromiseTime: e});
    };

    handleDateChangeGeneral = (e) => {
        this.setState({generalPromiseTime: e});
    }

    /**
     * *****************************************************************
     * @VALIDATIONS
     * *****************************************************************
     */

    getValidationDate(value){
        var data = new Date()
        if (value  != null && (value instanceof Date || value._isAMomentObject) && value>data)
            return 'success';
        else
            return 'error';
    }

    getValidationState(value) {
        var data = new Date()
        if (value != null && (value.length > 0 || value instanceof Date || value._isAMomentObject) && value>data)
            return 'success';
        else
            return 'error';
    }

    getValidationStateCoordinates(value) {
        if (value != null && parseInt(value) < 0)
            return 'success';
        else
            return 'error';
    }

    isValidDate(value){
        var data = new Date()
        return (value  != null && (value instanceof Date || value._isAMomentObject) && value>data)
    }

    isValid = () => {
        let validBody = true;
        let validFuneral = true;
        let validBurial = true;
        let validMeal = true;
        let validFuneralReturn = true;
        let validMealReturn = true;
        let validGeneral = true;
        let validOs = (this.state.bodyEnabled || this.state.funeralEnabled || this.state.burialEnabled || this.state.mealEnabled
            || this.state.funeralReturnEnabled || this.state.mealReturnEnabled || this.state.generalEnabled);

        if (this.state.name.length === 0 || this.state.serviceOrder.length === 0) return false;

        if (this.state.bodyEnabled) {
            validBody =
                this.state.bodyCoordinates.lat != null && this.state.bodyCoordinates.lat < 0 &&
                this.state.bodyCoordinates.lng != null && this.state.bodyCoordinates.lng < 0 &&
                this.state.bodyAddress.length > 0 &&
                this.isValidDate(this.state.bodyPromiseTime);

        }
        if (this.state.funeralEnabled) {
            validFuneral =
                this.state.funeralCoordinates.lat != null && this.state.funeralCoordinates.lat < 0 &&
                this.state.funeralCoordinates.lng != null && this.state.funeralCoordinates.lng < 0 &&
                this.state.funeralAddress.length > 0 &&
                this.isValidDate(this.state.funeralPromiseTime);
        }
        if (this.state.burialEnabled) {
            validBurial =
                this.state.burialDestinationCoordinates.lat != null && this.state.burialDestinationCoordinates.lat < 0 &&
                this.state.burialDestinationCoordinates.lng != null && this.state.burialDestinationCoordinates.lng < 0 &&
                this.state.burialDestinationAddress.length > 0 &&
                this.state.burialOriginCoordinates.lat != null && this.state.burialOriginCoordinates.lat < 0 &&
                this.state.burialOriginCoordinates.lng != null && this.state.burialOriginCoordinates.lng < 0 &&
                this.state.burialOriginAddress.length > 0 &&
                this.isValidDate(this.state.burialPromiseTime);
        }
        if (this.state.mealEnabled) {
            validMeal =
                this.state.mealCoordinates.lat != null && this.state.mealCoordinates.lat < 0 &&
                this.state.mealCoordinates.lng != null && this.state.mealCoordinates.lng < 0 &&
                this.state.mealAddress.length > 0 &&
                this.isValidDate(this.state.mealPromiseTime);
        }
        if (this.state.funeralReturnEnabled) {
            validFuneralReturn =
                this.state.funeralReturnCoordinates.lat != null && this.state.funeralReturnCoordinates.lat < 0 &&
                this.state.funeralReturnCoordinates.lng != null && this.state.funeralReturnCoordinates.lng < 0 &&
                this.state.funeralReturnAddress.length > 0 &&
                this.isValidDate(this.state.funeralReturnPromiseTime);
        }
        if (this.state.mealReturnEnabled) {
            validMealReturn =
                this.state.mealReturnCoordinates.lat != null && this.state.mealReturnCoordinates.lat < 0 &&
                this.state.mealReturnCoordinates.lng != null && this.state.mealReturnCoordinates.lng < 0 &&
                this.state.mealReturnAddress.length > 0 &&
                this.isValidDate(this.state.mealReturnPromiseTime);
        }

        if (this.state.generalEnabled) {
            validGeneral =
                this.state.generalCoordinates.lat != null && this.state.generalCoordinates.lat < 0 &&
                this.state.generalCoordinates.lng != null && this.state.generalCoordinates.lng < 0 &&
                this.state.generalAddress.length > 0 &&
                this.isValidDate(this.state.generalPromiseTime);
        }

        return validBody && validFuneral && validBurial && validMeal && validFuneralReturn && validMealReturn && validGeneral && validOs;
    };

    render() {

        const {auth, profile, signOut, authError, resources} = this.props;

        if (!auth.uid || profile.type === 'driver') 
            return <Redirect to={'/signin'}/>;
        else if (profile.type === 0 || profile.type === 3) 
            signOut();

        let closeModal = (success, justification) => {
            if (success && this.state.modalAction === 2) {
                if (this.state.currentAllocables === undefined || Object.keys(this.state.currentAllocables).length === 0) {
                    alert('Não existem allocables a serem ignorados');
                } else {
                    this.props.ignoreAllocables(this.state.currentAllocables, justification, auth.uid);
                }
            }
            else if (success) {
                this.props.createAllocable(this.state, this.state.lab, this.state.deposit, this.props.auth.uid, this.props.allocables, this.props.user);
            }

            this.setState({showModal: false});
        };

        return (
            <Grid>
                <Row className="show-grid">
                    <Col xs={12} md={12}>
                        <>
                            <OSFrom handleSubmit={this.handleSubmit}
                                    handleChange={this.handleChange}
                                    serviceOrder={this.state.serviceOrder}
                                    name={this.state.name}
                                    priority={this.state.priority}
                                    getValidationState={this.getValidationState}/><br/>

                                <Panel>
                                    <Panel.Heading>
                                        Serviços
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <Grid>
                                            <Row className="show-grid">
                                                <Col xs={3} sm={3} md={3} lg={3} xsOffset={2} smOffset={2} mdOffset={2}
                                                    lgOffset={2}>
                                                    <Row>
                                                        <Checkbox inline id={'bodyEnabled'} onChange={this.handleChange}
                                                                checked={this.state.bodyEnabled}>
                                                            Remoção
                                                        </Checkbox>
                                                    </Row>
                                                    
                                                </Col>
                                                <Col xs={3} md={3} sm={3} lg={3}>
                                                    <Row>
                                                        <Checkbox inline id={'funeralEnabled'} onChange={this.handleChange}
                                                                checked={this.state.funeralEnabled}>
                                                            Velório
                                                        </Checkbox>
                                                    </Row>
                                                    {/* <Row>
                                                        <Checkbox inline id={'mealEnabled'} onChange={this.handleChange}
                                                                checked={this.state.mealEnabled}>
                                                            Kit Lanche
                                                        </Checkbox>
                                                    </Row> */}
                                                    <Row>
                                                        <Checkbox inline id={'generalEnabled'} onChange={this.handleChange}
                                                                checked={this.state.generalEnabled}>
                                                            Genérica
                                                        </Checkbox>
                                                    </Row>
                                                </Col>
                                                <Col xs={4} md={4} sm={4} lg={4}>
                                                    <Row>
                                                        <Checkbox inline id={'burialEnabled'} onChange={this.handleChange}
                                                                checked={this.state.burialEnabled}>
                                                            Cortejo
                                                        </Checkbox>
                                                    </Row>
                                                    {/* <Row>
                                                        <Checkbox inline id={'funeralReturnEnabled'} onChange={this.handleChange}
                                                                checked={this.state.funeralReturnEnabled}>
                                                            Retorno de paramentos
                                                        </Checkbox>
                                                    </Row> */}
                                                    {/* <Row>
                                                        <Checkbox inline id={'mealReturnEnabled'} onChange={this.handleChange}
                                                                checked={this.state.mealReturnEnabled}>
                                                            Retorno de kit Lanche
                                                        </Checkbox>
                                                    </Row> */}
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </Panel.Body>
                                </Panel>

                            {this.state.bodyEnabled ?
                                <BodyForm handleSubmit={this.handleSubmit}
                                          handleChange={this.handleChange}
                                          handleChangeAddress={this.handleChangeAddress}
                                          handleDateChangeBody={this.handleDateChangeBody}
                                          bodyAddress={this.state.bodyAddress}
                                          bodySuggestions={this.state.bodySuggestions}
                                          bodyContact={this.state.bodyContact}
                                          suggestionClick={this.suggestionClick}
                                          bodyCoordinates={this.state.bodyCoordinates}
                                          bodyPromiseTime={this.state.bodyPromiseTime}
                                          name={this.state.name}
                                          bodyDescription={this.state.bodyDescription}
                                          getValidationDate={this.getValidationDate}
                                          getValidationState={this.getValidationState}
                                          getValidationStateCoordinates={this.getValidationStateCoordinates}
                                          bodyDefinedManual={this.state.bodyDefinedManual}
                                          resources={resources}
                                          bodyDefinedResource={this.state.bodyDefinedResource}/>
                                : null}

                            {this.state.funeralEnabled ?
                                <FuneralForm handleSubmit={this.handleSubmit}
                                             handleChange={this.handleChange}
                                             handleDateChangeFuneral={this.handleDateChangeFuneral}
                                             handleChangeAddress={this.handleChangeAddress}
                                             funeralAddress={this.state.funeralAddress}
                                             funeralSuggestions={this.state.funeralSuggestions}
                                             funeralContact={this.state.funeralContact}
                                             suggestionClick={this.suggestionClick}
                                             funeralCoordinates={this.state.funeralCoordinates}
                                             funeralPromiseTime={this.state.funeralPromiseTime}
                                             funeralDescription={this.state.funeralDescription}
                                             getValidationDate={this.getValidationDate}
                                             getValidationState={this.getValidationState}
                                             getValidationStateCoordinates={this.getValidationStateCoordinates}
                                             funeralReturn={this.state.funeralReturn}
                                             funeralDefinedManual={this.state.funeralDefinedManual}
                                             resources={resources}
                                             funeralDefinedResource={this.state.funeralDefinedResource}/>
                                : null}

                            {this.state.burialEnabled ?
                                <BurialForm handleSubmit={this.handleSubmit}
                                            handleChange={this.handleChange}
                                            handleDateChangeBurial={this.handleDateChangeBurial}
                                            handleChangeAddress={this.handleChangeAddress}
                                            burialOriginAddress={this.state.burialOriginAddress}
                                            burialDestinationAddress={this.state.burialDestinationAddress}
                                            burialOriginSuggestions={this.state.burialOriginSuggestions}
                                            burialDestinationSuggestions={this.state.burialDestinationSuggestions}
                                            burialContact={this.state.burialContact}
                                            suggestionClick={this.suggestionClick}
                                            burialOriginCoordinates={this.state.burialOriginCoordinates}
                                            burialDestinationCoordinates={this.state.burialDestinationCoordinates}
                                            burialPromiseTime={this.state.burialPromiseTime}
                                            burialDescription={this.state.burialDescription}
                                            getValidationDate={this.getValidationDate}
                                            getValidationState={this.getValidationState}
                                            getValidationStateCoordinates={this.getValidationStateCoordinates}
                                            burialDefinedManual={this.state.burialDefinedManual}
                                            resources={resources}
                                            burialDefinedResource={this.state.burialDefinedResource}/>
                                : null}

                            {this.state.mealEnabled ?
                                <MealForm handleSubmit={this.handleSubmit}
                                          handleChange={this.handleChange}
                                          handleDateChangeMeal={this.handleDateChangeMeal}
                                          handleChangeAddress={this.handleChangeAddress}
                                          mealAddress={this.state.mealAddress}
                                          mealSuggestions={this.state.mealSuggestions}
                                          mealContact={this.state.mealContact}
                                          suggestionClick={this.suggestionClick}
                                          mealCoordinates={this.state.mealCoordinates}
                                          mealPromiseTime={this.state.mealPromiseTime}
                                          mealDescription={this.state.mealDescription}
                                          name={this.state.name}
                                          getValidationDate={this.getValidationDate}
                                          getValidationState={this.getValidationState}
                                          getValidationStateCoordinates={this.getValidationStateCoordinates}
                                          mealDefinedManual={this.state.mealDefinedManual}
                                          resources={resources}
                                          mealDefinedResource={this.state.mealDefinedResource}/>
                                : null}

                            {this.state.funeralReturnEnabled ?
                                <FuneralReturnForm handleSubmit={this.handleSubmit}
                                                   handleChange={this.handleChange}
                                                   handleDateChangeFuneralReturn={this.handleDateChangeFuneralReturn}
                                                   handleChangeAddress={this.handleChangeAddress}
                                                   funeralReturnAddress={this.state.funeralReturnAddress}
                                                   funeralReturnSuggestions={this.state.funeralReturnSuggestions}
                                                   funeralReturnContact={this.state.funeralReturnContact}
                                                   suggestionClick={this.suggestionClick}
                                                   funeralReturnCoordinates={this.state.funeralReturnCoordinates}
                                                   funeralReturnPromiseTime={this.state.funeralReturnPromiseTime}
                                                   funeralReturnDescription={this.state.funeralReturnDescription}
                                                   name={this.state.name}
                                                   getValidationDate={this.getValidationDate}
                                                   getValidationState={this.getValidationState}
                                                   getValidationStateCoordinates={this.getValidationStateCoordinates}
                                                   funeralReturnDefinedManual={this.state.funeralReturnDefinedManual}
                                                   resources={resources}
                                                   funeralReturnDefinedResource={this.state.funeralReturnDefinedResource}/>
                                : null}

                            {this.state.mealReturnEnabled ?
                                <MealReturnForm handleSubmit={this.handleSubmit}
                                                handleChange={this.handleChange}
                                                handleDateChangeMealReturn={this.handleDateChangeMealReturn}
                                                handleChangeAddress={this.handleChangeAddress}
                                                mealReturnAddress={this.state.mealReturnAddress}
                                                mealReturnSuggestions={this.state.mealReturnSuggestions}
                                                mealReturnContact={this.state.mealReturnContact}
                                                suggestionClick={this.suggestionClick}
                                                mealReturnCoordinates={this.state.mealReturnCoordinates}
                                                mealReturnPromiseTime={this.state.mealReturnPromiseTime}
                                                mealReturnDescription={this.state.mealReturnDescription}
                                                name={this.state.name}
                                                getValidationDate={this.getValidationDate}
                                                getValidationState={this.getValidationState}
                                                getValidationStateCoordinates={this.getValidationStateCoordinates}
                                                mealReturnDefinedManual={this.state.mealReturnDefinedManual}
                                                resources={resources}
                                                mealReturnDefinedResource={this.state.mealReturnDefinedResource}/>
                                : null}

                            {this.state.generalEnabled ?
                                <GeneralForm
                                    handleSubmit={this.handleSubmit}
                                    handleChange={this.handleChange}
                                    handleChangeAddress={this.handleChangeAddress}
                                    handleDateChangeGeneral={this.handleDateChangeGeneral}
                                    generalAddress={this.state.generalAddress}
                                    generalSuggestions={this.state.generalSuggestions}
                                    generalContact={this.state.generalContact}
                                    suggestionClick={this.suggestionClick}
                                    generalCoordinates={this.state.generalCoordinates}
                                    generalPromiseTime={this.state.generalPromiseTime}
                                    name={this.state.name}
                                    generalDescription={this.state.generalDescription}
                                    generalDefinedManual={this.state.generalDefinedManual}
                                    generalDefinedResource={this.state.generalDefinedResource}
                                    getValidationDate={this.getValidationDate}
                                    getValidationState={this.getValidationState}
                                    getValidationStateCoordinates={this.getValidationStateCoordinates}
                                    
                                    resources={resources}
                                />
                                : null}
                        </>
                            <div align='center' style={{padding: 25}}>
                                <Button
                                    onClick={this.handleSubmit}
                                    disabled={this.state.disabled}
                                >
                                    <Glyphicon glyph={'glyphicon glyphicon-ok'}/>
                                    <p style={{borderLeft: 10}}>Enviar</p>
                                </Button>
                            </div>
                            
                        {authError ?
                            <Alert bsStyle="danger"> <strong>{authError}</strong>
                            </Alert> : null}
                    </Col>
                </Row>
                <ServiceCreationModal show={this.state.showModal} onHide={closeModal} action={this.state.modalAction}/>
            </Grid>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const { uid, email } = state.firebase.auth
    const { isEmpty, isLoaded, ...profile } = Object.assign({}, state.firebase.profile, {email: email, uid: uid})
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        allocables: state.firestore.ordered.allocables,
        resources: state.firestore.ordered.resources,
        os: id,
        user: profile
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createAllocable: (serviceOrder, lab, deposit, uid, allocables, user) => dispatch(createAllocable(serviceOrder, lab, deposit, uid, allocables, user)),
        ignoreAllocables: (allocables, justification, uid) => dispatch(ignoreAllocables(allocables, justification, uid)),
        signOut: () => dispatch(signOut())
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        {'collection': 'allocables'},
        {'collection': 'resources'}
    ])
)(CreateService);
