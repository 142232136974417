const allocableReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SERVICE_ORDER_CREATED':
            window.location.reload();
            return {
                ...state,
                authError: null
            };
        case 'SERVICE_ORDER_ERROR':
            return {
                ...state,
                authError: action.err
            };
        case 'SERVICE_ORDER_UPDATED':
            return {
                ...state,
                authError: null
            };
        case 'SUPERVISOR_UPDATE':
            window.location.reload();
            return {
                showModal: false,
                modalAction: 0,
                currentId: '',
                authError: null
            };
        case 'SUPERVISOR_ERROR':
            return {
                showModal: false,
                modalAction: 0,
                currentId: '',
                authError: action.err
            };
        case 'GET_PROGRESS_SUCCESS':
            return {
                ...state,
                [action.key]: action.result
            };
        case 'GET_PROGRESS_ERROR':
            return state;
        case 'GET_ROUTE_SUCCESS':
            return {
                ...state,
                geoJson: action.result
            };
        case 'GET_ROUTE_ERROR':
            return state;
        case 'DELETE_ALLOCABLES_SUCCESS':
            return state;
        case 'DELETE_ALLOCABLES_ERROR':
            return state;
        case 'SERVICE_ORDER_IGNORED':
            alert('OS ignorada com sucesso!');
            window.location.href = '/';
            return state;
        case 'SERVICE_ORDER_IGNORED_ERROR':
            alert('ERRO IGNORANDO OS: ' +  action.err);
            return state;
        default:
            return state;
    }
};

export default allocableReducer;

