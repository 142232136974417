import React, {Component} from 'react';
import 'react-table/react-table.css'
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {firestoreConnect} from "react-redux-firebase";
import {Redirect} from "react-router-dom";
import {signOut} from "../../store/actions/authActions";
import {
    Panel,
    Grid,
    Row,
    Col,
    ListGroup,
    FormControl,
    FormGroup,
    Button, Glyphicon,

} from 'react-bootstrap';
import Select from 'react-select'


import VehicleModal from './VehicleModal';
import {SupervisorActions} from "../../store/actions/allocableActions";

const UNAVAILABLE = 'unavailable'
const AVAILABLE = 'available'
const DISPONIVEL = 'Disponível'
const EMSERVICO = 'Em Serviço'
const OFFLINE = 'Off-Line'

var androidID = undefined

// let init = true;
class Vehicles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            modalAction: 0,
            currentId: '',
            plate: '',
            androidID:'',
        }
    }

    UNSAFE_UNSAFE_componentWillReceiveProps(nextProp) {
        if(Object.keys(this.state).length === 4){
            const {resources} = nextProp;
            resources && resources.map(resource => {
                return (
                    this.setState({
                        [resource.id + '-androidID']: resource.androidID,
                        [resource.id + '-capacity']: resource.capacity,
                        [resource.id + '-capacityReturn']: resource.capacityReturn,
                        [resource.id + '-loadQuantity']: resource.loadQuantity,
                        [resource.id + '-type']: resource.type,
                        [resource.id + '-status']: resource.status
                    })
                )
            });
        }
    }

    handleChange = (e) => {
        if(e.target.id.includes('status')){
            this.setState({[e.target.id]: e.target.value});
            return
        }
        this.setState({[e.target.id]: parseInt(e.target.value)});
    };

    onInputChange = (e) => {
        var nome = document.getElementById(e.target.id)
        if(nome.value === undefined || nome.value === "" || nome.value === null || nome.value === " " || nome.value === "undefined" ){
            androidID = nome.placeholder
        }else{
            androidID = nome.value;
        }
    }

    render() {
        

        const getStatusColour = (resource) => {
            switch (getStatusName(resource)) {
                case DISPONIVEL:
                    return 'success'
                case EMSERVICO:
                    return 'info'
                case OFFLINE:
                    return 'danger'
                default:
                    return 'warning'
            }
        }

        const getStatusName = ({status, lastAllocable, currentAllocable}) => {
            if (status)
                if (typeof status === 'string')
                    if (status === UNAVAILABLE)
                        return OFFLINE
                    if (!currentAllocable)
                        return DISPONIVEL  
                    if (currentAllocable && lastAllocable)
                        if (typeof currentAllocable === 'string' && typeof lastAllocable === 'string')
                            if (currentAllocable.toUpperCase() === lastAllocable.toUpperCase())
                                return DISPONIVEL
                            else
                                return EMSERVICO
                                
            return OFFLINE
        }
        
        const statusOrder = (objA, objB) => {

            const getPlate = ({plate}) => {
                if (plate)
                    if (typeof plate === 'string')
                        return plate
                return ''
            }

            switch (getStatusName(objA)) {
                case DISPONIVEL:
                    switch (getStatusName(objB)) {
                        case DISPONIVEL: return getPlate(objA).localeCompare(getPlate(objB))
                        default: return -1
                    }
                case EMSERVICO:
                    switch (getStatusName(objB)) {
                        case DISPONIVEL: return 1
                        case EMSERVICO: return getPlate(objA).localeCompare(getPlate(objB))
                        default: return -1
                    }
                case OFFLINE:
                    switch (getStatusName(objB)) {
                        case OFFLINE: return getPlate(objA).localeCompare(getPlate(objB))
                        default: return 1
                    }
                default:
                    return 1
            }
        }

        const {auth, profile, signOut, users} = this.props;
        const resources = Array.isArray(this.props.resources) ? this.props.resources.sort(statusOrder) : []
        if (!auth.uid) return <Redirect to={'/signin'}/>;
        else if (profile.type !== undefined && profile.type !== 2 && profile.type !== 4 && profile.type !== 3) signOut();

        if(resources === undefined || resources.length === 0 || users === undefined) return Loading();


        let closeModal = (success) => {
            if (success) {
                let capacity = this.state[this.state.currentId + '-capacity'];
                let capacityReturn = this.state[this.state.currentId + '-capacityReturn'];
                let loadQuantity = this.state[this.state.currentId + '-loadQuantity'];
                let status = this.state[this.state.currentId + '-status'];         
                let android_id = androidID

                if (capacity === undefined) {
                    for (let index in this.props.resources) {
                        if (this.props.resources[index].id === this.state.currentId) {
                            capacity = this.props.resources[index].capacity;
                            break;
                        }
                    }
                }

                if (capacityReturn === undefined) {
                    for (let index in this.props.resources) {
                        if (this.props.resources[index].id === this.state.currentId) {
                            capacityReturn = this.props.resources[index].capacityReturn;
                            break;
                        }
                    }
                }

                if (loadQuantity === undefined) {
                    for (let index in this.props.resources) {
                        if (this.props.resources[index].id === this.state.currentId) {
                            loadQuantity = this.props.resources[index].loadQuantity;
                            break;
                        }
                    }
                }

                if (status === undefined) {
                    for (let index in this.props.resources) {
                        if (this.props.resources[index].id === this.state.currentId) {
                            status = this.props.resources[index].status;
                            break;
                        }
                    }
                }

                if (android_id === undefined) {
                    for (let index in this.props.resources) {
                        if (this.props.resources[index].id === this.state.currentId) {
                            android_id = this.props.resources[index].androidID;
                            break;
                        }
                    }
                }


                this.setState({
                    androidID: android_id,
                    capacity: parseInt(capacity),
                    capacityReturn: parseInt(capacityReturn),
                    loadQuantity: parseInt(loadQuantity),
                    status: status
                }, () => {
                    this.props.SupervisorActions(this.state, auth.uid);
                });
            }
            androidID = undefined
            this.setState({showModal: false});
        };

        let CustomComponent = ({children, bsStyle, header, ...props}) => {
            return (
              <li className="list-group-item" style={{borderWidth: 0}}>
                <div className={'bg-'+bsStyle} style={{padding: 10, borderRadius: 5}}>
                    <h4 className={'text-'+bsStyle}>{header}</h4>
                    {children}
                </div>
              </li>
            );
          }

        return (
            <Grid>
                <Row>
                    <Col xs={12} md={12} sm={12}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Veículos</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <Row style={{marginTop: '1em', marginLeft:'1em'}}>
                                    <Col xs={12} md={12}
                                    
                                            style={{
                                                backgroundColor: '#dff0d8',
                                                width: '40px',
                                                height: '40px',  
                                            }}
                                    >              
                                    </Col> 
                                    <Col xs={1} md={1}
                                        style={{
                                            color: "green"
                                        }}
                                    >
                                        <h6> PLACA ATIVA</h6>

                                    </Col>
                                    <Col xs={12} md={12}
                                    
                                    style={{
                                        backgroundColor: '#d9edf7',
                                        width: '40px',
                                        height: '40px',  
                                    }}
                                     >              
                                    </Col> 
                                    <Col xs={1} md={1}
                                        style={{
                                            color: 'blue'
                                        }}
                                    >
                                    
                                        <h6> PLACA EM USO</h6>

                                    </Col>
                                    <Col xs={12} md={12}
                                    
                                        style={{
                                            backgroundColor: '#f2dede',
                                            width: '40px',
                                            height: '40px',  
                                        }}
                                     >              
                                    </Col> 
                                    <Col xs={1} md={1}
                                        style={{
                                            color: 'red'
                                        }}
                                    >                                   
                                        <h6> PLACA DESATIVADA</h6>
                                    </Col>                                            
                                </Row>                                 
                                <ListGroup>
                                    {resources && resources.map((resource, key) => {
                                        let user;

                                        let id = resource.id + '-status'
                                        let idCapacity = resource.id + '-capacity'
                                        let idCapacityReturn = resource.id + '-capacityReturn'
                                        let idLoadQuantity = resource.id + '-loadQuantity'

                                        const statusOption = [
                                                                {target:{value:AVAILABLE, id:id }, label:DISPONIVEL}, 
                                                                {target:{value:UNAVAILABLE, id:id}, label:OFFLINE}
                                                             ]
                                        
                                        const capacityOptions = [
                                                                    {target:{value:0, id:idCapacity}, label: 0},
                                                                    {target:{value:1, id:idCapacity}, label: 1},
                                                                    {target:{value:2, id:idCapacity}, label: 2},
                                                                    {target:{value:3, id:idCapacity}, label: 3},
                                                                    {target:{value:4, id:idCapacity}, label: 4},
                                                                    {target:{value:5, id:idCapacity}, label: 5},
                                                                    {target:{value:6, id:idCapacity}, label: 6},
                                                                    {target:{value:7, id:idCapacity}, label: 7},
                                                                    {target:{value:8, id:idCapacity}, label: 8},
                                                                    {target:{value:9, id:idCapacity}, label: 9},
                                                                    {target:{value:10, id:idCapacity}, label: 10}
                                                                ]

                                        const capacityReturnOptions = [
                                                                        {target:{value:0, id:idCapacityReturn}, label: 0},
                                                                        {target:{value:1, id:idCapacityReturn}, label: 1},
                                                                        {target:{value:2, id:idCapacityReturn}, label: 2},
                                                                        {target:{value:3, id:idCapacityReturn}, label: 3},
                                                                        {target:{value:4, id:idCapacityReturn}, label: 4},
                                                                        {target:{value:5, id:idCapacityReturn}, label: 5},
                                                                        {target:{value:6, id:idCapacityReturn}, label: 6},
                                                                        {target:{value:7, id:idCapacityReturn}, label: 7},
                                                                        {target:{value:8, id:idCapacityReturn}, label: 8},
                                                                        {target:{value:9, id:idCapacityReturn}, label: 9},
                                                                        {target:{value:10, id:idCapacityReturn}, label: 10}
                                                                    ]

                                        const loadQuantityOptions = [
                                                                        {target:{value:0, id:idLoadQuantity}, label: 0},
                                                                        {target:{value:1, id:idLoadQuantity}, label: 1},
                                                                        {target:{value:2, id:idLoadQuantity}, label: 2},
                                                                        {target:{value:3, id:idLoadQuantity}, label: 3},
                                                                        {target:{value:4, id:idLoadQuantity}, label: 4},
                                                                        {target:{value:5, id:idLoadQuantity}, label: 5},
                                                                        {target:{value:6, id:idLoadQuantity}, label: 6},
                                                                        {target:{value:7, id:idLoadQuantity}, label: 7},
                                                                        {target:{value:8, id:idLoadQuantity}, label: 8},
                                                                        {target:{value:9, id:idLoadQuantity}, label: 9},
                                                                        {target:{value:10, id:idLoadQuantity}, label: 10}
                                                                    ]

                                        for(let i in users){
                                            if(users[i].id === resource.onlineUser){
                                                user = users[i];
                                                break;
                                            }
                                        }

                                        return (
                                            <CustomComponent 
                                                header={
                                                    resource.onlineUser !== undefined && resource.onlineUser !== '' ?
                                                    resource.plate + ' - [' + user.firstName.toUpperCase() + ' ' + user.lastName.toUpperCase() + ']' :
                                                    resource.plate
                                                }
                                                key={key}
                                                bsStyle={getStatusColour(resource)}>
                                                <Row>
                                                    <Col sm={2} md={2} xs={2}>
                                                        <h5>Capacidade</h5>
                                                        <FormGroup>
                                                            <Select
                                                                options={capacityOptions}
                                                                value={this.state[resource.id + '-capacity']}
                                                                onChange={this.handleChange}
                                                                placeholder={this.state[resource.id + '-capacity'] === undefined ?
                                                                                resource.capacity 
                                                                                : this.state[resource.id + '-capacity']
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={2} md={2} xs={2}>
                                                        <h5>Capacidade retorno </h5>
                                                        <FormGroup>
                                                            <Select
                                                                options={capacityReturnOptions}
                                                                value={this.state[resource.id + '-capacityReturn']}
                                                                onChange={this.handleChange}
                                                                placeholder={this.state[resource.id + '-capacityReturn'] === undefined ?
                                                                                resource.capacityReturn 
                                                                                : this.state[resource.id + '-capacityReturn']
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={2} md={2} xs={2}>
                                                        <h5>Carga Atual </h5>
                                                        <FormGroup>
                                                            <Select
                                                                options={loadQuantityOptions}
                                                                value={this.state[resource.id + '-loadQuantity']}
                                                                onChange={this.handleChange}
                                                                placeholder={this.state[resource.id + '-loadQuantity'] === undefined ?
                                                                                resource.loadQuantity 
                                                                                : this.state[resource.id + '-loadQuantity']
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={2} md={2} xs={2}>
                                                        <h5>Tipo</h5>
                                                        <FormGroup controlId="type">
                                                            <FormControl type="text" disabled={true}
                                                                         value={resource.type === 'hearse' ? 'Carro fúnebre' : 'Carro kit lanche'}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={2} md={2} xs={2}>
                                                        <h5>Status</h5>
                                                        {/* <FormGroup controlId="status"> */}
                                                        <FormGroup>                                                        
                                                            <Select
                                                                options={statusOption}
                                                                value={this.state[resource.id + '-status']}
                                                                onChange={this.handleChange}
                                                                placeholder={this.state[resource.id + '-status'] === 'available'  ? 
                                                                                            DISPONIVEL 
                                                                                            : this.state[resource.id + '-status'] === 'unavailable' ? 
                                                                                                OFFLINE 
                                                                                                : resource.status === 'available' ? 
                                                                                                    DISPONIVEL 
                                                                                                    : OFFLINE}
                                                            >
                                                            </Select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={2} md={2} xs={2}>  
                                                        <h5>AndroidID</h5>
                                                        <FormGroup>
                                                            <FormControl 
                                                                id={resource.id + '-androidID'}
                                                                type="text" 
                                                                placeholder={androidID === undefined ? resource.androidID : androidID}
                                                                value={androidID}    
                                                                onChange={this.onInputChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Button block
                                                        bsStyle={getStatusColour(resource)}
                                                        onClick={() => {
                                                            this.setState({
                                                                showModal: true,
                                                                modalAction: 3,
                                                                currentId: resource.id,
                                                                plate: resource.plate
                                                            })
                                                        }}
                                                > Salvar
                                                    alterações <Glyphicon glyph="check"/></Button>
                                            </CustomComponent>
                                        );
                                    })}
                                </ListGroup>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
                <
                    VehicleModal
                    show={this.state.showModal
                    }
                    onHide={closeModal}
                    action={this.state.modalAction
                    }
                    plate={this.state.plate
                    }
                />
            </Grid>
        )
    }
}


const Loading = (props) => {
	
	const loadingGif = require('../../resources/img/loading-spin.svg')
	return (
		<div className={'loading-container'}>
			<img className={'loading-gif'} src={loadingGif} alt={'loading...'}/>
		</div>
	)
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        resources: state.firestore.ordered['resources'],
        users: state.firestore.ordered['users']
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        SupervisorActions: (allocable,auth) => dispatch(SupervisorActions(allocable,auth))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        {'collection': 'resources'},
        {'collection': 'users'}
    ])
)(Vehicles);
