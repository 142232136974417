import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import {
  XYPlot,
  ArcSeries,
  DiscreteColorLegend
} from 'react-vis'

export default class LastReport extends Component {

  render() {
    const { data } = this.props
    return (
      <>
        <Row style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
          <XYPlot
            xDomain={[-5, 5]}
            yDomain={[-5, 5]}
            width={300}
            height={300}>
            <ArcSeries
              animation
              radiusType={'literal'}
              data={data}
              colorType={'literal'}/>
            
          </XYPlot>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
          <DiscreteColorLegend orientation={'horizontal'} items={data} getTitle={d => d.color}/>
        </Row>
      </>
    )
  }
}
