import React from 'react'
import { FlexibleWidthXYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, LabelSeries } from 'react-vis'
import {Button, Glyphicon } from 'react-bootstrap'
import { tickFormatter } from '../../util'
import './index.css'
import Loading from '../loading'
import * as firebase from "firebase/app"
import "firebase/firestore";
import {saveAs} from "file-saver";

const RED = '#ef5350'
const BLUE = '#42a5f5'  
const GREEN = '#66bb6a'
const YELLOW = '#ffca28'

const state = {
	dataEnd: '',
    dataInit: '',
    total: '',
    supervised: '',
    finished: '',
    dataTotal: [],
    dataSupervised: [],
    dataFinished: []
    
}

export default function SuggestionsChart ({}) {

    
    async function download(){


        const timestampToDate = (date) => {
            console.log(date)
            let year = date.getUTCFullYear().toString()
            let month = date.getMonth()+1
            let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
            return `${day}/${month}/${year}`
          }
        


        var Excel = require('exceljs');
        // A new Excel Work Book
        var workbook = new Excel.Workbook();

        // Some information about the Excel Work Book.
        workbook.creator = 'Matheus Oliveira';
        workbook.lastModifiedBy = '';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();



        // Create a sheet
        var sheet = workbook.addWorksheet('Total');
        // A table header
        sheet.columns = [
            { header: 'Nome', key: 'name' },
            { header: 'OS', key: 'os' },
            { header: 'Data Marcada', key: 'promiseTime' },
            { header: 'Data Criação', key: 'createdAt' },
            
        ]
        // Add rows in the above header
        Object.keys(state.dataTotal).forEach(function(item){
            sheet.addRow({name: state.dataTotal[item].name, os: state.dataTotal[item].os, promiseTime: state.dataTotal[item].promiseTime, createdAt: state.dataTotal[item].createdAt });
        })



        var sheetSupervised = workbook.addWorksheet('Supervisionadas');
        // A table header
        sheetSupervised.columns = [
            { header: 'Nome', key: 'name' },
            { header: 'OS', key: 'os' },
            { header: 'Data Marcada', key: 'promiseTime' },
            { header: 'Data Criação', key: 'createdAt' },
            
        ]
        // Add rows in the above header
        Object.keys(state.dataSupervised).forEach(function(item){
            sheetSupervised.addRow({name: state.dataSupervised[item].name, os: state.dataSupervised[item].os, promiseTime: state.dataSupervised[item].promiseTime, createdAt: state.dataSupervised[item].createdAt });
        })



        var sheetFinished = workbook.addWorksheet('Finalizadas');
        
        // A table header
        sheetFinished.columns = [
            { header: 'Nome', key: 'name' },
            { header: 'OS', key: 'os' },
            { header: 'Data Marcada', key: 'promiseTime' },
            { header: 'Data Criação', key: 'createdAt' },
            
        ]
        // Add rows in the above header
        Object.keys(state.dataFinished).forEach(function(item){
            sheetFinished.addRow({name: state.dataFinished[item].name, os: state.dataFinished[item].os, promiseTime: state.dataFinished[item].promiseTime, createdAt: state.dataFinished[item].createdAt });
        })

        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileExtension = '.xlsx';
        
        const blob = new Blob([buffer], {type: fileType});
        
        if(arrayURL[4] != null){
            var dataURL = String(arrayURL[4]).split('$')
            var dataInit = String(dataURL[0]).split('-')
            var dataEnd = String(dataURL[1]).split('-')
            saveAs(blob, 'Overview_of_OS_'+ dataInit[2] + "/" + (parseInt(dataInit[1])) + "/" + dataInit[0] + "-"+ dataEnd[2] + "/" + (parseInt(dataEnd[1])) + "/" + dataEnd[0] + fileExtension);
        } else {
            let date = new Date()
            saveAs(blob, 'Overview_of_OS_mes_'+ (date.getMonth()+1) + fileExtension);
        }
    }

    var arrayURL = String(window.location).split('/')

    
    let dateFilterInit
    let dataFilterEnd
    let yearInit
    var montInit
    let montEnd

    if(arrayURL[4] != null){
        //configure date in paramenter
        var dataURL = String(arrayURL[4]).split('$')

        dateFilterInit = new Date(dataURL[0])
        dateFilterInit.setDate(dateFilterInit.getDate() + 1)
        dateFilterInit.setHours(0)
        yearInit = dateFilterInit.getFullYear()
        montInit = Number(dateFilterInit.getMonth()+1)
    
        dataFilterEnd = new Date(dataURL[1])
        dataFilterEnd.setDate(dataFilterEnd.getDate() + 1) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        montEnd = dataFilterEnd.getMonth()+1
         
    } else {
        dateFilterInit = new Date()
        dateFilterInit.setDate(1)
        dateFilterInit.setHours(0)
        yearInit = dateFilterInit.getFullYear()
        montInit = Number(dateFilterInit.getMonth()+1)
    
        dataFilterEnd = new Date()
        dataFilterEnd.setDate(dataFilterEnd.getDate() + 1) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        montEnd = dataFilterEnd.getMonth()+1
    } 

    queryTotal(montInit, yearInit, dateFilterInit.getDate(), montEnd, dataFilterEnd.getDate(), dataFilterEnd.getFullYear())
    querySupervised(montInit, yearInit, dateFilterInit.getDate(), montEnd, dataFilterEnd.getDate(), dataFilterEnd.getFullYear())

    let content = <Loading/>
                
    let all = state.total
                
    let supervised = state.supervised
    let finished = state.finished


    let data = [
                    {
                        x: 'TOTAL',
                        y: all,
                        color: YELLOW
                    },{
                        x: 'SUPERVISIONADAS',
                        y: supervised,
                        color: BLUE
                    },{
                        x: 'FINALIZADAS',
                        y: finished,
                        color: GREEN
                    },{
                        x: 'PENDENTES',
                        y: all-finished,
                        color: RED
                    }
        ]
                            
    content = (
                <div>
                    <FlexibleWidthXYPlot
                        height={300 + 202}
                        xType={'ordinal'}
                        margin={{ bottom: 150 }}
                    >
                        <VerticalGridLines/>
                        <HorizontalGridLines/>
                        <XAxis
                            tickLabelAngle={-90}
                            style={{
                                text: {
                                    stroke: 'none',
                                    fill: '#000',
                                    fontWeight: 600,
                                    maxWidth: 2,
                                }
                            }}
                            tickFormat={tickFormatter}
                            position={'start'}
                            className={'XAxis'}
                        />
                        <YAxis/>
                            <VerticalBarSeries
                                data={data}
                                colorType={'literal'}
                            />
                            <LabelSeries
                                data={data.map(obj => ({
                                    ...obj,
                                    label: `${obj.y}`
                                }))}
                                labelAnchorX={'start'}
                            />
                    </FlexibleWidthXYPlot>

                    <Button style={{marginRight: 10}} onClick={download}>
                        Exportar Dados {" "}
                        <Glyphicon glyph={'glyphicon glyphicon-download-alt'}/>
                    </Button>
                </div>
    )
            
    return (content)
}

async function queryTotal (monthInit, yearInit, dateInit, monthEnd, dateEnd, yearEnd) {

    let dateFilterEnd = new Date(yearEnd+"-"+monthEnd+"-"+(dateEnd))
    dateFilterEnd.setHours(23)
    dateFilterEnd.setMinutes(59)
    dateFilterEnd.setSeconds(59)

    let dateFilterInit = new Date(yearInit+"-"+monthInit+"-"+dateInit)
    dateFilterInit.setHours(0)
    dateFilterInit.setMinutes(0)
    dateFilterInit.setSeconds(0)

    //  console.log( "data init ==> ", dateFilterInit)
    //  console.log( "data end ==> ", dateFilterEnd)

    var valueTotal = 0
    let dataTotal = []
    let doc  = await firebase.firestore().collection('historico').where('type', '==', "OS CREATION")
                                                                 .where('data.promiseTime', '<=', dateFilterEnd)
                                                                 .where('data.promiseTime', '>=', dateFilterInit).get()
    .then( snapshot => {
        valueTotal = snapshot.docs.length
        snapshot.forEach(doc => {
            dataTotal.push({
                name: doc.data().data.name,
                os: doc.data().data.serviceOrder,
                promiseTime: new Date(doc.data().data.promiseTime.seconds * 1000).toUTCString(),
                createdAt: new Date(doc.data().data.createdAt.seconds * 1000).toUTCString()
            })
        })
        
    })

    state.total = valueTotal
    state.dataTotal = dataTotal

}


async function querySupervised (monthInit, yearInit, dateInit, monthEnd, dateEnd, yearEnd) {


    let dateFilterEnd = new Date(yearEnd+"-"+monthEnd+"-"+(dateEnd))
    dateFilterEnd.setHours(23)
    dateFilterEnd.setMinutes(59)
    dateFilterEnd.setSeconds(59)

    let dateFilterInit = new Date(yearInit+"-"+monthInit+"-"+dateInit)
    dateFilterInit.setHours(0)
    dateFilterInit.setMinutes(0)
    dateFilterInit.setSeconds(0)

    //  console.log( "data init ==> ", dateFilterInit)
    //  console.log( "data end ==> ", dateFilterEnd)

    var valueSupervised = 0
    var valueFinished = 0
    let dataSupervised = []
    let dataFinished = []
    let doc  = await firebase.firestore().collection('historico').where('type', '==', "UPDATE")
                                                                 .where('data.promiseTime', '<=', dateFilterEnd)
                                                                 .where('data.promiseTime', '>=', dateFilterInit).get()
    .then( snapshot => {
        snapshot.forEach(doc => {
            if(!doc.data().changes.status){
                if(doc.data().data.status !== "finished"){
                    dataSupervised.push({
                        name: doc.data().data.name,
                        os: doc.data().data.serviceOrder,
                        promiseTime: new Date(doc.data().data.promiseTime.seconds * 1000).toUTCString(),
                        createdAt: new Date(doc.data().data.createdAt.seconds * 1000).toUTCString()
                    })
                     valueSupervised++;
                }
            } else if(doc.data().data.status === "finished"){
                dataFinished.push({
                    name: doc.data().data.name,
                    os: doc.data().data.serviceOrder,
                    promiseTime: new Date(doc.data().data.promiseTime.seconds * 1000).toUTCString(),
                    createdAt: new Date(doc.data().data.createdAt.seconds * 1000).toUTCString()
                })
                valueFinished++;
            }

        })
        
    })

    // console.log("valueSupervised ", valueSupervised)
    // console.log("valueFinished ", valueFinished)
    state.supervised = valueSupervised
    state.finished = valueFinished
    state.dataSupervised = dataSupervised
    state.dataFinished = dataFinished

}
