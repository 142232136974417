import React from 'react'
import {signOut} from '../../store/actions/authActions';
import {Nav, NavItem, Badge} from 'react-bootstrap';
import { bootstrapUtils } from 'react-bootstrap/lib/utils';

bootstrapUtils.addStyle(Badge, 'custom');

const AdministratorLinks = () => {
    return(
        <Nav>
            <NavItem eventKey={1} href="/">
                Dashboard
            </NavItem>
            <NavItem eventKey={1} href="/labs">
               Monitorar Remoções
            </NavItem>
            <NavItem eventKey={1} href="/funerals">
                Monitorar Velórios
            </NavItem>
            <NavItem eventKey={1} href="/vehicles">
                Monitorar Veículos
            </NavItem>
            <NavItem eventKey={1} href="/createservice">
                Criar Ordem de Serviço
            </NavItem>
            <NavItem eventKey={1} href="#" onClick={signOut}>
               Sair
            </NavItem>
        </Nav>
    )
};

export default AdministratorLinks;
