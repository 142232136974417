import React, { Component } from "react";
import { 
  Button,
  Glyphicon
} from 'react-bootstrap'
import Select from 'react-select'
import { text } from '../../config/translate'
import VehiclePosition from "./VehiclePosition.js";



export default class Position extends Component{

  
    render() {
      
      return (
        <div>
          <VehiclePosition/>
        </div>
      );
    }
  }
  