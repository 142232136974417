import React from 'react'
import AdministratorLinks from './AdministratorLinks'
import ManagerLinks from './ManagerLinks';
import OperatorLinks from './OperatorLinks';
import SupervisorLinks from './SupervisorLinks';
import {AppVersion} from '../../App'
import {connect} from 'react-redux';
import {Navbar, Image} from 'react-bootstrap';

const NavBar = (props) => {
    const {auth, profile} = props;
    // const links = auth.uid ? profile.type === 1 ? <OperatorLinks profile={profile}/> : profile.type === 2 ?
    //     <SupervisorLinks profile={profile}/> :
    //     profile.type === 3 ? <ManagerLinks profile={profile}/> : profile.type === 4 ?
    //         <AdministratorLinks profile={profile}/> : null : null;
    
    const Links = (props) => {
        if (auth.uid) {
            switch (profile.type) {
                case 1: return <OperatorLinks profile={profile}/>
                case 2: return <SupervisorLinks profile={profile}/>
                case 3: return <ManagerLinks profile={profile}/>
                case 4: return <AdministratorLinks profile={profile} version={AppVersion}/>
                default: break
            }
        }
        return null
    }

    return (
        <Navbar inverse fluid>
            <Navbar.Header>
                <Navbar.Brand>
                    <a data-toggle={'tooltip'} title={'Home'} href="/" className="brand-logo" style={{padding: 1}}>
                        <Image src={require('./assets/logobw.png')} style={{height: '100%'}}/>
                    </a>
                </Navbar.Brand>
                <Navbar.Toggle/>
            </Navbar.Header>
            <Navbar.Collapse>
                <Links/>
            </Navbar.Collapse>
        </Navbar>
    )
};

const mapStateToPros = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
};

export default connect(mapStateToPros)(NavBar);
