import React, { Component } from 'react'
import Collection from './models/collection'
import Solution from './models/solution'
import Row from './row'
import { text } from '../../config/translate'
import { Glyphicon, Button, Modal } from 'react-bootstrap'
import Select from 'react-select'

const LABORATORY = 'laboratory'
const DEPOSIT = 'deposit'

const capitalize = string => {
    return string.charAt(0).toLowerCase() + string.slice(1).toLowerCase()
}

export default class Table extends Component {

    state = {
        show: false
    }

    showModalHandler = () => {
        this.setState(state => ({show: !state.show}))
    }
    render() {
        const { collection, columns, orderBy, order, from, to,  status, type, filter, setFilter, clearFilter } = this.props
        const TableContent = ({data, update, onChange, reset, ...rest}) => {
            if (Array.isArray(data)) {
                return data.filter((record,key) => record.type !==LABORATORY && record.type !== DEPOSIT).map((record, key) => {
                    const { id, type, _unsaved } = record
                    return (
                        <React.Fragment key={key}>
                            <Row
                                key={key}
                                columns={columns}
                                data={record}
                                update={(originalData) => update(id, collection, originalData)}
                                onChange={change => onChange(record.id, change)}
                                hasUnsavedChanges={_unsaved}
                                capitalize={capitalize}
                                reset={reset}
                                type={type}
                                {...rest}
                            />
                        </React.Fragment>
                    )
                })
            }
        }

        const handleClearFilter = () => {
            clearFilter()
        }
        
        const thStyle = (column) => {
            switch (column) {
                case 'status':
                case 'plate' :
                    return {verticalAlign: 'middle', textAlign: 'center'}
                default:
                    return {verticalAlign: 'middle'}
            }
        }
        
        return (
            <>
                <Modal show={this.state.show} onHide={this.showModalHandler}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='panel panel-default'>
                            <div className="panel-heading">
                                <div className={'panel-title pull-left'}>
                                    FILTROS
                                </div>
                                <div className={'panel-title pull-right'}>
                                    <Button onClick={handleClearFilter}>
                                        <Glyphicon glyph={'glyphicon glyphicon-erase'}/>
                                    </Button>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="panel-body">
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    {/* <div>
                                        <label>DE</label>
                                        <DateTime
                                            locale={'pt-br'}
                                            ref={'datetime'}
                                            defaultValue={from}
                                            onChange={() => console.log('CHANGED')}
                                            inputProps={{style:{width: 150, height: 35}}}
                                        />
                                    </div>
                                    <div style={{marginLeft: 30}}>
                                        <label>ATE</label>
                                        <DateTime
                                            locale={'pt-br'}
                                            ref={'datetime'}
                                            defaultValue={to}
                                            onChange={() => console.log('CHANGED')}
                                            inputProps={{style:{width: 150, height: 35}}}
                                        />
                                    </div> */}
                                    <div style={{marginLeft: 30, width: 300}}>
                                        <label>STATUS</label>
                                        <Select
                                            isMulti
                                            options={status}
                                            onChange={(e) => setFilter('status', e)}
                                            defaultValue={filter['status']}
                                            value={filter['status'] ? filter['status'] : null}
                                        />
                                    </div>
                                    <div style={{marginLeft: 30, width: 300}}>
                                        <label>TYPES</label>
                                        <Select
                                            isMulti
                                            options={type}
                                            onChange={(e) => setFilter('type', e)}
                                            defaultValue={filter['type']}
                                            value={filter['type'] ? filter['type'] : null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className='panel panel-default' style={{margin: 15}}>
                    <div className="panel-heading">
                        <div className={'panel-title pull-left'}>
                            {Array.isArray(collection) ? 'COLLECTION' : text(collection)}
                        </div>
                        <div className={'panel-title pull-right'}>
                            <Button onClick={this.showModalHandler}>
                                <Glyphicon glyph={'glyphicon glyphicon-filter'}/>
                            </Button>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="panel-body">
                        <div>
                            <table className={'table table-striped'}>
                                <thead>
                                    <tr>
                                        {
                                            columns.map((column, key) => {
                                                let { name } = column
                                                return (
                                                    <th key={key} style={thStyle(name)}>
                                                        <span className={'lead'}>{text(name)}</span>
                                                    </th>
                                                )
                                            })
                                        }
                                        <th>
                                            <span className={'lead'} ></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {   
                                        collection === 'solution' ? 
                                            <Solution filter={filter} collection={collection} orderBy={orderBy} order={order} from={from} to={to}>
                                                {TableContent}
                                            </Solution> :
                                            <Collection collection={collection} orderBy={orderBy} order={order}>
                                                {TableContent}
                                            </Collection>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="panel-footer">
                        
                    </div>
                </div>
        
            </>
        )
    }
}