import React from 'react';
import '../styles/index.css';

const Chart = props => {


    return (
        <div className="chart-wrapper">
            <div className="chart-container">
            Chart
            </div>
        </div>
    )
}

export default Chart;
