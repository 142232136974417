import React, {Component} from 'react';
import {
    Button,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Panel,
    Well,
    Popover,
    InputGroup,
    OverlayTrigger,
    Form
} from "react-bootstrap";
import DateTime from "react-datetime";
import MaskedFormControl from "react-bootstrap-maskedinput";

class BodyForm extends Component {
    state = {
        phone: ''
    };

    handleClickAdd = () => {
        if (!this.props.burialContact.includes(this.state.phone))
            this.props.burialContact.push(this.state.phone);

        this.setState({phone: ''});
    };

    handleClickRemove = () => {
        this.props.burialContact.splice(this.props.burialContact.indexOf(this.state.phone), 1);
        this.setState({phone: ''});
    };

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value})

    };

    render() {
        const popoverPlus = (
            <Popover id="popover-trigger-click" title="Adicionar telefone">
                <FormGroup>
                    <InputGroup>
                        <MaskedFormControl className="text" placeholder="Telefone"
                                           id="phone" onChange={this.handleChange}
                                           value={this.state.phone}
                                           mask='(11) 1 1111-1111' />
                        <InputGroup.Button>
                            <Button id={'add'} bsStyle={'primary'} onClick={this.handleClickAdd}>
                                <Glyphicon glyph="ok"/>
                            </Button>
                        </InputGroup.Button>
                    </InputGroup>
                </FormGroup>
            </Popover>
        );

        const popoverMinus = (
            <Popover id="popover-trigger-click" title="Remover telefone">
                <FormGroup>
                    <InputGroup>
                        <MaskedFormControl className="text" placeholder="Telefone"
                                           id="phone" onChange={this.handleChange}
                                           value={this.state.phone}
                                           mask='(11) 1 1111-1111' />
                        <InputGroup.Button>
                            <Button id={'remove'} bsStyle={'primary'} onClick={this.handleClickRemove}>
                                <Glyphicon glyph="remove"/>
                            </Button>
                        </InputGroup.Button>
                    </InputGroup>
                </FormGroup>
            </Popover>
        );
        return (
            <Panel defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>Cortejo</Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <Form>

                            <FormGroup>
                                <ControlLabel>Descrição</ControlLabel>
                                <FormControl componentClass="textarea" placeholder="Descrição"
                                             id="burialDescription" onChange={this.props.handleChange}
                                             value={this.props.burialDescription}/>
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>Telefones para contato</ControlLabel>
                                <InputGroup>
                                    <InputGroup.Button>
                                        <OverlayTrigger trigger="click" placement="right" overlay={popoverPlus}
                                                        rootClose>
                                            <Button>
                                                <Glyphicon glyph="plus"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Button>
                                    <FormControl className="text" placeholder="Telefones"
                                                 id="burialContact"
                                                 disabled={true}
                                                 value={this.props.burialContact}/>
                                    <InputGroup.Button>
                                        <OverlayTrigger trigger="click" placement="left" overlay={popoverMinus}
                                                        rootClose>
                                            <Button>
                                                <Glyphicon glyph="minus"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Button>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup validationState={this.props.getValidationDate(this.props.burialPromiseTime)}>
                                <ControlLabel>Horário do cortejo</ControlLabel>
                                <DateTime inputProps={{placeholder: 'Hora'}} locale={'pt-br'}
                                          onChange={this.props.handleDateChangeBurial}
                                          value={this.props.burialPromiseTime}
                                          disabled={true}/>
                            </FormGroup>
                            <FormGroup validationState={this.props.getValidationState(this.props.burialOriginAddress)}>
                                <ControlLabel>Endereço de origem</ControlLabel>
                                <FormControl type="text" placeholder="Endereço"
                                             id={"burialOriginAddress"}
                                             onChange={this.props.handleChangeAddress}
                                             value={this.props.burialOriginAddress}
                                />
                            </FormGroup>

                            {this.props.burialOriginSuggestions && this.props.burialOriginSuggestions.map(suggestion => {
                                return (
                                    <Well key={'burialOriginSuggestions,' + this.props.burialOriginSuggestions.indexOf(suggestion)}
                                          id={'burialOriginSuggestions,' + this.props.burialOriginSuggestions.indexOf(suggestion)}
                                          onClick={this.props.suggestionClick}>{suggestion.label}</Well>
                                )
                            })}

                            <FormGroup validationState={this.props.getValidationStateCoordinates(this.props.burialOriginCoordinates.lat)}>
                                <ControlLabel>Coordenadas origem</ControlLabel>
                                <FormControl type="text" placeholder="Lat"
                                             id="lat" value={this.props.burialOriginCoordinates.lat}
                                             disabled={true}/>

                            </FormGroup>
                            <FormGroup validationState={this.props.getValidationStateCoordinates(this.props.burialOriginCoordinates.lng)}>
                                <FormControl type="text" placeholder="Lon"
                                             id="lon" value={this.props.burialOriginCoordinates.lng}
                                             disabled={true}/>
                            </FormGroup>

                            <FormGroup validationState={this.props.getValidationState(this.props.burialDestinationAddress)}>
                                <ControlLabel>Endereço de destino</ControlLabel>
                                <FormControl type="text" placeholder="Endereço"
                                             id={"burialDestinationAddress"}
                                             onChange={this.props.handleChangeAddress}
                                             value={this.props.burialDestinationAddress}
                                />
                            </FormGroup>

                            {this.props.burialDestinationSuggestions && this.props.burialDestinationSuggestions.map(suggestion => {
                                return (
                                    <Well key={'burialDestinationSuggestions,' + this.props.burialDestinationSuggestions.indexOf(suggestion)}
                                          id={'burialDestinationSuggestions,' + this.props.burialDestinationSuggestions.indexOf(suggestion)}
                                          onClick={this.props.suggestionClick}>{suggestion.label}</Well>
                                )
                            })}

                            <FormGroup validationState={this.props.getValidationStateCoordinates(this.props.burialDestinationCoordinates.lat)}>
                                <ControlLabel>Coordenadas destino</ControlLabel>
                                <FormControl type="text" placeholder="Lat"
                                             id="lat" value={this.props.burialDestinationCoordinates.lat}
                                             disabled={true}/>

                            </FormGroup>
                            <FormGroup validationState={this.props.getValidationStateCoordinates(this.props.burialDestinationCoordinates.lng)}>
                                <FormControl type="text" placeholder="Lon"
                                             id="lon" value={this.props.burialDestinationCoordinates.lng}
                                             disabled={true}/>
                            </FormGroup>

                            {/* <FormGroup>
                            <ControlLabel>Motorista responsável</ControlLabel>
                            <Radio id={'burialDefinedAuto'} name={'burialDefined'} onChange={this.props.handleChange}
                                   checked={!this.props.burialDefinedManual}>Automático</Radio>
                            <Radio id={'burialDefinedManual'} name={'burialDefined'} onChange={this.props.handleChange}
                                   checked={this.props.burialDefinedManual}>Manual</Radio>
                            {this.props.burialDefinedManual && this.props.burialDefinedManual === true ?
                                <FormControl componentClass={'select'} id={'burialDefinedResource'}
                                             onChange={this.props.handleChange} value={this.props.burialDefinedResource}>
                                    {this.props.resources && this.props.resources.map( resource => {
                                        return(<option key={resource.id}>
                                            {resource.id}</option>);
                                    })}
                                </FormControl> : null}

                        </FormGroup> */}
                        </Form>
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }
}

export default BodyForm;

