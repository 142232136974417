import * as firebase from "firebase/app"
import "firebase/firestore";

const BASE = {
  count: 0, daily: {}, records: []
}

export default class Warehouse {

  static get supervisor () {
    return Supervisor
  }

  static get supervised () {
    return Supervised
  }

  static get resource () {
    return Resource
  }

  static get ref () {
    return this._ref
  }

  static set ref (ref) {
    this._ref = ref
  }

  static async update (data: Object, doc: String) {
    return await this.ref.doc(doc).update(data)
  }

  static async get (doc: String) {
    const document = await this.ref.doc(doc).get()
    return Object.assign({}, document.data(), {
      id: document.id,
      year: Number(String(document.id).slice(2,6)),
      month: Number(String(document.id).slice(0,2))
    })
  }

  static async all (year: Number, month: Number) {
    const documents = await this.ref.where('month', '>', month).where('year', '>', year).get()
    
    let data = []
    documents.forEach(doc => {
      const append = {
        id: doc.id,
        ...doc.data()
      }
      data.push(append)
    })
    
    return data.map(record => {
      let { daily, records, ...registry } = record
      return ({
        ...registry,
        daily: this.formatDailyData(daily, registry.year, registry.month),
        records: this.formatRecordData(records)
      })
    })
  }

  static formatRecordData = (records: Array) => {
    if (!records) return []
    return records.map(record => {
      let { timestamp: { seconds }, resource } = record
      let date = new Date(seconds*1000)
      return ({
        date,
        resource
      })
    })
  }

  static formatDailyData = (data: Object, year: Number, month: Number) => {
    let dataFormated = []
    for (const day in data) {
      if (data.hasOwnProperty(day))
        dataFormated.push({day: this.formatDate({day, month, year}), count: data[day]})
    }
    return dataFormated
  }

  static formatDate = ({day, month, year}) => {
    if (!day) return `${month}/${year}` 
    return `${day}/${month}/${year}`
  }

  static formatToVector = (dataset: Array, x, y) => {
    if (!dataset) return []
    return dataset.map(data => {
      let X, Y

      if (Array.isArray(x)) {
        let value = x.map(field => data[field] instanceof Date ? this.formatDate(this.dateStamp(data[field])) : data[field])
        X = value.join('/')
      } else {
        X = data[x] instanceof Date ? this.formatDate(this.dateStamp(data[x])) : data[x]
      }
      
      if (Array.isArray(y)) {
        let value = y.map(field => data[field] instanceof Date ? this.formatDate(this.dateStamp(data[field])) : data[field])
        Y = value.join('/')
      } else {
        Y = data[y] instanceof Date ? this.formatDate(this.dateStamp(data[y])) : data[y]
      }
      
      return ({
        x: X,
        y: Y
      })
    })
  }

  /**
   * 
   * @param {String} resource Resource's id
   */
  static async add (resource: String) {
    const { day, month, year } = this.dateStamp()
    const data = {
      count: firebase.firestore.FieldValue.increment(1),
      [`daily.${day}`]: firebase.firestore.FieldValue.increment(1),
      records: firebase.firestore.FieldValue.arrayUnion({resource: resource.toUpperCase(), timestamp: this.getTimestamp()})
    }
    try {
      await this.update(data, `${month}${year}`)
    } catch (error) {
      if (error.code === 'not-found') {
        await this.ref.doc(`${month}${year}`).set({year: Number(year), month: Number(month), ...BASE}, {merge: true})
        await this.add(resource)
      }
    }
  }

  static async test (...args) {
    this.getFromRange(...args)
  }

  static async getFromRange (from: Object, to: Object) {
    let month, year, monthly, data = [], daily = [], records = []
    do {
      year = `${from.date.getFullYear()}`
      month = from.date.getMonth()+1 < 10 ? `${0}${from.date.getMonth()+1}` : `${from.date.getMonth()+1}`
      let document = await this.ref.doc(`${month}${year}`).get()
      let documentData = await document.data()
      if (documentData) data.push({id: document.id, ...documentData})
      from.date.setMonth(from.date.getMonth() + 1)
    } while (from.date <= to.date)

    monthly = this.formatToVector(data, ['month', 'year'], 'count')

    data.forEach(data => {
      daily.push(this.formatToVector(this.formatDailyData(data.daily, data.year, data.month), 'day', 'count'))
      records.push(this.formatToVector(data.records, 'date', 'resource'))
    })
    
    const response = {
      monthly: monthly.flat().reverse(),
      daily: daily.flat().reverse(),
      records: this.formatRecords(this.groupBy(records.flat().reverse(), 'y'))
    }
    return response
  }

  static groupBy = (set, key) => {
    return (
      set.reduce((accumulator, value) => {
        return ({
          ...accumulator,
          [value[key]]: [...accumulator[value[key]] || [], value]
        })
      }, {})
    )
  }

  static formatRecords(records: Object) {
    return Object.keys(records).map(key => {
      return ({
        x: key,
        y: records[key].length
      })
    })
  }

  static async getMonthlyCount(limit: Number = 3) {
    const { month, year } = this.dateStamp(new Date())
    let data = []
    for (let index = 0; index < limit; index++) {
      const _data = await this.get(`${this.fixDateZero(month-index)}${year}`)
      data.push({..._data, month: this.formatDate({month: data.month, year: data.year})})
    }
    return this.formatToVector(data, 'month', 'count')
  }

  static async total () {
    const { count } = this.getData()
    return count
  }

  static async getData () {
    const { month, year } = this.dateStamp()
    const doc = await this.ref.doc(`${month}${year}`).get()
    return doc.data()
  }

  static dateStamp (date: Date = new Date()) {
    const day = this.fixDateZero(date.getDate())
    const month = this.fixDateZero(date.getMonth()+1)
    const year = `${date.getFullYear()}`
    return ({
      year, month, day
    })
  }

  static getTimestamp (date: Date = new Date()) {
    return firebase.firestore.Timestamp.fromDate(date)
  }

  static fixDateZero (_number) {
    const number = Number(_number)
    if (isNaN(number)) return false
    return number < 10 ? `0${number}` : `${number}`
  }

}

class Supervisor extends Warehouse{

  static get ref () {
    return (
      firebase
      .firestore()
      .collection('warehouse')
      .doc('registry')
      .collection('supervisor')
    )
  }

}

class Supervised extends Warehouse{

  static get ref () {
    return (
      firebase
      .firestore()
      .collection('warehouse')
      .doc('registry')
      .collection('supervised')
    )
  }

}

class Resource extends Warehouse {

  static get ref () {
    return (
      firebase
      .firestore()
      .collection('warehouse')
      .doc('registry')
      .collection('resource')
    )
  }

}
