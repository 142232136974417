import React, {Component} from 'react';
import {Modal, Button, Glyphicon} from 'react-bootstrap';

class LabModal extends Component {

    render() {
        let message;
        switch (this.props.action) {
            case 0:
                message = 'Tem certeza que deseja subtrair 30 minutos do tempo estimado de conclusão da tarefa?';
                break;
            case 1:
                message = 'Tem certeza que deseja somar 30 minutos ao tempo estimado de conclusão da tarefa?';
                break;
            case 2:
                message = 'Tem certeza que deseja marcar a tarefa como concluida? ESTA AÇÃO NÃO PODE SER DESFEITA!!!';
                break;
            case 4:
                message = 'Tem certeza que deseja marcar as tarefas selecionadas como concluida? ESTA AÇÃO NÃO PODE SER DESFEITA!!!';
            default: break
        }
        return (
            <Modal
                {...this.props}
                bsSize="small"
                aria-labelledby="contained-modal-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-lg">ATENÇÃO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <strong>{message}</strong>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle={'danger'} onClick={() => this.props.onHide(false)}><Glyphicon glyph={'remove'}></Glyphicon></Button>
                    <Button bsStyle={'success'} onClick={() => {this.props.onHide(true)
                    }
                    }><Glyphicon glyph={'ok'}></Glyphicon></Button>
                </Modal.Footer>
            </Modal>
            
        );
    }
}

export default LabModal;
