import React, {Component} from 'react';
import {FormControl, FormGroup, Panel} from "react-bootstrap";

class OSForm extends Component {
    render() {
        return (
            <Panel>
                <Panel.Heading>Ordem de Serviço</Panel.Heading>
                <Panel.Body>
                    <form onSubmit={this.props.handleSubmit}>
                        <FormGroup validationState={this.props.getValidationState(this.props.serviceOrder)}>
                            <FormControl type="text" placeholder="Ordem de serviço"
                                         id="serviceOrder" value={this.props.serviceOrder}
                                         onChange={this.props.handleChange}
                                         required={true}/>
                        </FormGroup>
                    </form>
                    <form onSubmit={this.props.handleSubmit}>
                        <FormGroup validationState={this.props.getValidationState(this.props.name)}>
                            <FormControl type="text" placeholder="Nome"
                                         id="name" value={this.props.name}
                                         onChange={this.props.handleChange}
                                         required={true}/>
                        </FormGroup>
                    </form>
                </Panel.Body>
            </Panel>
        )
    }
}

export default OSForm;

