import React, {Component} from 'react';
import {
    Button,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    InputGroup, OverlayTrigger,
    Panel,
    Popover,
    Well,
    Form
} from "react-bootstrap";
import DateTime from "react-datetime";
import MaskedFormControl from "react-bootstrap-maskedinput";

class MealForm extends Component {
    state = {
        phone: ''
    };

    handleClickAdd = () => {
        if (!this.props.mealContact.includes(this.state.phone))
            this.props.mealContact.push(this.state.phone);

        this.setState({phone: ''});
    };

    handleClickRemove = () => {
        this.props.mealContact.splice(this.props.mealContact.indexOf(this.state.phone), 1);
        this.setState({phone: ''});
    };

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value})

    };

    render() {
        const popoverPlus = (
            <Popover id="popover-trigger-click" title="Adicionar telefone">
                <FormGroup>
                    <InputGroup>
                        <MaskedFormControl className="text" placeholder="Telefone"
                                           id="phone" onChange={this.handleChange}
                                           value={this.state.phone}
                                           mask='(11) 1 1111-1111'/>
                        <InputGroup.Button>
                            <Button id={'add'} bsStyle={'primary'} onClick={this.handleClickAdd}>
                                <Glyphicon glyph="ok"/>
                            </Button>
                        </InputGroup.Button>
                    </InputGroup>
                </FormGroup>
            </Popover>
        );

        const popoverMinus = (
            <Popover id="popover-trigger-click" title="Remover telefone">
                <FormGroup>
                    <InputGroup>
                        <MaskedFormControl className="text" placeholder="Telefone"
                                           id="phone" onChange={this.handleChange}
                                           value={this.state.phone}
                                           mask='(11) 1 1111-1111'/>
                        <InputGroup.Button>
                            <Button id={'remove'} bsStyle={'primary'} onClick={this.handleClickRemove}>
                                <Glyphicon glyph="remove"/>
                            </Button>
                        </InputGroup.Button>
                    </InputGroup>
                </FormGroup>
            </Popover>
        );
        return (
            <Panel defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>Kit Lanche</Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <Form>
                            <FormGroup>
                                <ControlLabel>Descrição</ControlLabel>
                                <FormControl componentClass="textarea" placeholder="Descrição"
                                             id="mealDescription" onChange={this.props.handleChange}
                                             value={this.props.mealDescription}/>
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>Telefones para contato</ControlLabel>
                                <InputGroup>
                                    <InputGroup.Button>
                                        <OverlayTrigger trigger="click" placement="right" overlay={popoverPlus}
                                                        rootClose>
                                            <Button>
                                                <Glyphicon glyph="plus"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Button>
                                    <FormControl className="text" placeholder="Telefones"
                                                 id="mealContact"
                                                 disabled={true}
                                                 value={this.props.mealContact}/>
                                    <InputGroup.Button>
                                        <OverlayTrigger trigger="click" placement="left" overlay={popoverMinus}
                                                        rootClose>
                                            <Button>
                                                <Glyphicon glyph="minus"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Button>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup validationState={this.props.getValidationDate(this.props.mealPromiseTime)}>
                                <ControlLabel>Horário da entrega do kit lanche</ControlLabel>
                                <DateTime inputProps={{placeholder: 'Hora'}} locale={'pt-br'}
                                          onChange={this.props.handleDateChangeMeal}
                                          value={this.props.mealPromiseTime}
                                          disabled={true}/>
                            </FormGroup>
                            <FormGroup validationState={this.props.getValidationState(this.props.mealAddress)}>
                                <ControlLabel>Endereço do velório</ControlLabel>
                                <FormControl type="text" placeholder="Endereço"
                                             id={"mealAddress"}
                                             onChange={this.props.handleChangeAddress}
                                             value={this.props.mealAddress}
                                />
                            </FormGroup>

                            {this.props.mealSuggestions && this.props.mealSuggestions.map(suggestion => {
                                return (
                                    <Well key={'mealSuggestions,' + this.props.mealSuggestions.indexOf(suggestion)}
                                          id={'mealSuggestions,' + this.props.mealSuggestions.indexOf(suggestion)}
                                          onClick={this.props.suggestionClick}>{suggestion.label}</Well>
                                )
                            })}

                            <FormGroup
                                validationState={this.props.getValidationStateCoordinates(this.props.mealCoordinates.lat)}>
                                <ControlLabel>Coordenadas</ControlLabel>
                                <FormControl type="text" placeholder="Lat"
                                             id="lat" value={this.props.mealCoordinates.lat}
                                             disabled={true}/>

                            </FormGroup>
                            <FormGroup
                                validationState={this.props.getValidationStateCoordinates(this.props.mealCoordinates.lng)}>
                                <FormControl type="text" placeholder="Lon"
                                             id="lon" value={this.props.mealCoordinates.lng}
                                             disabled={true}/>
                            </FormGroup>
                            {/* <FormGroup>
                                <ControlLabel>Motorista responsável</ControlLabel>
                                <Radio id={'mealDefinedAuto'} name={'mealDefined'} onChange={this.props.handleChange}
                                       checked={!this.props.mealDefinedManual}>Automático</Radio>
                                <Radio id={'mealDefinedManual'} name={'mealDefined'} onChange={this.props.handleChange}
                                       checked={this.props.mealDefinedManual}>Manual</Radio>
                                {this.props.mealDefinedManual && this.props.mealDefinedManual === true ?
                                    <FormControl componentClass={'select'} id={'mealDefinedResource'}
                                                 onChange={this.props.handleChange} value={this.props.mealDefinedResource}>
                                        {this.props.resources && this.props.resources.map( resource => {
                                            return(<option key={resource.id}>
                                                {resource.id}</option>);
                                        })}
                                    </FormControl> : null}

                            </FormGroup> */}
                        </Form>
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }
}

export default MealForm;

