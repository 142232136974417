import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'

export default class Ignition extends Component {
	render() {
		const {
			data: { on },
			data: { off },
			size
		} = this.props

		const styles = {
			container: {
				display: 'flex',
				justifyContent: 'center',
				width: '100%'
			},
			text: {
				fontSize: size || 28,
				borderWidth: 10,
				fontWeight: 600,
				margin: 'auto'
			},
			col: {
				margin: 5,
				width: 200,
				display: 'flex',
				justifyContent: 'space-around',
				borderRadius: 5
			},
			on: {
				backgroundColor: '#66bb6a',
				textAlign: 'right'
			},
			off: {
				backgroundColor: '#ef5350',
				textAlign: 'left' 
			},
			label: {
				margin: 10,
				color: 'rgba(0,0,0,0.5)'
			}
		}

		return (
			<>
				<Row style={styles.container}>
					<Col style={{...styles.col, ...styles.on}}>
						<label style={styles.label}>ON</label>
						<p style={styles.text}>{on}</p>
					</Col>
					<Col style={{...styles.col, ...styles.off}}>
						<p style={styles.text}>{off}</p>
						<label style={styles.label}>OFF</label>
					</Col>
				</Row>
			</>
		)
	}
}


