import React from 'react';
import '../styles/index.css';

const Orders = props => {


    return (
        <div className="orders-wrapper">
            <div className="orders-container">
                <OrdersTable/>
            </div>
        </div>
    )
}

export default Orders;

const OrdersTable = props => {
    
    return (
        <div className="orders-table-container">
            <div className="orders-table-header">
                <div className="orders-table-column-name">
                    Service Order
                </div>
                <div className="orders-table-column-name">
                    Name
                </div>
                <div className="orders-table-column-name">
                    Progress
                </div>
                <div className="orders-table-column-name">
                    Status
                </div>
            </div>
            <div className="orders-table-body">
                {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map( (key) => (
                        <div className="orders-table-row" key={key}>
                            <div className="orders-table-data">
                                0123456789
                            </div>
                            <div className="orders-table-data">
                                Tâmara Tamarindo
                            </div>
                            <div className="orders-table-data">
                                Funeral Return
                            </div>
                            <div className="orders-table-data">
                                On Time
                            </div>
                            <div className="orders-table-data">
                                <AllocableAlert/>
                            </div>
                            <div className="orders-table-data">
                                <AllocableProgressIcon/>
                            </div>
                            <div className="orders-table-data">
                                <AllocableProgressIcon/>
                            </div>
                            <div className="orders-table-data">
                                <AllocableProgressIcon/>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const AllocableProgressIcon = props => {

    return (
        <div className="orders-icon-container">
            <div className="icon">
                <img src="/img/003-hearse.svg" alt="icon"/>
            </div>
            <div className="status"></div>
        </div>
    )
}

const AllocableAlert = props => {

    return (
        <div className="orders-alert-container">
            <div className="icon">
                <img src="/img/010-notification-1.svg" alt=""/>
            </div>
        </div>
    )
}
