import React from 'react';
import '../styles/index.css';

const Monitor = props => {


    return (
        <div className="monitor-wrapper">
            <div className="monitor-container">

                <div className="monitor-title">
                    <p>MONITOR</p>
                </div>

                <div className="monitor-content">
                    {
                        [1, 2, 3, 4, 5, 6].map( key => {
                            return (
                                <div key={key}>
                                    <div className="container-divisor"></div>
                                    <div className="monitor-item">
                                        
                                        <div className="title">
                                            <div className="icon">
                                                <img src="/img/032-hearse-1.svg" alt=""/>
                                            </div>
                                            <p>TITLE</p>
                                        </div>
                                        <div className="status-container">
                                            {
                                                [1, 2, 3, 4].map( key => {
                                                    return (
                                                        <div key={key} className="status">
                                                            <div className="icon">
                                                                <p>
                                                                    X
                                                                </p>
                                                            </div>
                                                            <p>
                                                                MEANING
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default Monitor;
