import React, {Component} from 'react';
import 'react-table/react-table.css'
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {firestoreConnect} from "react-redux-firebase";
import {Redirect} from "react-router-dom";
import {signOut} from "../../store/actions/authActions";
import {
    Panel,
    Grid,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    ProgressBar,
    ButtonGroup,
    Button,
    Glyphicon
} from 'react-bootstrap';
import LabModal from './LabModal';
import {SupervisorActions} from "../../store/actions/allocableActions";

class LabAllocables extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            modalAction: 0,
            currentId: '',
            defaultEstimatedDuration: 180
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.forceUpdate(), 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {auth, profile, signOut, allocables} = this.props;

        if (!auth.uid) return <Redirect to={'/signin'}/>;
        else if (profile.type !== undefined && profile.type !== 3 && profile.type !== 4) signOut();

        if (allocables === undefined || allocables.length === 2) return null;

        let closeModal = (success) => {
            if (success) {
                this.props.SupervisorActions(this.state, auth.uid);
            }

            this.setState({showModal: false});
        };

        let solutionKitchens = [];
        for (let index in allocables) {
            if (allocables[index].type === 'meal' && allocables[index].status !== 'finsihed' && allocables[index].status !== 'new') {
                let allocable = allocables[index];

                solutionKitchens.push({
                    serviceOrder: allocable.serviceOrder,
                    name: allocable.name,
                    estimatedDuration: allocable.estimatedDuration,
                    arrivalTime: allocable.startedTime,
                    id: allocable.id
                });
            }
        }


        return (
            <Grid>
                <Row>
                    <Col xs={12} md={12} sm={12}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Kit Lanches</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <ListGroup>
                                    {solutionKitchens && solutionKitchens.map(solutionKitchen => {
                                        let perc = ((new Date(((new Date()).getTime()) - solutionKitchen.arrivalTime.toDate().getTime() - this.state.defaultEstimatedDuration * 60000).getTime()) /
                                            (new Date((solutionKitchen.arrivalTime.toDate().getTime() + (solutionKitchen.estimatedDuration * 60000 - this.state.defaultEstimatedDuration * 60000)) -
                                                (((new Date()).getTime()) - solutionKitchen.arrivalTime.toDate().getTime() - solutionKitchen.estimatedDuration * 60000))).getTime()) * 100;

                                        perc = Number((perc).toFixed(0));

                                        if (perc < 0) perc = 0;
                                        else if (perc > 100) perc = 100;

                                        let color = undefined;

                                        if (perc === 0)
                                            color = undefined;
                                        else if (perc < 50)
                                            color = 'success';
                                        else if (perc < 75)
                                            color = 'warning';
                                        else
                                            color = 'danger';

                                        return (
                                            <ListGroupItem
                                                header={
                                                    <div>
                                                        <div className={'monitorHeader block'}>
                                                            <div className={'monitorHeader osInfo'}>
                                                                <h4 className={'monitorHeader mHlabel'}>Nome: </h4>
                                                                <h4 className={'monitorHeader value'}>{solutionKitchen.name}</h4>
                                                            </div>
                                                            <div className={'monitorHeader osInfo'}>
                                                                <h4 className={'monitorHeader mHlabel'}>OS: </h4>
                                                                <h4 className={'monitorHeader value'}>{solutionKitchen.serviceOrder}</h4>
                                                            </div>
                                                        </div>
                                                        <div className={'monitorHeader block right'}>
                                                            <div className={'monitorHeader osInfo'}>
                                                                <h4 className={'monitorHeader mHlabel'}>Início: </h4>
                                                                <h4 className={'monitorHeader value'}>{solutionKitchen.arrivalTime.toDate().toLocaleString('pt-Br')}</h4>
                                                            </div>
                                                            <div className={'monitorHeader osInfo'}>
                                                                <h4 className={'monitorHeader mHlabel'}>Fim: </h4>
                                                                <h4 className={'monitorHeader value'}>{(new Date(solutionKitchen.arrivalTime.toDate().getTime() + solutionKitchen.estimatedDuration * 60000)).toLocaleString('pt-Br')}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                bsStyle={color} href="#">
                                                <br/><ProgressBar bsStyle={color} now={perc} label={perc + '%'}/>
                                                <ButtonGroup justified>
                                                    <ButtonGroup>
                                                        <Button bsStyle={'primary f'} onClick={() => this.setState({
                                                            showModal: true,
                                                            modalAction: 0,
                                                            currentId: solutionKitchen.id
                                                        })}> <Glyphicon glyph="minus"/> 30 min</Button>
                                                    </ButtonGroup>
                                                    <ButtonGroup>
                                                        <Button bsStyle={'danger'} onClick={() => this.setState({
                                                            showModal: true,
                                                            modalAction: 1,
                                                            currentId: solutionKitchen.id
                                                        })}> <Glyphicon glyph="plus"/> 30 min</Button>
                                                    </ButtonGroup>
                                                    <ButtonGroup>
                                                        <Button bsStyle={'success'} onClick={() => this.setState({
                                                            showModal: true,
                                                            modalAction: 2,
                                                            currentId: solutionKitchen.id
                                                        })}> <Glyphicon glyph="check"/></Button>
                                                    </ButtonGroup>
                                                </ButtonGroup>
                                            </ListGroupItem>
                                        );
                                    })}
                                </ListGroup>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
                <LabModal show={this.state.showModal} onHide={closeModal} action={this.state.modalAction}/>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        allocables: state.firestore.ordered['allocables'],
        solution: state.firestore.ordered['solution']
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        SupervisorActions: (allocable) => dispatch(SupervisorActions(allocable))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        {'collection': 'allocables'},
        {'collection': 'solution'}
    ])
)(LabAllocables);
