import React, {Component} from 'react';
import {Modal, Button, Glyphicon, FormControl} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';

class LabModal extends Component {

    state = {
        justification: 'Lançamento incorreto'
    };

    handleChange = (e) => {
        this.setState({
            justification: e.target.value
        });
    };

    render() {
        let message;
        switch (this.props.action) {
            case 0:
                message = 'Tem certeza que deseja criar uma nova OS?';
                break;
            case 1:
                message = 'Tem certeza que deseja atualizar esta OS?';
                break;
            case 2:
                message = 'Tem certeza que deseja ignorar esta OS? ESTA AÇÃO NÃO PODE SER DESFEITA, A OS NÃO SERÁ MAIS CONCIDERADA NO SISTEMA DE OTIMIZAÇÃO DE ROTAS!!!!!';
                break;
            default: break
        }
        return (
            <Modal
                {...this.props}
                bsSize="small"
                aria-labelledby="contained-modal-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-lg">ATENÇÃO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <strong>{message}</strong>
                </Modal.Body>
                {this.props.action === 2 ?
                    <Grid fluid>
                        <Row>
                            <Col xs={12} md={12}>
                                <strong>Justificativa</strong>
                                <FormControl componentClass={'select'} id={'justification'}
                                             onChange={this.handleChange}
                                             value={this.state.justification}>
                                    <option value={'Lançamento incorreto'}>Lançamento incorreto</option>
                                    <option value={'OS repetida'}>OS repetida</option>
                                    <option value={'Terceirizado'}>Terceirizado</option>
                                    })}
                                </FormControl>
                            </Col>
                        </Row>
                    </Grid> : null}
                <Modal.Footer>
                    <Button bsStyle={'danger'} onClick={() => this.props.onHide(false)}><Glyphicon
                        glyph={'remove'}></Glyphicon></Button>
                    <Button bsStyle={'success'}
                            onClick={() => this.props.onHide(true, this.state.justification)}><Glyphicon
                        glyph={'ok'}></Glyphicon></Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default LabModal;
