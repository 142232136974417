import React, { Component } from "react";
import { 
  Button,
  Glyphicon
} from 'react-bootstrap'
import Select from 'react-select'
import { text } from '../../config/translate'
import InterventionHistory from "./interventionHistory.js";

import * as firebase from "firebase/app"
import "firebase/firestore";



export default class InterventionTable extends Component{

  
  render() {
    
    return (
      <div>
        <InterventionHistory/>
      </div>
    );
  }
}

