import React from 'react';
import * as firebase from 'firebase';

const Playground = () => {

    const ref = firebase.firestore().collection('_playground');

    ref.doc('20200115').get().then( snapshot => {
        console.log(snapshot.data());
    });

    return (
        <h1>PLAYGROUND</h1>
    );

}

export default Playground;
