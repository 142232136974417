import React, { Component } from 'react'
import Warehouse from './warehouse'


export default class WarehouseIndex extends Component {

  render() {

    const click = () => {
      Warehouse.supervisor.add('plx0000')
    }
    
    return (
      <>
        <button onClick={click}>CLICK ME</button>
      </>
    )
  }
}
