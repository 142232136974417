import React, {Component} from 'react';
import {BrowserRouter, Switch, Route, withRouter} from 'react-router-dom'
import NavBar from './components/layout/NavBar'
// import Dashboard from './components/dashboard/Dashboard';
import SignIn from './components/auth/SignIn';
import CreateUser from './components/auth/CreateUser';
import CreateService from './components/serviceOrder/CreateService';
import RegisterVehicle from './components/vehicles/RegisterVehicle';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import LabAllocables from "./components/allocables/LabAllocables";
import FuneralAllocables from "./components/allocables/FuneralAllocables";
import Vehicles from './components/vehicles/Vehicles';
import MealAllocables from './components/allocables/MealAllocables'
import SignOut from './components/auth/SignOut';
import NotFound from './components/errors/NotFound'
import SuperSecretDelete from './components/superSecretTools/SuperSecretDelete';
import AllocablesTable from './components/table/allocableTable'
import Resources from './components/table/resourceTable'
import SolutionTable from './components/table/solutionTable'
import OperationStatus from './components/status';
import Graveyard from './components/graveyard';
import HistoryTable from './components/history';
import Dashboard from './components/dash';
import Operation from './components/operation';
import WarehouseIndex from './components/warehouse';
import Playground from './components/playground';
import Intervention from './components/intervention';
import Funerarys from './components/funerary';
import Position from './components/position';

export const AppVersion = 'v2.0'

class App extends Component {

    
    render() {
            let homeComponent

            switch (this.props.profile.type) {
                case 3:
                    homeComponent = SolutionTable;
                    break
                case 4:
                    homeComponent = SolutionTable;
                    break
                default:
                    homeComponent = SignIn;
                    break
            }
            
            return (
                    <BrowserRouter>
                        <div className="App">
                            <NavBar/>
                            <Switch>
                                <Route exact path={"/"} component={homeComponent}/>
                                <Route exact path={"/home"} component={homeComponent}/>
                                <Route path={"/signin"} component={SignIn}/>
                                <Route path={"/createuser"} component={CreateUser}/>
    
                                <Route path={"/A00000000allocables"} component={AllocablesTable}/>
                                <Route path={"/A00000000resources"} component={Resources}/>
                                <Route path={"/A00000000solution"} component={SolutionTable}/>
                                <Route path={"/history"} component={HistoryTable}/>
                                <Route path={"/playground"} component={withRouter(Playground)}/>
                                <Route path={"/graveyard"} component={withRouter(Graveyard)}/>
                                <Route path={'/dash'} component={Dashboard}/>
                                <Route path={'/warehouse'} component={WarehouseIndex}/>
                                <Route path={'/operation'} component={OperationStatus}/>
                                <Route path={'/operation/...'} component={OperationStatus}/>

                                <Route path={'/intervention'} component={Intervention}/>
                                <Route path={'/funerary'} component={Funerarys}/>
                                <Route path={'/position'} component={Position}/>
                                {/* Currently Working On This */}
                                <Route path={'/op'} component={Operation}/>
                                
                                <Route path={"/command"} component={SolutionTable}/>
                                <Route path={"/dashboard"} component={Dashboard}/>
                                <Route exact path={"/createservice"} component={CreateService}/>
                                <Route path={"/createservice/:id"} component={CreateService}/>
                                <Route path={"/registervehicle"} component={RegisterVehicle}/>
                                <Route path={"/privacypolicy"} component={PrivacyPolicy}/>
                                <Route path={"/labs"} component={LabAllocables}/>
                                <Route path={"/funerals"} component={FuneralAllocables}/>
                                <Route path={"/vehicles"} component={Vehicles}/>
                                <Route path={"/meals"} component={MealAllocables}/>
                                <Route path={"/signout"} component={SignOut}/>
                                <Route path={"/supersecrettools/deleteeverthing"} component={SuperSecretDelete}/>
                                <Route path="*" component={NotFound} />

                                

                            </Switch>
                        </div>
                    </BrowserRouter>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile
    }
};

export default compose(connect(mapStateToProps))(App);
