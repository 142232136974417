import React from 'react'
import { Col, Row, Grid, Table, Badge, Button, Glyphicon } from 'react-bootstrap'
import { FlexibleWidthXYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, LabelSeries } from 'react-vis'
import Select from 'react-select'
import { tickFormatter } from '../../util'
import './index.css'
import Loading from '../loading'
import * as firebase from "firebase/app"
import "firebase/firestore";
import { text } from '../../config/translate'
import { useFirestore, useFirestoreGet } from '../../hooks/firebase'
import StateManager from 'react-select'
import {saveAs} from "file-saver";

const RED = '#ef5350'
const BLUE = '#42a5f5'  
const GREEN = '#66bb6a'
const YELLOW = '#ffca28'
const GRAY = '#AC58FA'

const state = {
    body: '',
    funeral: '',
    burial: '',
    general: '',
    funeral_return: '',
    data: [],
    dateInit: '',
    dateEnd: ''
}

export default function OperationTypesChart ({}) {
    //push paramenter to url and verify if exist    
    var arrayURL = String(window.location).split('/')

    let dateFilterInit
    let dataFilterEnd
    let yearInit
    var montInit
    let montEnd

    if(arrayURL[4] != null){
        //configure date in paramenter
        var dataURL = String(arrayURL[4]).split('$')

        dateFilterInit = new Date(dataURL[0])
        dateFilterInit.setDate(dateFilterInit.getDate() + 1)
        dateFilterInit.setHours(0)
        yearInit = dateFilterInit.getFullYear()
        montInit = Number(dateFilterInit.getMonth()+1)
        state.dateInit = dateFilterInit
    
        dataFilterEnd = new Date(dataURL[1])
        dataFilterEnd.setDate(dataFilterEnd.getDate() + 1) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        montEnd = dataFilterEnd.getMonth()+1
        
    } else {
        dateFilterInit = new Date()
        dateFilterInit.setDate(1)
        dateFilterInit.setHours(0)
        yearInit = dateFilterInit.getFullYear()
        montInit = Number(dateFilterInit.getMonth()+1)
        state.dateInit = dateFilterInit
    
        dataFilterEnd = new Date()
        dataFilterEnd.setDate(dataFilterEnd.getDate() + 1) 
        dataFilterEnd.setHours(23)
        dataFilterEnd.setMinutes(59)
        dataFilterEnd.setSeconds(59)
        montEnd = dataFilterEnd.getMonth()+1
        state.dateEnd = dataFilterEnd
    } 

    let content = <Loading/>

    //function to search data in firebase
    queryasync(montInit, yearInit, montEnd, dataFilterEnd.getFullYear(), dateFilterInit.getDate(), dataFilterEnd.getDate())
    
    async function queryasync(montInit, yearInit, montEnd, dataFilterEnd, dateInit, dateEnd){  
        await query(montInit, yearInit, montEnd, dataFilterEnd, dateInit, dateEnd)
    }

    async function download(){


        const timestampToDate = (date) => {
            console.log(date)
            let year = date.getUTCFullYear().toString()
            let month = date.getMonth()+1
            let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
            return `${day}/${month}/${year}`
          }
        


        var Excel = require('exceljs');
        // A new Excel Work Book
        var workbook = new Excel.Workbook();

        // Some information about the Excel Work Book.
        workbook.creator = 'Matheus Oliveira';
        workbook.lastModifiedBy = '';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();

        // Create a sheet
        var sheet = workbook.addWorksheet('Sheet1');
        // A table header
        sheet.columns = [
            { header: 'Nome', key: 'name' },
            { header: 'OS', key: 'os' },
            { header: 'Tipo', key: 'type' },
            { header: 'Data Marcada', key: 'promiseTime' },
            { header: 'Data Criação', key: 'createdAt' },
            
        ]

        // Add rows in the above header
        Object.keys(state.data).forEach(function(item){
            sheet.addRow({name: state.data[item].name, os: state.data[item].os, type: state.data[item].type, promiseTime: state.data[item].promiseTime, createdAt: state.data[item].createdAt });
        })

        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileExtension = '.xlsx';
        
        const blob = new Blob([buffer], {type: fileType});
        
        if(arrayURL[4] != null){
            var dataURL = String(arrayURL[4]).split('$')
            var dataInit = String(dataURL[0]).split('-')
            var dataEnd = String(dataURL[1]).split('-')
            saveAs(blob, 'OS_Types_'+ dataInit[2] + "/" + (parseInt(dataInit[1])) + "/" + dataInit[0] + "-"+ dataEnd[2] + "/" + (parseInt(dataEnd[1])) + "/" + dataEnd[0] + fileExtension);
        } else {
            let date = new Date()
            saveAs(blob, 'OS_Types_mes_'+ (date.getMonth()+1) + fileExtension);
        }
    }

    let data = [
                {
                    x: 'REMOÇÃO ',
                    y: state.body,
                    color: YELLOW
                },{
                    x: 'VELÓRIO',
                    y: state.funeral,
                    color: BLUE
                },{
                    x: 'CORTEJO',
                    y: state.burial,
                    color: GREEN
                },{
                    x: 'GENÉRICA',
                    y: state.general,
                    color: RED
                },
                {
                    x: 'RETORNO PARAM',
                    y: state.funeral_return,
                    color: GRAY
                }
        ]
                        
    content = (
            <div>
                <FlexibleWidthXYPlot
                    height={300 + 202}
                    xType={'ordinal'}
                    margin={{ bottom: 150 }}
                >
                    <VerticalGridLines/>
                    <HorizontalGridLines/>
                    <XAxis
                        tickLabelAngle={-90}
                        style={{
                            text: {
                                stroke: 'none',
                                fill: '#000',
                                fontWeight: 600,
                                maxWidth: 2,
                            }
                        }}
                        tickFormat={tickFormatter}
                        position={'start'}
                        className={'XAxis'}
                    />
                    <YAxis/>
                        <VerticalBarSeries
                            data={data}
                            colorType={'literal'}
                        />
                        <LabelSeries
                            data={data.map(obj => ({
                                ...obj,
                                label: `${obj.y}`
                            }))}
                            labelAnchorX={'start'}
                        />
                </FlexibleWidthXYPlot>

                <Button style={{marginRight: 10}} onClick={download}>
                    Exportar Dados {" "}
					<Glyphicon glyph={'glyphicon glyphicon-download-alt'}/>
				</Button>
            </div>


    )

    return (content)
}

async function query (monthInit, yearInit, monthEnd, yearEnd, dateInit, dateEnd) {

    var body = 0
    var funeral = 0;
    var burial = 0;
    var general = 0;
    var funeral_return = 0;

    let dateFilterEnd = new Date(yearEnd+"-"+monthEnd+"-"+(dateEnd))
    dateFilterEnd.setHours(23)
    dateFilterEnd.setMinutes(59)
    dateFilterEnd.setSeconds(59)

    let dateFilterInit = new Date(yearInit+"-"+monthInit+"-"+dateInit)
    dateFilterInit.setHours(0)
    dateFilterInit.setMinutes(0)
    dateFilterInit.setSeconds(0)



    // console.log( "data init ==> ", dateFilterInit)
    // console.log( "data end ==> ", dateFilterEnd)
    let data = []
    let doc  = await firebase.firestore().collection('historico').where('type', '==', "OS CREATION")
                                                                 .where('data.promiseTime', '<=', dateFilterEnd)
                                                                 .where('data.promiseTime', '>=', dateFilterInit).get()
    .then( snapshot => {
        snapshot.forEach(doc => {
                data.push({
                    name: doc.data().data.name,
                    os: doc.data().data.serviceOrder,
                    type: text(doc.data().data.type),
                    promiseTime: new Date(doc.data().data.promiseTime.seconds * 1000).toUTCString(),
                    createdAt: new Date(doc.data().data.createdAt.seconds * 1000).toUTCString()
                })

                if(doc.data().data.type === "body"){

                    body++
                }
                if(doc.data().data.type === 'funeral'){
                    funeral++
                }
                if(doc.data().data.type === 'burial'){
                    burial++
                }
                if(doc.data().data.type === 'general'){
                    general++
                }
                if(doc.data().data.type === 'funeral_return'){
                    funeral_return++
                }  
            
        })
    })


    // console.log("body ==> ", body)
    // console.log("funeral ==> ", funeral)
    // console.log("burial ==> ", burial)
    // console.log("general ==> ", general)
    // console.log("funeral_return ==> ", funeral_return)

    state.body =  body
    state.funeral = funeral 
    state.burial =  burial
    state.general =  general
    state.funeral_return =  funeral_return
    state.data = data
    
}