import React, { Component } from 'react'
import Select from 'react-select'
import { Glyphicon } from 'react-bootstrap'
import DateTime from 'react-datetime';
import * as firebase from "firebase/app"
import { text } from '../../config/translate';
import { Link } from 'react-router-dom'
import {funerary_list} from '../../config/funeraryList'
import "firebase/firestore"
import Loading from '../loading'

const LABORATORY = 'laboratory'
const DEPOSIT = 'deposit'
var names = []

class DataField extends Component {
    
    render() {
        if (this.props.editableCell)
            if (this.props.editableRow) {
                if (this.props.list) {
                    return this.props.children.find(child => child.ref === 'select')
                } else {
                    if (this.props.date)
                        return this.props.children.find(child => child.ref === 'datetime')
                    else
                        return this.props.children.find(child => child.ref === 'editableField')
                }
            }
        return this.props.children.find(child => child.ref === 'fixedField')
    }
}

class NewSelect extends Component {

    newOptions = ({ options, name, type, defaultValue }) => {

        if (name === 'status' && defaultValue && defaultValue.value !== 'finished') {
            let op = options.find(option => {
                if (typeof option.value === 'string' && typeof defaultValue.value === 'string') {
                    if (text(option.value).toLowerCase() === defaultValue.value.toLowerCase())
                        return true
                }
                return false
            })

            if (op) {
                let { extra: { next } } = op
                if (next && Object.keys(next).length > 0) {
                    if (type) {
                        let isOfType = Object.keys(next).map(key => key.toLowerCase()).includes(type.toLowerCase())
                        if (isOfType) {
                            return options = next[type.toLowerCase()].map(option => {
                                return ({
                                    value: option.toLowerCase(),
                                    label: text(option.toUpperCase())
                                })
                            })
                        }
                    }
                }
            }
        }
        
        return options.map(option => {
            return ({
                value: option.value.toLowerCase(),
                label: text(option.label.toUpperCase())
            })
        })
    }

    render() {
        let { options, ...props } = this.props
        return (
            <Select options={this.newOptions(this.props)} {...props}/>
        )
    }
}

const IndicatorSeparator = () => {
    return null
}

const IndicatorsContainer = () => {
    return null
}


const timestampToDate = timestamp => {
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    let date = new Date (timestamp)
    let year = date.getFullYear().toString()
    let month = months[date.getMonth()]
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    let hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    // let seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
    return `${day}/${month}/${year} ${hours}:${minutes}`
}

var flag = false
var dateComp

export default class Row extends Component {

    state = {
        editable: false,
        data: {}, 
        value: '', 
        name: ''
    }

    componentDidMount() {
        this.setState(Object.assign({}, this.state, {data: this.props.data, editable: this.props.hasUnsavedChanges || false}))
    }

    editableHandler = async () => {

        this.props.onChange({_unsaved:true})
        this.props.startEditing()
        
        this.setState((state, props) => ({
            data: props.data,
            editable: !state.editable,
            originalPlate: state.plate
        }))

    }

    onChange = ({value}, column, flag) => {

        this.props.onChange({[column]: value, _unsaved:true}, flag)
        //console.log("aquiiiiiiiiiiiiiiiiiiiiiiiii ", value)
        //this.intervention(value);
        if(column === "definedResource"){
            this.setState(({value: value})) 
        }  
    }

    update = async() => {
        let changedColumns = this.props.changedColumns(this.state.data.id)
        let originalData = {}

        //let flag = false
        if (changedColumns) {
            
            changedColumns.forEach(column => {

                Object.assign(originalData, {
                    [column]: this.state.data[column] !== undefined ? this.state.data[column] : null
                })
                // if(column === "definedResource"){
                //     this.intervention(this.state.value);
                //     this.interventionPrevious()
                // }
            })
        }
        
        
        await this.props.onChange({_unsaved:false})
        await this.editableHandler()
        await this.props.update(originalData)
        await this.props.finishEditing()
    }
    
    //TODO: Delay during query
    // funerary_list_query = async() =>{
    //     if(this.state.name === ''){
    //         let name = []
    //         if(names.length === 0){ 
    //             await firebase.firestore().collection("funeraryList").doc('names').get()
    //                 .then( snapshot => {
    //                     Object.keys(snapshot.data().funerary).forEach(function(item) {
    //                         name[snapshot.data().funerary[item].id] = snapshot.data().funerary[item].name  
                                    
    //                     })
    //                     names = name
    //                 })
                
    //         } else {
    //            // console.log("funerary_list_query ", names)
    //         }
    //     }
    // }

    // funerary_list= async(value) =>{
    //     if(this.state.name === ''){
    //         let name 
    //         if(names.length !== 0){ 
    //             if(value){
    //                 Object.keys(names).forEach(function(item) {
    //                     if(parseInt(item) === parseInt(value)){
    //                         name = 	names[item] 
    //                     }  
                             
    //                 })
                   
    //                 this.setState(state =>({
    //                     name: name
    //                 }))
                 
    //             }

    //         } else {
    //             //console.log("funerary_list ", names)
    //         }
    //     }
    // }

    cancel = () => {
        this.props.onChange({_unsaved:false})
        this.editableHandler()
        let { id, docId } = this.state.data
        this.props.reset(id, docId)
    }

    valueTransform = (value, name) => {
        if (typeof value === 'object' && value) {
            if ('seconds' in value && 'nanoseconds' in value) {
                return timestampToDate(value.seconds*1000)
            }
        }
        return value
    }

    isLaboratory = ({type}) => type === LABORATORY
    isDeposit = ({type}) => type === DEPOSIT

    render() {
        const { columns, data: {serviceOrder} } = this.props

        const tdStyle = (column) => {
            switch (column) {
                case 'status':
                case 'plate' :
                    return {verticalAlign: 'middle', textAlign: 'center'}
                default:
                    return {verticalAlign: 'middle'}
            }
        }
        
        const spanClassName = (column, value) => {
            switch (column) {
                case 'status':
                    switch (value) {
                        case 'new': return {backgroundColor: '#ffdabb', padding: 3, borderRadius: 3}
                        case 'started': return {backgroundColor: '#f3fab1', padding: 3, borderRadius: 3}
                        case 'arrived_body': return {backgroundColor: '#b1fab3', padding: 3, borderRadius: 3}
                        case 'arrived_funeral': return {backgroundColor: '#b1fab3', padding: 3, borderRadius: 3}
                        case 'at_lab': return {backgroundColor: '#bbcaff', padding: 3, borderRadius: 3}
                        case 'finished': return {backgroundColor: '#777', color: 'white', padding: 3, borderRadius: 3}
                        case 'picked_up_body_funeral': return {backgroundColor: '#fffac7', padding: 3, borderRadius: 3}
                        default: return {backgroundColor: '#ffaaaa', padding: 3, borderRadius: 3}
                    }
                case 'plate':
                    if (value.length === 0)
                        return {}
                    else if (this.props.data.definedResource)
                        return {backgroundColor: '#333', color: 'white', padding: 3, borderRadius: 3}                    
                    else
                        return {backgroundColor: '#ddd', color: '#777', padding: 3, borderRadius: 3}
            default: return {}
            }
        }

        const customSelectStyle = {
            option: (provided, state) => ({
              ...provided
            }),
            control: (provided, state) => ({
              // none of react-select's styles are passed to <Control />
              ...provided,
              width: '100%',
              height: 35,
              minWidth: 100
            }),
            singleValue: (provided, state) => {
              return { ...provided};
            }
        }

        const styles = {
            input: {
                width: '100%',
                height: 35,
                borderWidth: 0,
                borderBottomWidth: 0,
                borderColor: '#4caf50',
                overflow: 'auto',
                WebkitBoxShadow: '0 5px 15px rgba(0,0,0,0)',
                fontSize: 12,
                backgroundColor: 'rgba(0,0,0,0)'
            },
            command: {
                width: 100,
                minWidth: 100,
                textAlign: 'center',
                margin: 'auto'
            }
        }

        const editableHandler = () => {
            window.open("/createservice/" + encodeURIComponent(serviceOrder));
        }

        // this.funerary_list_query()

        return (
            <tr style={{backgroundColor: this.state.editable ? "#66bb6a" : 'white'}}>
                {
                    columns.map((column, key) => {
                        let { name, editable, editTarget, date } = column
                        let value = this.valueTransform(this.props.data[name], name) || ''
                        let _date = date ? this.props.data[name] : null
                        let os = this.props.serviceOrder 
                        
                        //console.log(this.props.data)
                        if (typeof value === 'number'){
                            
                            value = funerary_list(value)
                            value = String (value)

                            //TODO: Delay during query
                            // value = this.funerary_list(value)
                            // value = String (this.state.name)

                        }
                        
                        

                        if(this.props.data.createdAt){
                            let dateComp = new Date(this.props.data.createdAt.seconds * 1000) 
                            var dateNow = new Date()    
                            dateNow.setHours(dateNow.getHours() - 1)
                            if(dateComp >= dateNow){
                                flag =  true
                           }else{
                                flag = false
                           }
                        }

                        
                        return (
                                <td id={'controlTableRow'} key={key} style={tdStyle(name)}>
                                    <DataField editableCell={this.state.editable} name={name} definedResource={this.props.data.definedResource} editableRow={editable} list={this.props.getList(name)} editTarget={editTarget} date={date}>
                                        <input
                                            className={'form-control'}
                                            ref={'editableField'}
                                            type="text"
                                            value={
                                                flag === true ?
                                                    <strong>{value}</strong>
                                                : value
                                            }

                                            onChange={(event) => this.onChange(event.target, editTarget || name)}
                                            style={styles.input1}
                                            id={'inputTableRow'}
                                        />
                                        <span
                                            style={name === 'status' || name === 'plate' ? spanClassName(name, value) : {backgroundColor: 'rgba(0,0,0,0)'}}
                                            ref={'fixedField'}
                                            id={'inputTableRow'}
                                        > 
                                            {flag === true ?
                                                <strong>{text(value.toLowerCase()).toUpperCase()}</strong>
                                            : text(value.toLowerCase()).toUpperCase()
                                            }
                                        </span>
                                        <NewSelect
                                            ref={'select'}
                                            type={this.props.type}
                                            name={column.name}
                                            options={this.props.getList(name)}
                                            editTarget={editTarget} // CHECK THIS LATER
                                            defaultValue={
                                                flag === true ?
                                                <strong>{{value: text(value).toUpperCase(), label: text(value).toUpperCase()}}</strong>
                                                :  {value: text(value).toUpperCase(), label: text(value).toUpperCase()}
                                                }
                                            onChange={(e) => this.onChange(e, editTarget || name)}
                                            styles={customSelectStyle}
                                            components={{ IndicatorSeparator, IndicatorsContainer }}
                                        />
                                        <DateTime
                                            locale={'pt-br'}
                                            ref={'datetime'}
                                            defaultValue={_date ? new Date(_date.seconds * 1000) : null}
                                            onChange={e => e.toDate && this.onChange({value: firebase.firestore.Timestamp.fromDate(e.toDate())}, editTarget || name)}
                                            inputProps={{style:{width: 150, height: 35}}}
                                        />
                                    </DataField>
                                </td>
                            )
                        
                    
                            
                    })
                }
                {
                    this.state.editable &&
                    !this.isDeposit(this.state.data) &&
                    !this.isLaboratory(this.state.data) &&
                        <td style={styles.command}>
                            <button id={'actionButtonControlTable'} className={'btn btn-primary'} disabled={!this.state.editable} onClick={this.update}>
                                <Glyphicon glyph={'glyphicon glyphicon-ok'}/>
                            </button>
                            <button id={'actionButtonControlTable'} className={'btn btn-danger'} onClick={this.cancel}>
                                <Glyphicon glyph={'glyphicon glyphicon-remove'}/>
                            </button>
                        </td>
                    
                }
                {
                    !this.state.editable &&
                    !this.isDeposit(this.state.data) &&
                    !this.isLaboratory(this.state.data) &&
                    <td style={styles.command}>
                        <button className={'btn btn-default'} data-toggle={'tooltip'} title={'Editar TAREFA'} disabled={this.state.editable} onClick={this.editableHandler}>
                            <Glyphicon glyph={'glyphicon glyphicon-pencil'}/>
                        </button>
                        <Link to={'#'+serviceOrder} className={'btn btn-default'} data-toggle={'tooltip'} title={'Editar O.S.'} disabled={this.state.editable} onClick={editableHandler}>
                            <Glyphicon glyph={'glyphicon glyphicon-search'}/>
                        </Link>
                    </td>
                }
            </tr>
        )
            
    }

    
}


