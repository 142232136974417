import React, {Component} from 'react';
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {deleteAllocables, showAllocables} from '../../store/actions/allocableActions'
import {Grid, Col, Row, Button} from 'react-bootstrap';

class SuperSecretDelete extends  Component{
    handleClick = () => {
        this.props.deleteAllocables();
    };

    handleClick2 = () => {
        this.props.showAllocables();
    };

    render(){
        const {auth} = this.props;

        if (auth.uid !== 'kNk1Md3bMYOcwho6JAF1Vek7qtf2') return null;
        return(
            <Grid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Button onClick={this.handleClick} size={"lg"} block>DELETAR TUDO!!!!!!!!!!!!</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Button onClick={this.handleClick2} size={"lg"} block>MOSTRAR TUDO.</Button>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteAllocables: () => dispatch(deleteAllocables()),
        showAllocables: () => dispatch(showAllocables())
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(SuperSecretDelete);
