import React from 'react';
import '../styles/index.css';

const Vehicles = props => {

    const arr = [...Array(20).keys()];

    return (
        <div className="vehicles-wrapper">
            <div className="vehicles-container">
                <div className="vehicles-title">
                    <p>Vehicles</p>
                </div>
                <div className="vehicles-content">
                    {
                        arr.map( key => {
                            return (
                                <div key={key} className="item">
                                    <div className="icon">
                                        <img src="/img/032-hearse-1.svg" alt=""/>
                                    </div>
                                    <div className="details">
                                        <div className="upper">
                                            <div className="plate">
                                                PLA-XXXX
                                            </div>
                                            <div className="user">
                                                JON DOE
                                            </div>
                                        </div>
                                        <div className="lower">
                                            STEP
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Vehicles;
