import React from 'react';
import './styles/index.css';
import Api from './components/api';

import Orders from './components/orders';
import Vehicles from './components/vehicles';
import Monitor from './components/monitor';
import Resources from './components/resources';
import Chart from './components/chart';

const Operation = () => {

    const content = (

        <div id="wrapper-container">
            <div id="main-container">
                <div id="api-container">
                    <Api/>
                </div>
                <div id="web-container">
                    <Api/>
                </div>
                <div id="optimizer-container">
                    <Api/>
                </div>
                <div id="orders-container">
                    <Orders/>
                </div>
                <div id="vehicles-container">
                    <Vehicles/>
                </div>
                <div id="monitor-container">
                    <Monitor/>
                </div>
                <div id="resources-container">
                    <Resources/>
                </div>
                <div id="chart-container">
                    <Chart/>
                </div>
            </div>
        </div>

    )

    return content;
}

export default Operation;
