export const funerary_list  = (value) => {
    return subtitle(value)
}

const subtitle = value => {
    const FUNERARY = [
        {
            id: 1,
            name: 'FUNERARIA SANTA BARBARA'
        },
        {
            id: 2,
            name: 'FUNERARIA BOM JESUS'
        },
        {
            id: 3,
            name: 'FUNERARIA BOM PASTOR'
        },
        {
            id: 4,
            name: 'FUNERARIA SANTA CLARA BH'
        },
        {
            id: 5,
            name: 'FUNERARIA SANTA RITA'
        },
        {
            id: 6,
            name: 'FUNERARIA SANTA CLARA LTDA- ME BETIM'
        },
        {
            id: 7,
            name: 'FUNERARIA SANTA CLARA SETE LAGOAS'
        },
        {
            id: 8,
            name: 'FUNERARIA SANTA CLARA MATOZINHOS'
        },
        {
            id: 9,
            name: 'FUNERARIA SANTA CLARA BARAO DE COCAIS'
        },        
        {
            id: 10,
            name: 'FUNERARIA SANTA CLARA CAETE'
        },
        {
            id: 11,
            name: 'FUNERARIA SANTA BARBARA 2'
        },
        {
            id: 12,
            name: 'FUNERARIA GRUPO ZELO BARABACENA'
        },
        {
            id: 13,
            name: 'GRUPO ZELO'
        },        
        {
            id: 14,
            name: 'FUNERARIA GRUPO ZELO CARANDAI'
        },
        {
            id: 15,
            name: 'NAO MEXER'
        },
        {
            id: 16,
            name: 'JARDEL '
        },
        {
            id: 17,
            name: 'JARDEL RAMOS DA SILVA'
        },        {
            id: 18,
            name: 'FUNERARIA IBERTIOGA'
        },
        {
            id: 19,
            name: 'VELORIO / LAB - BARROSO'
        },
        {
            id: 20,
            name: 'FUNERARIA RIBERAO DAS NEVES'
        },
        {
            id: 21,
            name: 'FUNERARIA BARROSO'
        },
        {
            id: 22,
            name: 'FUNERARIA PORTO FIRME'
        },
        {
            id: 23,
            name: 'FUNERARIA ALTO RIO DOCE'
        },        {
            id: 24,
            name: 'ATENDIMENTO DANTA BARBARA 2'
        },
        {
            id: 25,
            name: 'ATENDIMENTO BOM JESUS'
        },        
        {
            id: 26,
            name: 'FUNERARIA SANTA EFIGENIA'
        },
        {
            id: 27,
            name: 'ATENDIMENTO INVEST PLAN BH'
        },        
        {
            id: 28,
            name: 'ATENDIMENTO SENHOR DOS PASSOS'
        },
        {
            id: 29,
            name: 'ATENDIMENTO EQUADOR'
        },        
        {
            id: 30,
            name: 'ATENDIMENTO JOSE ANTONIO'
        },
        {
            id: 31,
            name: 'ATENDIMENTO PRAÂA JOSE ANTONIO'
        },        
        {
            id: 32,
            name: 'ATENDIMENTO CAPIM BRANCO'
        },
        {
            id: 33,
            name: 'ATENDIMENTO PRUDENTE DE MORAES'
        },        
        {
            id: 34,
            name: 'ATENDIMENTO PEDRO LEOPOLDO'
        },
        {
            id: 35,
            name: 'ATENDIMENTO SERVIÇOS PRUDENTE DE MORAES'
        },        
        {
            id: 36,
            name: 'ATENDIMENTO PARAOPEBA'
        },
        {
            id: 37,
            name: 'ATENDIMENTO SANTA LUZIA'
        },        
        {
            id: 38,
            name: 'FUNERARIA JABOTICATUBAS'
        },
        {
            id: 39,
            name: 'C.B.S.F GAMELEIRA'
        },        
        {
            id: 40,
            name: 'FUNERARIA VIDA CARANGOLA'
        },
        {
            id: 41,
            name: 'FUNERARIA ESPERA FELIZ'
        },        
        {
            id: 42,
            name: 'FUNERARIA VIDA GUACUI'
        },
        {
            id: 43,
            name: 'FUNERARIA VIDA ALEGRE'
        },        
        {
            id: 44,
            name: 'FUNERARIA CONGONHAS'
        },
        {
            id: 45,
            name: 'FUNERARIA CBSF BETIM'
        },        
        {
            id: 46,
            name: 'FUNERARIA CBSF ABAETE'
        },
        {
            id: 47,
            name: 'FUNERARIA CBSF MEMORIAL'
        },        
        {
            id: 48,
            name: 'FUNERARIA VICOSA'
        },
        {
            id: 49,
            name: 'FUNERARIA CBSF BOM DESPACHO'
        },
        {
            id: 56,
            name: 'COLINA'
        },
        {
            id: 62,
            name: 'HOSPITAL VERA CRUZ'
        },
    ]

    let term = FUNERARY.find(term => term.id === value)
    if (term)
        return term.name
    return value
}
    