
const initState = {
    authError: null
};

const authReducer = (state = initState, action) => {
        switch (action.type) {
            case 'LOGIN_ERROR':
                return {
                    ...state,
                    authError: action.err.message
                };

            case 'LOGIN_SUCCESS':
                return {
                    ...state,
                    authError: null
                };

            case 'SIGNOUT_SUCCESS':
                window.location.href = '/signin';
                return state;

            case 'CREATE_USER_SUCCESS':
                alert('Usuário criado com sucesso');
                window.location.reload();
                return {
                    ...state,
                    authError: null
                };

            case 'CREATE_USER_ERROR':
                return {
                    ...state,
                    authError: action.err
                };

            default:
                return state;
        }
    }
;

export default authReducer;

