import React from 'react'
import {connect} from 'react-redux';
import {signOut} from '../../store/actions/authActions';
import {Nav, NavItem, NavDropdown, Glyphicon} from 'react-bootstrap';

const style = {
    item: {
        marginLeft: 5,
        color: 'white',
        fontWeight: 600
    }
}

const COMMAND = '/command'
const CREATESERVICE = '/createservice'
const LABS = '/labs'
const FUNERALS = '/funerals'
const VEHICLES = '/vehicles'
const HISTORY = '/history'
const REGISTERVEHICLE = '/registervehicle'
const CREATEUSER = '/createuser'
const OPERATION = '/operation'
const INTERVENTION = '../intervention'
const FUNERARY = './funerary'
const POSITION = './position'

const AdministratorLinks = (props) => {
    
    return (
        <>
            <Nav>
                <NavItem active={window.location.href.includes(COMMAND)} eventKey={1} href={COMMAND}>
                    <Glyphicon glyph={'glyphicon glyphicon-th-list'}/>
                    <span style={style.item}>Tabela de Serviços</span>
                </NavItem>
                <NavItem active={window.location.href.includes(CREATESERVICE)} eventKey={2} href={CREATESERVICE}>
                    <Glyphicon glyph={'glyphicon glyphicon-file'}/>
                    <span style={style.item}>Criar Ordem de Serviço</span>
                </NavItem>

                <NavDropdown eventKey={3} title={
                    <>
                        <Glyphicon glyph={'glyphicon glyphicon-eye-open'}/>
                        <span style={style.item}>Monitoramento</span>
                    </>
                } id={'navbar_dropdown'} >
                    <NavItem active={window.location.href.includes(LABS)} eventKey={3.1} href={LABS}>
                        Monitorar Laboratório
                    </NavItem>
                    <NavItem active={window.location.href.includes(FUNERALS)} eventKey={3.2} href={FUNERALS}>
                        Monitorar Velórios
                    </NavItem>
                    <NavItem active={window.location.href.includes(VEHICLES)} eventKey={3.3} href={VEHICLES}>
                        Monitorar Veículos
                    </NavItem>
                    <NavItem active={window.location.href.includes(OPERATION)} eventKey={3.4} href={OPERATION}>
                        Monitorar Operação
                    </NavItem>
                </NavDropdown>
                
                {/* <NavItem active={window.location.href.includes(HISTORY)} eventKey={4} href={HISTORY}>
                    <Glyphicon glyph={'glyphicon glyphicon-hourglass'}/>
                    <span style={style.item}>Histórico</span>
                </NavItem> */}

                <NavDropdown eventKey={4} title={
                        <>
                        <Glyphicon glyph={'glyphicon glyphicon-hourglass'}/>
                            <span style={style.item}>Histórico</span>
                        </>
                    } id={'navbar_dropdown'} >
                        <NavItem active={window.location.href.includes(HISTORY)} eventKey={4.1} href={HISTORY}>
                            Histórico
                        </NavItem>
                        <NavItem active={window.location.href.includes(INTERVENTION)} eventKey={4.2} href={INTERVENTION}>
                            Histórico de Intervenções
                        </NavItem>
                </NavDropdown>

                <NavItem active={window.location.href.includes(REGISTERVEHICLE)} eventKey={5} href={REGISTERVEHICLE}>
                    <Glyphicon glyph={'glyphicon glyphicon-road'}/>
                    <span style={style.item}>Registrar Veículo</span>
                </NavItem>
                <NavItem active={window.location.href.includes(CREATEUSER)} eventKey={6} href={CREATEUSER}>
                    <Glyphicon glyph={'glyphicon glyphicon-user'}/>
                    <span style={style.item}>Criar Usuário</span>
                </NavItem>

                <NavItem active={window.location.href.includes(FUNERARY)} eventKey={7} href={FUNERARY}>
                    <Glyphicon glyph={'glyphicon glyphicon-save'}/>
                    <span style={style.item}>Cadastrar Funerárias/ Plataformas</span>
                </NavItem>
                
                <NavItem active={window.location.href.includes(POSITION)} eventKey={8} href={POSITION}>
                    <Glyphicon glyph={'glyphicon glyphicon-globe'}/>
                    <span style={style.item}>Lozalização de Veículos</span>
                </NavItem>
                

            </Nav>
            <Nav pullRight>
                <NavItem disabled>
                    <p style={{color: 'grey'}}>{props.version}</p>
                </NavItem>
                <NavItem data-toggle={'tooltip'} title={'Sair'} eventKey={9} href="#" onClick={props.signOut}>
                    <Glyphicon glyph={'glyphicon glyphicon-off'}/>
                </NavItem>
            </Nav>

        </>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    };
};

export default connect(null, mapDispatchToProps)(AdministratorLinks);
