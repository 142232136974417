import React, {Component} from 'react';
import {Modal, Button, Glyphicon} from 'react-bootstrap';

class FuneraryModal extends Component {

    render() {
        let message;
        switch (this.props.action) {
            case 0:
                message = 'ID OU NOME JÁ CADASTRADOS!';
                break;
            case 1:
                message = 'OS CAMPOS ID E NOME DEVEM SER PREENCHIDOS!';
                break;
            case 2:
                message = 'FUNERÁRIA/ PLATAFORMA CADASTRADA COM SUCESSO!';
                break;
            case 3:
                message = 'FUNERÁRIA/ PLATAFORMA ATUALIZADA COM SUCESSO!'
                break;
            case 4: 
                message = 'FUNERÁRIA/ PLATAFORMA DELETADA COM SUCESSO!'
                break;
            default: break;
        }
        return (
            <Modal
                {...this.props}
                bsSize="small"
                aria-labelledby="contained-modal-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-lg">ATENÇÃO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <strong>{message}</strong>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle={'default'} onClick={() => this.props.onHide()}><Glyphicon glyph={'ok'}></Glyphicon></Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default FuneraryModal;
