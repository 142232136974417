import React, { Component } from 'react'
import { Row } from 'react-bootstrap'

import Chart from './chart'

export default class TimeChart extends Component {

  render() {
    const { data } = this.props
    return (
      <>
        <Row style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
          <Chart data={data}/>
        </Row>
      </>
    )
  }
}
