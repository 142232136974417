import React, { Component } from "react";
import History from "./history";
import Timeline from "./modal";
import { 
  Button,
  Glyphicon
} from 'react-bootstrap'
import Select from 'react-select'
import { text } from '../../config/translate'

import * as firebase from "firebase/app"
import "firebase/firestore";

const COLUMNS = ["user", "serviceOrder", "createdAt"];
const RESOURCES = 'resources'

const originalState = {
  data: [],
  show: false,
  resource: false,
  os: '',
  dateInitValue: '',
  dateEndValue: '',
  timestamp: ''
}

export default class HistoryTable extends Component {
  state = originalState;

  componentDidMount = async () => {
    const ref = firebase.firestore().collection(RESOURCES)
    const snapshot = await ref.get()
    const resources = snapshot.docs.map(doc => ({
      value: doc.id,
      label: doc.id.toUpperCase()
    }))
    this.setState({ resources: resources });
  };

  getHistory = async() => {
    const { resource: {value: resource},  dateInitValue, dateEndValue} = this.state
   
    let data = new Date(dateInitValue);
    let dataEnd = new Date(dateEndValue);
    
    const history = History.query(resource, data, dataEnd);
    
    this.setState({data: history})
  }

  getDataByServiceOrder = serviceOrder => {
    return this.state.data.filter(({ serviceOrder: so }) => {
      if (so === serviceOrder) return true;
      else return false;
    });
  };

  showModalHandle = serviceOrder => {
    this.setState(state => ({
      show: !state.show,
      serviceOrder: serviceOrder
    }));
  };

  dateToTimestamp = () => {
    const {dateInitValue} = this.state
    var datum = Date.parse(dateInitValue);
    return datum/1000;
     

  };

  searchResource = async () => {

    const { resource: {value: resource}, os, dateInitValue, dateEndValue} = this.state
    
      let history = await History.query(resource, dateEndValue, dateInitValue, os);
      this.setState({data: history})
  }

  clearFilter = () => {
    this.setState(originalState, History.reset())
  }

  onSelectChange = e => {
    this.setState({resource: e})
  }

  onInputChange = ({ target: { value }}) => {
    this.setState({os: value})
  }

  onInputChangeDateInit= ({target: {value}}) => {
    
    this.setState({dateInitValue: value})
  }

  onInputChangeDateEnd = ({target: {value}}) => {
    
    this.setState({dateEndValue: value})
  }

  setTimestamp = (value) => {
    this.setState({timestamp: value})
  }
  
  orderData = ({createdAtTimestamp: {seconds: a}}, {createdAtTimestamp: {seconds: b}}) => {

    if (a && b) {
      if (a > b)
        return -1
      else if (a < b)
        return 1
      else
        return 0
    }
    return -1
  }

  searchData = ({createdAtTimestamp: {seconds: a}}, {createdAtTimestamp: {seconds: b}}) => {

    if(a === 1571242756 || b === 1571242756)
      return 1
    else 
      return -1

  }

  orderDataInverted = ({createdAtTimestamp: {seconds: a}}, {createdAtTimestamp: {seconds: b}}) => {
    if (a && b) {
      if (a > b)
        return 1
      else if (a < b)
        return -1
      else
        return 0
    }
    return 1
  }

  render() {
    const { data, show, serviceOrder, resources, resource, os, dateInitValue, dateEndValue} = this.state;
    return (
      <div>
        <Filter
          resources={resources}
          onClick={this.searchResource}
          selectValue={resource}
          inputValue={os}
          onSelectChange={this.onSelectChange}
          onInputChange={this.onInputChange}
          onClear={this.clearFilter}
          dateInit={dateInitValue}
          dateEnd={dateEndValue} 
          onInputChangeDateEnd={this.onInputChangeDateEnd} 
          onInputChangeDateInit={this.onInputChangeDateInit}
        />
        <ListTable
          data={data.sort(this.orderData)}
          onClick={this.showModalHandle}
        />
        <Timeline
          show={show}
          onHide={this.showModalHandle}
          data={() => this.getDataByServiceOrder(serviceOrder).sort(this.orderDataInverted)}
          serviceOrder={serviceOrder}
        />
      </div>
    );
  }
}

const Filter = ({resources, onClick, selectValue, inputValue, onSelectChange, onInputChange, onClear, dateInit, dateEnd, onInputChangeDateEnd, onInputChangeDateInit}) => {
  return (
    <div className="panel panel-default" style={{margin: 15}}>
      <div className="panel-heading">{text('FILTER')}</div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}} className="panel-body">
        <div style={{width: '15%'}}>
          <label style={{marginLeft: 20}}>{text('Resource')}</label>
          <Select
            options={resources}
            value={selectValue}
            onChange={onSelectChange}
            placeholder={text('Select...')}
          />
        </div>
        <div style={{width: '15%'}} className={'css-2b097c-container'}>
          <label style={{marginLeft: 30}}>{text('ServiceOrder')}</label>
          <input
            className={'css-yk16xz-control'}
            style={{marginLeft: 10, marginRight: 10, padding: 10, height: 38, width: '100%'}}
            type="text"
            value={inputValue}
            onChange={onInputChange}
            placeholder={text('Type...')}
          />
        </div>
        <div style={{width: '12%'}} className={'css-2b097c-container'}>
          <label style={{marginLeft: 30}}>{'A partir'}</label>
          <input
            className={'css-yk16xz-control'}
            style={{marginLeft: 20, marginRight: 10, padding: 10, height: 38, width: '100%'}}
            type="Date"
            value={dateInit}
            onChange={onInputChangeDateInit}
            placeholder={text('Date Create...')}
          />
        </div>
        <div style={{width: '12%'}} className={'css-2b097c-container'}>
          <label style={{marginLeft: 40}}>{'Até'}</label>
          <input
            className={'css-yk16xz-control'}
            style={{marginLeft: 30, marginRight: 10, padding: 10, height: 38, width: '100%'}}
            type="Date"
            value={dateEnd}
            onChange={onInputChangeDateEnd}
            placeholder={text('Date End...')}
          />
        </div>        
        <div style={{marginLeft: 'auto'}}>
          <Button style={{marginRight: 10}} onClick={onClick}>
            <Glyphicon glyph={'glyphicon glyphicon-search'}/>
          </Button>
          <Button onClick={onClear}>
              <Glyphicon glyph={'glyphicon glyphicon-erase'}/>
          </Button>
        </div>
      </div>
      
    </div>
  )
}

const ListTable = ({data, onClick}) => {
  return (
    <div className="panel panel-default" style={{ margin: 15 }}>
      <div className="panel-heading">HISTÓRICO</div>
      <div className="panel-body">
        <div>
          <table className={"table table-striped"}>
            <thead>
              <tr>
                {COLUMNS.map((column, key) => {
                  return (
                    <th key={key}>
                      <span className={'lead'}>{text(column.toUpperCase())}</span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((data, key) => {
                return (
                  <tr
                    key={key}
                    onClick={() => onClick(data.serviceOrder)}
                  >
                    {COLUMNS.map((column, key) => {
                      return <td key={key}>{data[column]}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="panel-footer"></div>
    </div>
  );
};
