import React, {Component} from 'react';
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {firestoreConnect} from "react-redux-firebase";
import {Redirect} from "react-router-dom";
import {signOut} from "../../store/actions/authActions";
import {
    Panel,
    Grid,
    Row,
    Col,
    ListGroup,
    ButtonGroup,
    Button,
    Glyphicon
} from 'react-bootstrap';
import LabModal from './LabModal';
import {SupervisorActions} from "../../store/actions/allocableActions";

var numberCheckBox = 0;
var numberSet = 1
class FuneralAllocables extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            modalAction: 0,
            currentId: '',
            type: 'funeral',
            serviceOrder: '',
            flag: false,
            ids: ''
        }

        
    }

    
    onCheck = () =>{
        var label = document.getElementsByName("checkboxFuneral");
        if(label.checked !== true){
            label.checked=true
        } else {
            label.checked=false
        }
    
    }

    onClick = () =>{ 
        for(i=0;i<numberCheckBox;i++){
            var label = document.getElementsByName("checkboxFuneral");

            label.value = '';
                    
            for(var i=0;i<label.length;i++){       
                if (label[i].checked){
                    label.value += label[i].value+'/';
                }
            }
            
            this.setState({                                                           
                showModal: true,
                modalAction: 4,
                flag: true,
                ids: label.value
                
            })

            
        }
    }

    

    render() {
        numberCheckBox = 0
        const {auth, profile, signOut, solution, allocables, flag} = this.props;

        if (!auth.uid) return <Redirect to={'/signin'}/>;
        else if (profile.type !== undefined && profile.type !== 2 && profile.type !== 4) signOut();
        
        if (solution === undefined || allocables === undefined ) return Loading();

        let closeModal = (success) => {
            if (success) {
                this.props.SupervisorActions(this.state, auth.uid, this.props.user);
            }

            this.setState({showModal: false});
        };

        let solutionFunerals = [];

        for (let index in allocables) {
            if (allocables[index].type === 'funeral' && allocables[index].status === 'arrived_funeral') {
                let allocable = allocables[index];
                
                let bodyAllocable;

                for (let i = 0; i < allocables.length; i++) {
                    if (allocables[i].type === 'body' && allocables[i].serviceOrder === allocable.serviceOrder) {
                        bodyAllocable = allocables[i];
                    }
                }

                let safeArrivalTime = undefined

                if(allocable && allocable.startedTime && bodyAllocable && bodyAllocable.finishedTime)
                    safeArrivalTime = allocable.startedTime ? allocable.startedTime : bodyAllocable.finishedTime ?  bodyAllocable.finishedTime : allocable.createdAt

                solutionFunerals.push({
                    serviceOrder: allocable.serviceOrder,
                    name: allocable.name,
                    estimatedDuration: allocable.estimatedDuration,
                    arrivalTime: safeArrivalTime,
                    id: allocable.id,
                    place: allocable.place
                });
            }
        }
        
        let CustomListGroupItem = ({children})=> {
            return (
                <div class="card" >
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">{children}</li>
                </ul>
              </div>

            );
        }
        
        solutionFunerals.sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
        })
        


        return (
            <Grid>
                <Row>
                    <Col xs={12} md={12} sm={12}>
                        <Panel>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">Velórios em progresso</Panel.Title>
                            </Panel.Heading>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Button  bsStyle={'success'}  onClick={this.onClick} >
                                        Liberar corpos selecionados para o cortejo {" "}
                                    <Glyphicon glyph="check"/>
                                </Button>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: 'red'
                                    }}
                                >
                                <h4>ATENÇÃO: Para liberar vários corpos simultaneamente, os selecione primeiro.</h4>
                                </div>
                            <Panel.Body>
                                <ListGroup>
                                    {
                                        (!solutionFunerals || !solutionFunerals.length) && (
                                            <p>Não há nenhum velório em progresso.</p>
                                        )
                                    }
                                    {solutionFunerals && solutionFunerals.map((solutionFuneral, key) => {
                                        let perc = 0
                                                  
                                        if(solutionFuneral.arrivalTime && solutionFuneral.estimatedDuration){

                                            perc = (((new Date()).getTime() - solutionFuneral.arrivalTime.toDate().getTime())
                                                / (new Date(solutionFuneral.arrivalTime.toDate().getTime() + solutionFuneral.estimatedDuration * 60000)
                                                    - solutionFuneral.arrivalTime.toDate().getTime())) * 100;

                                            perc = Number((perc).toFixed(0));

                                            if (perc < 0) perc = 0;
                                            else if (perc > 100) perc = 100;

                                        }
                                        numberCheckBox++
                                        return (
                                            <CustomListGroupItem key={key}>
                                            <Col>
                                                <div>
                                                    <div className={'monitorHeader block'}>
                                                        <div className={'monitorHeader osInfo'}>
                                                            <h4 className={'monitorHeader mHlabel'}>Nome: </h4>
                                                            <h4 className={'monitorHeader value'}>{solutionFuneral.name}</h4>
                                                        </div>
                                                        <div className={'monitorHeader osInfo'}>
                                                            <h4 className={'monitorHeader mHlabel'}>OS: </h4>
                                                            <h4 className={'monitorHeader value'}>{solutionFuneral.serviceOrder}</h4>
                                                        </div>
                                                        <div className="monitorHeader osInfo">
                                                            <h4 className="monitorHeader mHlabel">LOCAL: </h4>
                                                            <h4 className="monitorHeader value">{solutionFuneral.place}</h4>
                                                        </div>
                                                        
                                                    </div>
                                                    {
                                                        (solutionFuneral.arrivalTime && solutionFuneral.estimatedDuration) &&
                                                        <div className={'monitorHeader block right'}>
                                                            <div className={'monitorHeader osInfo'}>
                                                                <h4 className={'monitorHeader mHlabel'}>Início: </h4>
                                                                <h4 className={'monitorHeader value'}>{solutionFuneral.arrivalTime.toDate().toLocaleString('pt-Br')}</h4>
                                                            </div>
                                                            <div className={'monitorHeader osInfo'}>
                                                                <h4 className={'monitorHeader mHlabel'}>Fim: </h4>
                                                                <h4 className={'monitorHeader value'}>{(new Date(solutionFuneral.arrivalTime.toDate().getTime() + solutionFuneral.estimatedDuration * 60000)).toLocaleString('pt-Br')}</h4>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div name="checkbox1" xs={12} md={12} sm={12} >
                                                        <h4>
                                                            <input 
                                                                    name="checkboxFuneral"
                                                                    type="checkbox" 
                                                                    value={solutionFuneral.id}
                                                                    onClick={this.onCheck}
                                                                    style={{width: '20px',
                                                                    height: '20px' }}

                                                            />
                                                            {" "} Selecionar corpo</h4>
                                                            
                                                    </div>
                                                </div>
                                             </Col>
                                                <br/>
                                            {/* <Col > 
                                                <ButtonGroup justified>
                                                    <ButtonGroup>
                                                        <Button bsStyle={'success'} onClick={() => {
                                                            this.setState({                                                           
                                                                showModal: true,
                                                                modalAction: 2,
                                                                currentId: solutionFuneral.id
                                                            })
                                                            
                                                            }}> 
                                                                Liberar este corpo {" "}
                                                            <Glyphicon glyph="check"/></Button>
                                                       
                                                    </ButtonGroup>
                                                </ButtonGroup> 
                                            </Col> */}
                                            </CustomListGroupItem>
                                        );
    
                                    })}
                                </ListGroup>
                            </Panel.Body>
                        </Panel>
                    </Col>                   
                </Row>
                <LabModal show={this.state.showModal} onHide={closeModal} action={this.state.modalAction} />
            </Grid>
            
        )
        
    }
}

const Loading = (props) => {
	
	const loadingGif = require('../../resources/img/loading-spin.svg')
	return (
		<div className={'loading-container'}>
			<img className={'loading-gif'} src={loadingGif} alt={'loading...'}/>
		</div>
	)
}

const mapStateToProps = (state) => {
    const { uid, email } = state.firebase.auth
    const { isEmpty, isLoaded, ...profile } = Object.assign({}, state.firebase.profile, {email: email, uid: uid})
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        allocables: state.firestore.ordered['allocables'],
        solution: state.firestore.ordered['solution'],
        user: profile
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        SupervisorActions: (allocable, uid, user) => dispatch(SupervisorActions(allocable, uid, user))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        {'collection': 'allocables'},
        {'collection': 'solution'}
    ])
)(FuneralAllocables);
