import React, { Component } from 'react'
import Table from '.'

const COLUMNS = [
    'id', 'status', 'type'
]

export default class Resources extends Component {
    render() {
        return (
            <Table collection={'resources'} columns={COLUMNS}/>
        )
    }
}
