import React, {Component} from 'react';
import {Alert, Button, Col, ControlLabel, FormControl, FormGroup, Grid, Panel, Row} from "react-bootstrap";
import {signOut} from "../../store/actions/authActions";
import {createResource} from '../../store/actions/resourceActions';
import connect from "react-redux/es/connect/connect";
import {Redirect} from "react-router-dom";

class RegisterVehicle extends Component {
    state = {
        plate: '',
        capacity: '',
        capacityReturn: '',
        description: '',
        type: 'hearse',
        status: 'unavailable',
        coordinates: null,
        disabled: false
    };

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({disabled: true});
        this.props.createResource(this.state);
        this.props.history.push('/');
    };

    render() {
        const {auth, authError, profile, signOut} = this.props;

        if (!auth.uid || profile.type === 'driver') return <Redirect to={'/'}/>;
        else if (profile.type < 4) signOut();

        return (
            <Grid>
                <Row className="show-grid">
                    <Col xs={12} md={12}>
                        <Panel ref={this.panel}>
                            <Panel.Heading>Novo Veículo</Panel.Heading>
                            <Panel.Body>
                                <form onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <ControlLabel>Placa</ControlLabel>
                                        <FormControl type="text" placeholder="Placa"
                                                     id="plate" onChange={this.handleChange}
                                                     disabled={this.state.disabled}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Capacidade</ControlLabel>
                                        <FormControl type="number" placeholder="Capacidade"
                                                     id="capacity" onChange={this.handleChange}
                                                     disabled={this.state.disabled}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Capacidade de retorno</ControlLabel>
                                        <FormControl type="number" placeholder="Capacidade de retorno"
                                                     id="capacityReturn" onChange={this.handleChange}
                                                     disabled={this.state.disabled}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Descrição</ControlLabel>
                                        <FormControl componentClass="textarea" placeholder="Descrição"
                                                     id="description" onChange={this.handleChange}
                                                     disabled={this.state.disabled}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Tipo de carro</ControlLabel>
                                        <FormControl componentClass="select" placeholder="Tipo de carro"
                                                     onChange={this.handleChange} id={'type'}
                                                     disabled={this.state.disabled}
                                                     defaultValue={"hearse"}>
                                            <option value="hearse">Carro fúnebre</option>
                                            <option value="meal">Kit Lanche</option>
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup>
                                        <Button type="submit" disabled={this.state.disabled}>Criar Usuário</Button>
                                    </FormGroup>

                                    {authError ?
                                        <Alert bsStyle="danger"> <strong>{authError}</strong> </Alert> : null}
                                </form>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError,
        profile: state.firebase.profile,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createResource: (newVehicle) => dispatch(createResource(newVehicle)),
        signOut: () => dispatch(signOut())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterVehicle);
