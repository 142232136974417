export const createResource = (resource) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        const createdAt =  new Date();

        delete resource.disabled;
        resource.plate = resource.plate.toString().toUpperCase();

        let capacity = parseInt(resource.capacity);
        let capacityReturn = parseInt(resource.capacityReturn);

        delete resource.capacity;
        delete resource.capacityReturn;

        firestore.collection('resources').doc(resource.plate).set({
            ...resource,
            capacity,
            capacityReturn,
            createdAt
        })
            .then(() => {
                dispatch({type: 'CREATE_RESOURCE', resource});
            })
            .catch((err) => {
                dispatch({type: 'CREATE_RESOURCE_ERROR', err});
            });
    }
};
