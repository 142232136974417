import React, { Component } from "react";
import { Col, ControlLabel, FormControl, FormGroup, Grid, Panel, Row} from "react-bootstrap";
import { 
	Button,
	Glyphicon
} from 'react-bootstrap'

import * as firebase from "firebase/app"
import "firebase/firestore";

import FuneraryModal from './FuneraryModal';
import {SupervisorActions} from "../../store/actions/allocableActions";
import {signOut} from "../../store/actions/authActions";
import connect from "react-redux/es/connect/connect";

class FuneraryList extends Component{

	state ={
		menu: 0,
		data: [],
		showModal: false,
		modalAction: 0
	}

	search = async(e) => {

		var e_id = e.target.id
		var id = document.getElementById("id_"+e.target.id)
		var nome = document.getElementById("name_"+e.target.id)
		let data = []
		if(id.value || nome.value){			
			if(id.value){
				 await firebase.firestore().collection("funeraryList").doc('names').get()
				.then( snapshot => {
					Object.keys(snapshot.data().funerary).forEach(function(item) {
						if(snapshot.data().funerary[item].id === parseInt(id.value)){
							data = 	snapshot.data().funerary[item]
						}
							
					})
				})
				if(e_id == 'search' || e_id == 'result'){
					this.setState(state => ({
						menu:4,
						data: data
					}))
				} else if(e_id == 'search_remove' || e_id == 'remove'){
					this.setState(state => ({
						menu:5,
						data: data
					}))
				}
			}else{
				await firebase.firestore().collection("funeraryList").doc('names').get()
				.then( snapshot => {
					Object.keys(snapshot.data().funerary).forEach(function(item) {
						if(String(snapshot.data().funerary[item].name).toUpperCase() === String(nome.value).toUpperCase()){
							data = 	snapshot.data().funerary[item]
						}
							
					})
				})
				if(e_id == 'search' || e_id == 'result'){
					this.setState(state => ({
						menu:4,
						data: data
					}))
				} else if(e_id == 'search_remove'){
					this.setState(state => ({
						menu:5,
						data: data
					}))
				}
			}

		}
	}

	update = async() => {
		var id = document.getElementById("id_result")
		var nome = document.getElementById("name_result")
		let data = []
		if(id.value || nome.value){
			if(id.value && !nome.value){
				data = {id:id.value, name: this.state.data.name}
			} else if(!id.value && nome.value){
				data = {id:this.state.data.id, name: nome.value}
			} else if(id.value && nome.value){
				data = {id:id.value, name: nome.value}
			}

			await firebase.firestore().collection("funeraryList").doc('names').update({
				funerary: firebase.firestore.FieldValue.arrayRemove(this.state.data)
			});

			await firebase.firestore().collection("funeraryList").doc('names').update({
				funerary: firebase.firestore.FieldValue.arrayUnion({id: parseInt(data.id), name: String(data.name).toUpperCase()})
			});
			
			this.setState(state => ({
				showModal: true,
				modalAction: 3
			}))

		}
	}

	create = async(e) => {

		var id = document.getElementById("id_"+e.target.id)
		var nome = document.getElementById("name_"+e.target.id)
		var exist 
		if(id.value && nome.value && id.value !== null && nome.value !== null){
			await firebase.firestore().collection("funeraryList").doc('names').get()
			.then( snapshot => {
				Object.keys(snapshot.data().funerary).forEach(function(item) {
					if(snapshot.data().funerary[item].id === parseInt(id.value) || String(snapshot.data().funerary[item].name).toUpperCase() === String(nome.value).toUpperCase()){
						exist = true
					}else{
						exist = false
					}
				})
			})
			
			if(exist === true){
				console.log("ID OU NOME JÁ CADASTRADOS")
				this.setState(state => ({
					showModal: true,
					modalAction: 0
				}))
			}else if(exist === false){
				await firebase.firestore().collection("funeraryList").doc('names').update({
					funerary: firebase.firestore.FieldValue.arrayUnion({id: parseInt(id.value), name:String(nome.value).toUpperCase()})
				});

				this.setState(state => ({
					showModal: true,
					modalAction: 2
				}))
			}
			
		}else{
			this.setState(state => ({
				showModal: true,
				modalAction: 1
			}))
			console.log("OS CAMPOS ID E NOME DEVEM SER PREENCHIDOS")
		}

		
	}

	remove = async() =>{

		if(this.state.data.id && this.state.data.name){		
			await firebase.firestore().collection("funeraryList").doc('names').update({
				funerary: firebase.firestore.FieldValue.arrayRemove(this.state.data)
			});
			this.setState(state => ({
				showModal: true,
				modalAction: 4
			}))
		}
	}

    render(){

		let onClick = async() =>{
			this.setState(state => ({
				showModal: false,
				menu:0
			}))
		}

        return (
            <Grid>
                <Row className="show-grid">
                    <Col xs={12} md={12}>
                        <Panel ref={this.panel}>
                            <Panel.Heading>Atualizar Lista de Funerárias/ Plataformas</Panel.Heading>
                            <Panel.Body>
                                <form 
                                    // onSubmit={this.handleSubmit}
                                >
								<Button style={{marginRight: 10}} onClick={() => this.setState({menu: 1})}>
            						<Glyphicon glyph={'glyphicon glyphicon-refresh'}/>
										{" Atualizar Informações "}
          						</Button>
								<Button style={{marginRight: 10}} onClick={() => this.setState({menu: 2})}>
            						<Glyphicon glyph={'glyphicon glyphicon-pencil'}/>
										{" Criar nova Funerária/ Plataforma "}
          						</Button>
								<Button style={{marginRight: 10}} onClick={() => this.setState({menu: 3})}>
            						<Glyphicon glyph={'glyphicon glyphicon-remove'}/>
										{" Remover Funerária/ Plataforma "}
          						</Button>
								  </form>
							</Panel.Body>
                        </Panel>

								{
									this.state.menu === 1 ?
										<Panel ref={this.panel}>
											<Panel.Body>
												<form>
													<FormGroup>
														<ControlLabel>ID</ControlLabel>
														<FormControl 
																	id="id_search"
																	type="text" 
																	placeholder="ID"   
														/>
													</FormGroup>
													<FormGroup>
														<ControlLabel>Nome</ControlLabel>
														<FormControl 
																	id="name_search"
																	type="text" 
																	placeholder="Nome"   
														/>
													</FormGroup>
													<FormGroup>
														<Button 
															id="search"
															onClick={this.search}
														>
															<Glyphicon glyph={'glyphicon glyphicon-search'}/>
																{" Pesquisar "}
														</Button>
													</FormGroup>
												</form>        
											</Panel.Body>
										</Panel>
									: this.state.menu === 2 ?
											<Panel ref={this.panel}>
												<Panel.Body>
													<form>
														<FormGroup>
															<ControlLabel>ID</ControlLabel>
															<FormControl 
																		id="id_create"
																		type="text" 
																		placeholder="ID"  
															/>
														</FormGroup>
														<FormGroup>
															<ControlLabel>Nome</ControlLabel>
															<FormControl 
																		id="name_create"
																		type="text" 
																		placeholder="Nome"       
															/>
														</FormGroup>
														<FormGroup>
															<Button 
																id="create"
																onClick={this.create}
															>
																<Glyphicon glyph={'glyphicon glyphicon-pencil'}/>
																	{" Criar "}
															</Button>
														</FormGroup>
													</form>        
												</Panel.Body>
											</Panel>
										: this.state.menu === 3 ?
											<Panel ref={this.panel}>
												<Panel.Body>
													<form>
														<FormGroup>
															<ControlLabel>ID</ControlLabel>
															<FormControl 
																		id="id_search_remove"
																		type="text" 
																		placeholder="ID"
																		//  id="plate" onChange={this.handleChange}
																		//  disabled={this.state.disabled}          
															/>
														</FormGroup>
														<FormGroup>
															<ControlLabel>Nome</ControlLabel>
															<FormControl 
																		id="name_search_remove"
																		type="text" 
																		placeholder="Nome"
																		//  id="plate" onChange={this.handleChange}
																		//  disabled={this.state.disabled}          
															/>
														</FormGroup>
														<FormGroup>
															<Button 
																id="search_remove"
																onClick={this.search}
																//disabled={this.state.disabled}
															>
																<Glyphicon glyph={'glyphicon glyphicon-search'}/>
																	{" Pesquisar "}
															</Button>

														</FormGroup>
													</form>        
												</Panel.Body>
											</Panel>
											: this.state.menu === 4 ?
												<Panel ref={this.panel}>
													<Panel.Body>
														<form>
															<FormGroup>
																<ControlLabel>ID</ControlLabel>
																<FormControl 
																			id="id_result"
																			type="text" 
																			placeholder={this.state.data.id}
																			//  id="plate" onChange={this.handleChange}
																			//  disabled={this.state.disabled}          
																/>
															</FormGroup>
															<FormGroup>
																<ControlLabel>Nome</ControlLabel>
																<FormControl 
																			id="name_result"
																			type="text" 
																			placeholder={this.state.data.name}
																			//  id="plate" onChange={this.handleChange}
																			//  disabled={this.state.disabled}          
																/>
															</FormGroup>
															<FormGroup>
																<Button 
																	id="result"
																	style={{marginRight: 10}}
																	//disabled={this.state.disabled}
																	onClick={this.search}
																>
																	<Glyphicon glyph={'glyphicon glyphicon-search'}/>
																		{" Pesquisar "}
																</Button>
																<Button 
																	onClick={this.update}
																	style={{marginRight: 10}} 
																>
																	<Glyphicon glyph={'glyphicon glyphicon-refresh'}/>
																		{" Atualizar Informações "}
																</Button>
															</FormGroup>
														</form>        
													</Panel.Body>
												</Panel>
											: this.state.menu === 5 ?
												<Panel ref={this.panel}>
													<Panel.Body>
														<form>
															<FormGroup>
																<ControlLabel>ID</ControlLabel>
																<FormControl 
																			id="id_remove"
																			type="text" 
																			placeholder={this.state.data.id}
																			//  id="plate" onChange={this.handleChange}
																			//  disabled={this.state.disabled}          
																/>
															</FormGroup>
															<FormGroup>
																<ControlLabel>Nome</ControlLabel>
																<FormControl 
																			id="name_remove"
																			type="text" 
																			placeholder={this.state.data.name}
																			//  id="plate" onChange={this.handleChange}
																			//  disabled={this.state.disabled}          
																/>
															</FormGroup>
															<FormGroup>
																<Button 
																	style={{marginRight: 10}}
																	id="remove"
																	onClick={this.search}
																	//disabled={this.state.disabled}
																>
																	<Glyphicon glyph={'glyphicon glyphicon-search'}/>
																		{" Pesquisar "}
																</Button>
																<Button 
																	style={{marginRight: 10}}
																	id="remove"
																	onClick={this.remove}
																	//disabled={this.state.disabled}
																>
																	<Glyphicon glyph={'glyphicon glyphicon-remove'}/>
																		{" Remover "} 
																</Button>
															</FormGroup>
														</form>        
													</Panel.Body>
												</Panel>
										: null
										
								}
   

                    </Col>
                </Row>

				<
					FuneraryModal
                    show={this.state.showModal
                    }
					onHide={onClick}
                    action={this.state.modalAction
                    }
                    plate={this.state.plate
                    }
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        auth: state.firebase.auth,
        authError: state.auth.authError,
        profile: state.firebase.profile,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneraryList);